import { Check, HelpOutline } from '@mui/icons-material'
import { Box, Button, Chip, Stack, Typography } from '@mui/material'
import { useState } from 'react'
import { Visibility, Add } from '@mui/icons-material'

import AddToWatchlist from '../prime/watchlist-toolbar'
import AddToOpportunity from '../prime/opportunity'

import { getUserInfo } from '../../../../selectors/login-selector'
import { useSelector } from 'react-redux'

const Verfication = ({ verified, id }: { verified: boolean; id: number }) => {
    const userInfo = useSelector(getUserInfo)
    const [open, setOpen] = useState(false)
    const [openInvite, setOpenInvite] = useState(false)

    return (
        <>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
            >
                <Box display="flex">
                    <Chip
                        label={
                            <Typography fontWeight="bold">
                                {' '}
                                {verified ? 'Verified' : 'Unverified'}
                            </Typography>
                        }
                        icon={
                            verified ? (
                                <Check
                                    sx={{ pr: 0.5, pl: 0, py: 0.5 }}
                                    color="success"
                                />
                            ) : (
                                <HelpOutline
                                    sx={{ pr: 0.5, pl: 0, py: 0.5 }}
                                    color="warning"
                                />
                            )
                        }
                        color={verified ? 'success' : 'warning'}
                    />
                </Box>
                {userInfo.userType !== 'BUSINESS' && (
                    <Stack direction="row" spacing={2}>
                        <Button
                            startIcon={<Visibility />}
                            onClick={() => setOpen(true)}
                            variant="text"
                            color="primary"
                            sx={{
                                textTransform: 'none',
                            }}
                        >
                            Add to Watchlist
                        </Button>
                        <Button
                            startIcon={<Add />}
                            onClick={() => setOpenInvite(true)}
                            variant="text"
                            color="primary"
                            sx={{
                                textTransform: 'none',
                            }}
                        >
                            Invite to Scope of Work
                        </Button>
                    </Stack>
                )}
            </Box>
            <AddToWatchlist open={open} setOpen={setOpen} id={id} />
            <AddToOpportunity
                open={openInvite}
                setOpen={setOpenInvite}
                id={id}
            />
        </>
    )
}
export default Verfication
