import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
    Business,
    OpportunityResponse,
    Opportunity,
    ResponseRequirement,
} from '../../../../../utils/models/api-models'
import {
    getOpportunityResponse,
    getPricingFile,
} from '../../../../../api/v1/opportunity-response'
import { getBusinessById } from '../../../../../api/v1/business'
import { getOpportunity } from '../../../../../api/v1/opportunity'
import Page from '../../../../ui-components/page'
import WhiteContainer from '../../../../ui-components/white-container'
import {
    Box,
    Button,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    Stack,
    Tooltip,
    Typography,
} from '@mui/material'
import { NAICS_NAMES_HASHMAP } from '../../../../../utils/constants/naics-name'
import { Requirement } from '../../business/response/upsert-response/utils'
import EvaluateResponse from './evaluate-response'
import { CERTFICATION_BODY_CODES } from '../../../../../utils/constants/info-constants'

const DetailedResponse = () => {
    const { opId, respId } = useParams()
    const navigate = useNavigate()

    const [opportunity, setOpportunity] = useState<Opportunity>()
    const [response, setResponse] = useState<OpportunityResponse>()
    const [business, setBusiness] = useState<Business>()
    const [openEvaluateDialog, setOpenEvalauateDialog] =
        useState<boolean>(false)
    const [respStatus, setRespStatus] = useState<
        'OPEN' | 'SHORTLISTED' | 'DISQUALIFIED' | 'SELECTED'
    >('OPEN')

    const fetchOpportunity = async () => {
        if (!opId) {
            navigate('/404-not-found')
            return
        }
        const { success, data } = await getOpportunity({ id: +opId })
        if (!success || !data) {
            navigate('/404-not-found')
            return
        }
        setOpportunity(data)
    }

    const getResponseAndBusiness = async () => {
        if (!respId) {
            navigate('/404-not-found')
            return
        }
        const { success, data } = await getOpportunityResponse({
            id: +respId,
        })
        if (!success || !data) {
            navigate('/404-not-found')
            return
        }
        setResponse(data)
        setRespStatus(
            data.status as 'OPEN' | 'SHORTLISTED' | 'DISQUALIFIED' | 'SELECTED'
        )
        const businessId = data.business.id

        const businessResponse = await getBusinessById({
            id: businessId,
            authenticated: true,
        })
        if (!businessResponse.success || !businessResponse.data) {
            navigate('/404-not-found')
            return
        }
        setBusiness(businessResponse.data)
    }

    const getPricing = async () => {
        if (response?.id) {
            const { data } = await getPricingFile({ id: response?.id })
            if (data?.link) {
                window.open(data?.link)
            }
        }
    }

    useEffect(() => {
        fetchOpportunity()
    }, [opId])

    useEffect(() => {
        getResponseAndBusiness()
    }, [respId, respStatus])

    const displayRequirements = (reqs: ResponseRequirement[]) => (
        <Stack spacing={4}>
            {reqs.map((i: Requirement, index: number) => (
                <Box component={'span'}>
                    <Typography variant="body1">
                        {index + 1}. {i.question}
                    </Typography>
                    {!i.required && (
                        <Typography
                            variant="body1"
                            fontSize={'0.875rem'}
                            color="rgba(0, 0, 0, 0.6)"
                            lineHeight={1.43}
                            fontStyle={'italic'}
                        >
                            Optional Compliance
                        </Typography>
                    )}
                    <Box display="flex" mt={2}>
                        <FormControl disabled={true}>
                            <RadioGroup
                                name="controlled-radio-buttons-group"
                                value={i.value}
                            >
                                <FormControlLabel
                                    value={0}
                                    control={
                                        <Radio
                                            disabled={true}
                                            sx={{
                                                '&.Mui-checked': {
                                                    color: '#262d50',
                                                },
                                            }}
                                        />
                                    }
                                    label="Comply"
                                />
                                <FormControlLabel
                                    value={1}
                                    control={
                                        <Radio
                                            disabled={true}
                                            sx={{
                                                '&.Mui-checked': {
                                                    color: '#262d50',
                                                },
                                            }}
                                        />
                                    }
                                    label="Partial"
                                />
                                <FormControlLabel
                                    value={2}
                                    control={
                                        <Radio
                                            disabled={true}
                                            sx={{
                                                '&.Mui-checked': {
                                                    color: '#262d50',
                                                },
                                            }}
                                        />
                                    }
                                    label="Non-Compliant"
                                />
                            </RadioGroup>
                        </FormControl>
                        <Box component={'span'} ml={8} maxWidth={'65%'}>
                            <Typography variant="body1">Notes:</Typography>
                            <Typography variant="body1">
                                {i.comment.length > 0
                                    ? i.comment
                                    : 'No additional notes'}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            ))}
        </Stack>
    )

    return (
        <Page
            header={business?.name || 'Response'}
            headerSubtitle={
                opportunity?.title
                    ? `Response to ${opportunity?.title}`
                    : undefined
            }
            actionButtons={
                respId
                    ? [
                          <Tooltip title="Click to change stauts">
                              <Button
                                  color="primary"
                                  onClick={() => setOpenEvalauateDialog(true)}
                              >
                                  {respStatus === 'OPEN'
                                      ? 'Select Status'
                                      : respStatus.charAt(0).toUpperCase() +
                                        respStatus.slice(1)}
                              </Button>
                          </Tooltip>,
                      ]
                    : []
            }
        >
            <Box component="span" display={'flex'}>
                <WhiteContainer sx={{ width: '30%', mr: '25px' }}>
                    <Stack spacing={2}>
                        <Box component={'span'}>
                            <Typography
                                variant="h2"
                                fontSize={16}
                                fontWeight={300}
                                lineHeight={1.7}
                            >
                                Firm Profile
                            </Typography>
                            <Typography variant="body1">
                                {business?.profile
                                    ? business?.profile
                                    : 'No Profile Available'}
                            </Typography>
                        </Box>
                        <Box component={'span'}>
                            <Typography
                                variant="h2"
                                fontSize={16}
                                fontWeight={300}
                                lineHeight={1.7}
                            >
                                Locations
                            </Typography>
                            <Box component={'span'}>
                                {business?.locations ? (
                                    <Stack spacing={0}>
                                        {business.locations.map((i) => (
                                            <Typography variant="body1">
                                                {i.city}, {i.state}
                                            </Typography>
                                        ))}
                                    </Stack>
                                ) : (
                                    <Typography variant="body1">
                                        No Location Available
                                    </Typography>
                                )}
                            </Box>
                        </Box>
                        <Box component={'span'}>
                            <Typography
                                variant="h2"
                                fontSize={16}
                                fontWeight={300}
                                lineHeight={1.7}
                            >
                                Headcount
                            </Typography>
                            <Typography variant="body1">
                                {business?.headcount
                                    ? business?.headcount
                                    : 'No Headcount Available'}
                            </Typography>
                        </Box>
                        <Box component={'span'}>
                            <Typography
                                variant="h2"
                                fontSize={16}
                                fontWeight={300}
                                lineHeight={1.7}
                            >
                                Certifications
                            </Typography>
                            <Box component={'span'}>
                                {business?.certificates ? (
                                    <Stack spacing={0}>
                                        {business.certificates.map((i) => (
                                            <Typography variant="body1">
                                                {i.body
                                                    ? // @ts-ignore
                                                      CERTFICATION_BODY_CODES[
                                                          i.body
                                                      ] + ', '
                                                    : ''}
                                                , {i.type}
                                            </Typography>
                                        ))}
                                    </Stack>
                                ) : (
                                    <Typography variant="body1">
                                        No Certificates Available
                                    </Typography>
                                )}
                            </Box>
                        </Box>
                        <Box component={'span'}>
                            <Typography
                                variant="h2"
                                fontSize={16}
                                fontWeight={300}
                                lineHeight={1.7}
                            >
                                NAICS
                            </Typography>
                            <Box component={'span'}>
                                {business?.naics ? (
                                    <Stack spacing={0}>
                                        {business.naics.map((i) => (
                                            <Typography variant="body1">
                                                {i.code}
                                                {i.code in NAICS_NAMES_HASHMAP
                                                    ? ` - ${
                                                          //@ts-ignore
                                                          NAICS_NAMES_HASHMAP[
                                                              +i.code
                                                          ]
                                                      }`
                                                    : null}
                                            </Typography>
                                        ))}
                                    </Stack>
                                ) : (
                                    <Typography variant="body1">
                                        No NAICS Available
                                    </Typography>
                                )}
                            </Box>
                        </Box>
                    </Stack>
                </WhiteContainer>
                <WhiteContainer sx={{ width: '60%', ml: '25px' }}>
                    <Stack spacing={4}>
                        <Box component={'span'}>
                            <Typography
                                variant="h2"
                                fontSize={16}
                                fontWeight={300}
                                lineHeight={1.7}
                            >
                                Technical Requirements
                            </Typography>
                            {opportunity?.technicalReqs?.length === 0 ? (
                                <Typography variant="body1">
                                    No Technical Requirements
                                </Typography>
                            ) : (
                                displayRequirements(
                                    response?.technicalReqs || []
                                )
                            )}
                        </Box>
                        <Box component={'span'}>
                            <Typography
                                variant="h2"
                                fontSize={16}
                                fontWeight={300}
                                lineHeight={1.7}
                            >
                                Commercial Requirements
                            </Typography>
                            {opportunity?.technicalReqs?.length === 0 ? (
                                <Typography variant="body1">
                                    No Commercial Requirements
                                </Typography>
                            ) : (
                                displayRequirements(
                                    response?.commercialReqs || []
                                )
                            )}
                        </Box>
                        <Box component={'span'}>
                            <Typography
                                variant="h2"
                                fontSize={16}
                                fontWeight={300}
                                lineHeight={1.7}
                            >
                                Pricing
                            </Typography>
                            {opportunity?.pricing ? (
                                <Typography variant="body1">
                                    <Box
                                        color="#1976d2"
                                        sx={{
                                            cursor: 'pointer',
                                            textDecoration: 'underline',
                                        }}
                                        onClick={async () => await getPricing()}
                                    >
                                        Download Pricing
                                    </Box>
                                </Typography>
                            ) : (
                                <Typography variant="body1">
                                    No Pricing Required
                                </Typography>
                            )}
                        </Box>
                    </Stack>
                </WhiteContainer>
            </Box>
            <EvaluateResponse
                open={openEvaluateDialog}
                setOpen={setOpenEvalauateDialog}
                response={response ?? undefined}
                setStatus={setRespStatus}
            />
        </Page>
    )
}

export default DetailedResponse
