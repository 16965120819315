import {
    Alert,
    Box,
    Button,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@mui/material'
import React, { useCallback, useEffect } from 'react'
import { useFormContext, useFieldArray } from 'react-hook-form'
import { Reqs } from '../../../../../../utils/models/api-models'
import Requirement from './requirement'
import { AttachFile } from '@mui/icons-material'
import { useDropzone } from 'react-dropzone'
import Papa from 'papaparse'

const Requirements = ({
    disable,
    editorType,
    type,
}: {
    disable: boolean
    editorType: 0 | 1
    type: 0 | 1
}) => {
    const requirementType = type === 0 ? 'technicalReqs' : 'commercialReqs'

    const { control, getValues } = useFormContext()
    const { fields, append, remove } = useFieldArray({
        name: requirementType,
        control: control,
    })

    const getMaxValue = () => {
        const values = getValues(requirementType)
        let curMax = -1
        values.map((tr: Reqs) => {
            curMax = Math.max(curMax, tr.id)
        })
        return curMax
    }

    const addRequirement = ({
        value,
        required,
    }: {
        value?: string
        required?: string
    }) => {
        if (editorType === 0) {
            let maxValue = getMaxValue()
            append(
                {
                    id: maxValue + 1,
                    value: value || '',
                    required: required
                        ? required === 'y'
                            ? true
                            : false
                        : true,
                },
                { shouldFocus: true }
            )
            maxValue += 1
        } else {
            let maxValue = getMaxValue()
            append(
                {
                    id: maxValue + 1,
                    value: value ?? '',
                    required: required
                        ? required === 'y'
                            ? true
                            : false
                        : true,
                },
                { shouldFocus: true }
            )
            maxValue += 1
        }
    }

    const onDrop = useCallback((acceptedFiles) => {
        const file = acceptedFiles[0]
        Papa.parse(file, {
            //@ts-ignore
            complete: (results) => {
                //@ts-ignore
                results.data.map((d: Record<string, string>) => {
                    const name = d?.['requirement'] || ''
                    const required = d?.['required'] || 'y'
                    addRequirement({ value: name, required: required })
                })
            },
            header: true,
        })
    }, [])

    const { fileRejections, getRootProps, getInputProps, isDragActive } =
        useDropzone({
            accept: {
                'text/*': ['.csv'],
            },
            multiple: false,
            onDrop,
        })

    useEffect(() => {}, [editorType, type, fields.values])

    return (
        <React.Fragment>
            <Alert severity="info">
                {type === 0
                    ? 'Technical requirements'
                    : 'Commercial requirements'}{' '}
                are optional. You can manually enter your requirements or upload
                a CSV in the following{' '}
                <a
                    target="_blank"
                    href="https://docs.google.com/spreadsheets/d/1fGkHrU_55cbm2uo2ZrfY3i1yiYWoUmazF1_C13Iq-kI/edit?usp=sharing"
                >
                    format
                </a>
                .
            </Alert>
            <Stack spacing={4} mt={2}>
                <Box
                    justifyContent="center"
                    display="flex"
                    px={8}
                    py={8}
                    {...getRootProps()}
                    sx={{
                        borderStyle: 'dotted',
                        borderRadius: 2,
                        borderColor: 'rgba(0, 0, 0, 0.38)',
                        color: 'rgba(0, 0, 0, 0.38)',
                    }}
                >
                    <AttachFile />
                    {isDragActive
                        ? 'Drop the file here...'
                        : 'Click or Drag to Upload Requirements in CSV Format'}

                    <input {...getInputProps()} />
                </Box>

                {fileRejections.length > 0 && (
                    <Alert severity="error">Incorrect file type</Alert>
                )}

                {fields.length > 0 && (
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ fontWeight: 'bold' }}>
                                    Requirement
                                </TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>
                                    Required?
                                </TableCell>
                                <TableCell
                                    align="right"
                                    sx={{ fontWeight: 'bold' }}
                                ></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {fields.map((f, index) => (
                                <Requirement
                                    key={f.id}
                                    reqName={requirementType}
                                    index={index}
                                    remove={() => remove(index)}
                                    disable={disable}
                                />
                            ))}
                        </TableBody>
                    </Table>
                )}
                <Button
                    onClick={() => addRequirement({})}
                    disabled={disable}
                    variant="text"
                    color="primary"
                    size="small"
                    sx={{ width: 170 }}
                >
                    Add Requirement
                </Button>
            </Stack>
        </React.Fragment>
    )
}

export default Requirements
