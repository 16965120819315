import { Info, Email, Phone } from '@mui/icons-material'
import { Box, Link, Stack, Tooltip, Button } from '@mui/material'
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid'

import {
    OpportunityResponse,
    Opportunity,
    ResponseRequirement,
} from '../../../../utils/models/api-models'
import NoSearchResult from '../../search/search-result-states/no-search-result'

interface ResponseTableProps {
    data: OpportunityResponse[] | null
    count: number
    opportunity: Opportunity
}

const getRequirement = ({
    opportunity,
    reqs,
    type,
}: {
    opportunity: Opportunity
    reqs: ResponseRequirement[]
    type: 0 | 1
}) => {
    const validReqs =
        type === 0 ? opportunity.technicalReqs : opportunity.commercialReqs
    let message = 'N/A'

    let numberOfRequired = 0
    const validReqsIdsSet = new Set()
    validReqs?.forEach((r) => {
        validReqsIdsSet.add(r.id)
        if (r.required) {
            numberOfRequired += 1
        }
        return { id: r.id, required: r.required }
    })

    if (reqs.length > 0) {
        let count = 0
        reqs.forEach((r: ResponseRequirement) => {
            if (r.value === 0 && validReqsIdsSet.has(r.id)) {
                count += 1
            }
        })
        message = `${count}/${numberOfRequired}`
    }
    return (
        <Box display="flex" textAlign="center">
            {message}{' '}
            <Tooltip title={'This only uses the required requirments.'}>
                <Info sx={{ ml: 2 }} />
            </Tooltip>
        </Box>
    )
}

const ResponseTable = ({ data, count, opportunity }: ResponseTableProps) => {
    const columns: GridColDef[] = [
        {
            field: 'businessName',
            headerName: 'Firm',
            editable: false,
            sortable: false,
            flex: 1.75,
            renderCell: (params: GridRenderCellParams) => (
                <Link
                    href={`/platform/business/${params.row.business.id}`}
                    target="_blank"
                >
                    {params.row.business.name}
                </Link>
            ),
        },
        {
            field: 'contact',
            headerName: 'Contact',
            editable: false,
            sortable: false,
            flex: 1,
            renderCell: (params: GridRenderCellParams) => (
                <Box display="flex" textAlign="left">
                    {params.row.business.contactName}
                    <Tooltip
                        placement="right"
                        title={
                            <Stack spacing={1} my={1} mx={1} fontSize={12}>
                                <Box display="flex">
                                    <Email
                                        sx={{ height: 16, width: 16, mr: 1 }}
                                    />
                                    {params.row.business.email}
                                </Box>
                                <Box display="flex">
                                    <Phone
                                        sx={{ height: 16, width: 16, mr: 1 }}
                                    />
                                    {`+${params.row.business.phoneNumber}`}
                                </Box>
                            </Stack>
                        }
                    >
                        <Info sx={{ ml: 1 }} />
                    </Tooltip>
                </Box>
            ),
        },
        {
            field: 'TR',
            headerName: 'Tech Reqs',
            editable: false,
            sortable: false,
            flex: 1,
            renderCell: (params: GridRenderCellParams) => {
                if (opportunity) {
                    return getRequirement({
                        opportunity: opportunity,
                        reqs: params.row.technicalReqs,
                        type: 0,
                    })
                }
                return 'N/A'
            },
        },
        {
            field: 'CR',
            headerName: 'Comm Reqs',
            editable: false,
            sortable: false,
            flex: 1,
            renderCell: (params: GridRenderCellParams) => {
                if (opportunity) {
                    return getRequirement({
                        opportunity: opportunity,
                        reqs: params.row.technicalReqs,
                        type: 1,
                    })
                }
                return 'N/A'
            },
        },
        {
            field: 'status',
            headerName: 'Status',
            editable: false,
            sortable: false,
            flex: 1,
            renderCell: (params: GridRenderCellParams) => (
                <Box>
                    {params.row.status.charAt(0).toUpperCase() +
                        params.row.status.slice(1).toLowerCase()}
                </Box>
            ),
        },
        {
            field: 'tools',
            headerName: '',
            editable: false,
            sortable: false,
            flex: 1,
            renderCell: (params: GridRenderCellParams) => (
                <Button
                    color="primary"
                    target="_blank"
                    href={`/platform/scope-of-work/${opportunity.id}/response/${params.row.id}`}
                >
                    View
                </Button>
            ),
        },
    ]
    return (
        <Box
            sx={{
                height: '100vh',
                width: '100%',
            }}
        >
            <DataGrid
                rows={data || []}
                columns={columns}
                pageSize={10}
                disableColumnFilter
                disableColumnMenu
                disableSelectionOnClick
                disableColumnSelector
                rowCount={count}
                paginationMode="client"
                getRowHeight={() => 'auto'}
                sx={{
                    border: 0,
                    '& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cell:focus-within':
                        {
                            outline: 'none',
                        },
                    '& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus':
                        {
                            outline: 'none',
                        },
                    '.MuiDataGrid-columnSeparator': {
                        display: 'none',
                    },
                    '& .MuiDataGrid-columnHeaderTitle': {
                        fontWeight: 'bold',
                        color: '#262d50',
                    },
                    '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
                        py: '8px',
                    },
                    '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
                        py: '15px',
                    },
                    '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell':
                        { py: '22px' },
                }}
                components={{
                    NoRowsOverlay: () => (
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            sx={{
                                height: '100%',
                                width: '100%',
                            }}
                        >
                            <NoSearchResult
                                title="No Responses."
                                subtitle="Your scope of work has no responses."
                            />
                        </Box>
                    ),
                }}
            />
        </Box>
    )
}

export default ResponseTable
