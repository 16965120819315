import React, { useEffect, useState } from 'react'
import { Grid, Typography, Button, Link, Box } from '@mui/material'

import WorldTextField from '../../ui-components/text-field'
import WorldSnackbar from '../../ui-components/snackbar'
import { useParams, useNavigate } from 'react-router-dom'

import resetPassword from '../../../api/v1/user/reset-password'
import { Failure } from '../../../utils/constants/messages'

const messages = {
    success: 'Password was successfully changed.',
    failure: Failure,
}

const ResetPasswordForm = () => {
    //@ts-ignore
    const { uidb64, token } = useParams()
    const navigate = useNavigate()

    const [messageType, setMessageType] = useState<'success' | 'failure'>(
        'success'
    )
    const [password1, setPassword1] = useState<string>()
    const [password2, setPassword2] = useState<string>()
    const [errorMessage, setErrorMessage] = useState<string>()
    const [error, setError] = useState<boolean>(false)
    const [submitted, setSubmitted] = useState<boolean>(false)
    const [success, setSuccess] = useState(false)
    const [disable, setDisabled] = useState<boolean>(false)

    useEffect(() => {
        if (!uidb64 || !token) {
            navigate('404-error')
        }
    }, [uidb64, token])

    const handleResetPassword = async () => {
        setDisabled(true)
        if (!password1 || !password2) {
            setError(true)
            setDisabled(false)
            return
        }

        if (password1.length < 8 || password2.length < 8) {
            setError(true)
            setErrorMessage('The password length should be greater than 8')
            setDisabled(false)
            return
        }

        if (password1 !== password2) {
            setError(true)
            setErrorMessage('The passwords should match')
            setDisabled(false)
            return
        }

        const { success } = await resetPassword({
            uuid: uidb64 || '',
            password: password1,
            token: token || '',
        })

        if (!success) {
            setError(true)
        } else {
            setError(false)
            setSuccess(true)
        }

        setDisabled(false)
        setMessageType(success ? 'success' : 'failure')
        setSubmitted(true)
    }

    const linkStyling = {
        color: 'black',
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
            color: '#262d50',
        },
    }

    return (
        <>
            <Grid
                container
                justifyContent="flex-start"
                px={10}
                mt={10}
                direction="column"
                alignItems="center"
                textAlign="center"
            >
                <Grid item>
                    <Typography
                        variant="h5"
                        color="#262d50"
                        fontWeight="bold"
                        fontFamily='"Montserrat", sans-serif'
                    >
                        dbeWorld
                    </Typography>
                </Grid>
                <Grid item mt={4}>
                    <Typography
                        variant="h3"
                        fontWeight="bold"
                        fontFamily='"Inter", sans-serif'
                    >
                        Reset Password
                    </Typography>
                </Grid>
                <Grid item mt={6} justifyContent="flex-start">
                    {!success && (
                        <>
                            <WorldTextField
                                placeholder="Enter password"
                                label="Password"
                                type="password"
                                fullWidth
                                error={error}
                                helperText={error && errorMessage}
                                value={password1}
                                onChange={(e: React.BaseSyntheticEvent) =>
                                    setPassword1(e.target.value)
                                }
                            />
                            <WorldTextField
                                placeholder="Enter password again"
                                label="Confirm Password"
                                type="password"
                                error={error}
                                fullWidth
                                helperText={error && errorMessage}
                                value={password2}
                                sx={{ mt: 2 }}
                                onChange={(e: React.BaseSyntheticEvent) =>
                                    setPassword2(e.target.value)
                                }
                            />
                            <Button
                                variant="text"
                                color="primary"
                                sx={{
                                    mt: 3,
                                    width: '100%',
                                    px: 4,
                                }}
                                onClick={() => handleResetPassword()}
                                disabled={disable}
                            >
                                Update Password
                            </Button>
                        </>
                    )}
                </Grid>
            </Grid>
            <Grid
                container
                justifyContent="center"
                mt={3}
                spacing={2}
                sx={{ fontSize: 12 }}
            >
                <Grid item>
                    <Link href="/register" sx={linkStyling}>
                        Register
                    </Link>
                </Grid>
                <Grid item>|</Grid>
                <Grid item>
                    <Link href="/login" sx={linkStyling}>
                        Login
                    </Link>
                </Grid>
                <Grid item>|</Grid>
                <Grid item>
                    <Link href="/resend-activation" sx={linkStyling}>
                        Resend Activation
                    </Link>
                </Grid>
            </Grid>
            <WorldSnackbar
                open={submitted}
                setOpen={setSubmitted}
                message={<Box>{messages[messageType]}</Box>}
            />
        </>
    )
}

export default ResetPasswordForm
