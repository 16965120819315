import { Button, Typography } from '@mui/material'

import Page from '../../ui-components/page'
import WhiteContainer from '../../ui-components/white-container'
import OpportunityTable from './prime/opportunity/table'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { getUserInfo } from '../../../selectors/login-selector'
import ResponseTable from './business/response/table'

const Opportunity = () => {
    const { userType } = useSelector(getUserInfo)
    const [openCreateOpGroup, setOpenCreateOpGroup] = useState<boolean>(false)
    const [openCopySow, setOpenCopySow] = useState<boolean>(false)

    return (
        <Page
            header={'Opportunity Management'}
            actionButtons={
                userType === 'BUSINESS'
                    ? []
                    : [
                          <Button
                              variant="text"
                              color="primary"
                              sx={{
                                  px: 2,
                              }}
                              onClick={() => setOpenCreateOpGroup(true)}
                          >
                              Create Opportunity
                          </Button>,
                          <Button
                              variant="text"
                              color="primary"
                              sx={{
                                  px: 2,
                              }}
                              href={'/platform/scope-of-work/edit'}
                          >
                              Create Scope of Work
                          </Button>,
                          <Button
                              variant="text"
                              color="primary"
                              sx={{
                                  px: 2,
                              }}
                              onClick={() => setOpenCopySow(true)}
                          >
                              Copy Existing Scope of Work
                          </Button>,
                      ]
            }
        >
            <WhiteContainer>
                <React.Fragment>
                    <Typography variant="h6" fontWeight="bold" mb={2}>
                        {userType === 'BUSINESS'
                            ? 'My Scopes of Work'
                            : 'Opportunities'}
                    </Typography>
                    {userType === 'BUSINESS' ? (
                        <ResponseTable />
                    ) : (
                        <OpportunityTable
                            openCreateOpGroup={openCreateOpGroup}
                            setOpenCreateOpGroup={setOpenCreateOpGroup}
                            openCopySow={openCopySow}
                            setOpenCopySow={setOpenCopySow}
                        />
                    )}
                </React.Fragment>
            </WhiteContainer>
        </Page>
    )
}

export default Opportunity
