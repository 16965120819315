import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Stack,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { evaluateOpportunityResponse } from '../../../../../api/v1/opportunity-response'
import WorldSearchSingleSelect from '../../../../ui-components/search-single-select'
import WorldTextField from '../../../../ui-components/text-field'
import { setOpen as setNotif } from '../../../../../slices/notification-slice'
import {
    Failure,
    EvaluateOpportunityResponse,
} from '../../../../../utils/constants/messages'
import { evaluateOptions } from './constant'
import { Controller, useForm } from 'react-hook-form'
import { OpportunityResponse } from '../../../../../utils/models/api-models'

interface EvaluateResponseProps {
    open: boolean
    setOpen: (value: boolean) => void
    setStatus: (
        val: 'OPEN' | 'SHORTLISTED' | 'DISQUALIFIED' | 'SELECTED'
    ) => void
    response?: OpportunityResponse
}

const statusToVal = {
    0: 'OPEN',
    1: 'SHORTLISTED',
    2: 'DISQUALIFIED',
    3: 'SELECTED',
}

const labelToOption = {
    OPEN: evaluateOptions[0],
    SHORTLISTED: evaluateOptions[1],
    DISQUALIFIED: evaluateOptions[2],
    SELECTED: evaluateOptions[3],
}

const EvaluateResponse = ({
    open,
    setOpen,
    setStatus,
    response,
}: EvaluateResponseProps) => {
    const [disable, setDisable] = useState(false)
    const [showReasonField, setShowReasonField] = useState(false)

    const { control, trigger, reset, getValues, setValue } = useForm({
        defaultValues: {
            reason: response?.rejectionNote || '',
            status: labelToOption[
                response?.status as
                    | 'OPEN'
                    | 'SHORTLISTED'
                    | 'DISQUALIFIED'
                    | 'SELECTED'
            ],
        },
    })

    useEffect(() => {
        if (response?.status.toUpperCase() === 'DISQUALIFIED') {
            setShowReasonField(true)
        }
        reset({
            reason: response?.rejectionNote || '',
            status: labelToOption[
                response?.status as
                    | 'OPEN'
                    | 'SHORTLISTED'
                    | 'DISQUALIFIED'
                    | 'SELECTED'
            ],
        })
    }, [reset, open])

    useEffect(() => {
        if (response?.status.toUpperCase() === 'DISQUALIFIED') {
            setShowReasonField(true)
        }
    }, [open])

    const dispatch = useDispatch()

    const onSubmit = async () => {
        setDisable(true)
        if (await trigger()) {
            const { status, reason } = getValues()
            const { success } = await evaluateOpportunityResponse({
                status: status.value as 0 | 1 | 2 | 3,
                responseId: response?.id || -1,
                note: status.value === 2 ? reason : '',
            })
            dispatch(
                setNotif({
                    open: true,
                    message: success ? EvaluateOpportunityResponse : Failure,
                })
            )
            setStatus(
                statusToVal[status.value as 0 | 1 | 2 | 3] as
                    | 'OPEN'
                    | 'SHORTLISTED'
                    | 'DISQUALIFIED'
                    | 'SELECTED'
            )
            setOpen(false)
            setShowReasonField(false)
            reset()
        }
        setDisable(false)
    }

    return (
        <Dialog
            fullWidth
            maxWidth="sm"
            open={open}
            onClose={() => {
                setOpen(false)
                reset()
                setShowReasonField(false)
            }}
        >
            <DialogTitle sx={{ fontWeight: 'light', textAlign: 'left' }}>
                Evaluate Response
            </DialogTitle>
            <DialogContent>
                <Stack my={2} spacing={3}>
                    <Controller
                        name={'status' as const}
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <WorldSearchSingleSelect
                                label="Type"
                                placeholder="Select type..."
                                options={evaluateOptions}
                                onChange={(selected) => {
                                    field.onChange(selected)
                                    if (selected?.value === 2) {
                                        setShowReasonField(true)
                                    } else {
                                        setShowReasonField(false)
                                    }
                                }}
                                disable={disable}
                                error={error ? Boolean(error) : false}
                                errorMessage={error?.message || ''}
                                value={
                                    field.value as {
                                        value: number
                                        label: string
                                    }
                                }
                                variant="outlined"
                            />
                        )}
                        rules={{
                            required: {
                                value: true,
                                message: 'Type is required',
                            },
                        }}
                    />
                    {showReasonField && (
                        <Controller
                            name={'reason' as const}
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                                <WorldTextField
                                    placeholder="My reason to disqualify is..."
                                    label="Disqualification Reason"
                                    variant="outlined"
                                    value={field.value}
                                    disabled={disable}
                                    error={Boolean(error)}
                                    multiline
                                    minRows={5}
                                    maxRows={5}
                                    helperText={error?.message || ''}
                                    onChange={(e: React.BaseSyntheticEvent) =>
                                        field.onChange(e.target.value)
                                    }
                                />
                            )}
                            rules={{
                                required: {
                                    value: true,
                                    message: 'Reason is required',
                                },
                                minLength: {
                                    value: 1,
                                    message:
                                        'Reason must be 1 characters or longer',
                                },
                                pattern: {
                                    value: /^[^\s]+(?:$|.*[^\s]+$)/,
                                    message:
                                        'Reason can not start or end with a space',
                                },
                            }}
                        />
                    )}
                </Stack>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'center', mx: 2, mb: 2 }}>
                <Button
                    variant="text"
                    color="secondary"
                    sx={{
                        width: 100,
                    }}
                    onClick={() => {
                        setOpen(false)
                        reset()
                        setShowReasonField(false)
                    }}
                >
                    Close
                </Button>
                <Button
                    variant="text"
                    color="primary"
                    sx={{
                        width: 100,
                    }}
                    disabled={disable}
                    onClick={async () => await onSubmit()}
                >
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default EvaluateResponse
