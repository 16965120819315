import { useEffect, useState } from 'react'
import { getInvitedOpportunitiesForMyBusiness } from '../../../../../../api/v1/opportunity'
import { useDispatch } from 'react-redux'
import { setOpen as openNotification } from '../../../../../../slices/notification-slice'
import { Failure } from '../../../../../../utils/constants/messages'
import { MyInvitedOpportunities } from '../../../../../../utils/models/api-models'
import Table from './table'

const ResponseTable = () => {
    const dispatch = useDispatch()

    const [pageNumber, setPageNumber] = useState<number>(1)
    const [totalCount, setTotalCount] = useState<number>(0)
    const [invitedOpportunities, setInvitedOpportunities] = useState<
        MyInvitedOpportunities[]
    >([])

    const getInvitedOpportunities = async () => {
        const result = await getInvitedOpportunitiesForMyBusiness({
            page: pageNumber,
        })
        if (!result.success) {
            dispatch(
                openNotification({
                    message: Failure,
                    open: true,
                })
            )
        }
        setInvitedOpportunities(result.data?.data || [])
        setTotalCount(result.data?.count || 0)
    }

    useEffect(() => {
        getInvitedOpportunities()
    }, [pageNumber])

    return (
        <Table
            data={invitedOpportunities}
            setPage={setPageNumber}
            count={totalCount}
            getInvitedOpportunities={() => getInvitedOpportunities()}
        />
    )
}

export default ResponseTable
