import { useEffect, useState } from 'react'
import { Box, CircularProgress } from '@mui/material'

import { useForm, FormProvider } from 'react-hook-form'

import SearchFilters from './search-filters'
import SearchResults from './search-results'
import NoSearch from './search-result-states/no-search'
import NoSearchResult from './search-result-states/no-search-result'

import searchAPI from '../../../api/v1/search/search-api'

import { AnonBusiness } from '../../../utils/models/api-models'
import { RADIUS } from '../../../utils/constants/info-constants'

interface DataProps {
    data: AnonBusiness[]
    count: number
}

const SearchForm = () => {
    const [initialSearch, setInitialSearch] = useState(true)
    const [searched, setSearched] = useState(false)
    const [disable, setDisable] = useState(false)
    const [searchedData, setSearchedData] = useState<DataProps>({
        data: [],
        count: 0,
    })
    const [page, setPage] = useState(0)

    const handlePageChange = async (pageNumber: number) => {
        if (pageNumber === page) {
            await handleSearch({ pageNumber: pageNumber })
        }
        setPage(pageNumber)
    }

    useEffect(() => {
        if (!initialSearch) {
            handleSearch({ pageNumber: page })
        }
    }, [page, setPage])

    useEffect(() => {
        setInitialSearch(false)
    }, [])

    const methods = useForm({
        defaultValues: {
            verified: false,
            hasWebsite: false,
            businessName: '',
            headcount: [],
            certificates: [],
            certificateBodies: [],
            states: [],
            cities: [],
            keywords: [],
            naicsCodes: [],
            locationRadius: RADIUS[0],
        },
    })

    const handleSearch = async ({
        pageNumber = 1,
    }: {
        pageNumber?: number
    }) => {
        setDisable(true)

        const {
            verified,
            hasWebsite,
            businessName,
            headcount,
            certificates,
            certificateBodies,
            states,
            cities,
            naicsCodes,
            keywords,
            locationRadius,
        } = methods.getValues()

        const { success, data } = await searchAPI({
            verified,
            website: hasWebsite,
            businessName,
            headcount: headcount.map((h) => h?.['value']),
            certification: certificates.map((c) => c?.['value']),
            certifyingBody: certificateBodies.map((c) => c?.['value']),
            state: states.map((c) => c?.['value']),
            city: cities,
            keywords: keywords,
            naics: naicsCodes.map((n) => n?.['value']),
            locationRadius: locationRadius?.['value'] ?? 0,
            page: pageNumber,
        })
        if (success) {
            setSearchedData({
                data: data?.data || [],
                count: data?.count || 0,
            })
        }

        setDisable(false)
        setInitialSearch(false)
        setSearched(true)
    }

    const showResultPage = () => {
        if (disable && !searched) {
            return (
                <Box
                    display="flex"
                    height="100%"
                    justifyContent="center"
                    alignItems="center"
                >
                    <CircularProgress color="inherit" />
                </Box>
            )
        }
        if (!searched)
            return (
                <NoSearch
                    title="No Active Search"
                    subtitle="Please use the filters to search for businesses."
                />
            )
        if (searched && searchedData.data.length === 0)
            return (
                <NoSearchResult
                    title="No Results Found"
                    subtitle="Try adjusting the filters to find what you are looking for."
                />
            )
        return (
            <SearchResults
                disable={disable}
                data={searchedData.data}
                count={searchedData.count}
                page={page}
                setPage={handlePageChange}
            />
        )
    }

    return (
        <Box display="flex" justifyContent="center">
            <Box
                px={3}
                mr={3}
                py={2}
                bgcolor="white"
                boxShadow="rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px"
                borderRadius={2}
                width="50%"
            >
                <FormProvider {...methods}>
                    <SearchFilters
                        handleSearch={() => handlePageChange(1)}
                        disabledSearch={disable}
                    />
                </FormProvider>
            </Box>
            <Box
                px={3}
                py={2}
                bgcolor="white"
                boxShadow="rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px"
                borderRadius={2}
                width="100%"
            >
                {showResultPage()}
            </Box>
        </Box>
    )
}

export default SearchForm
