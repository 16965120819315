import { useEffect, useState } from 'react'
import { Navigate, Outlet } from 'react-router-dom'

import checkToken from '../../api/v1/user/check-token'

const LoggedInRoute = () => {
    const [auth, setAuth] = useState<boolean>()
    const handleLogin = async () => {
        const { success } = await checkToken()
        setAuth(success)
    }
    useEffect(() => {
        handleLogin()
    }, [])

    if (auth === undefined) return null

    if (!auth) {
        return <Navigate to="/login" />
    }

    return <Outlet />
}

export default LoggedInRoute
