import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import getUserActivate from '../../../api/v1/user/get-user-activate'

import NonBusinessActivate from './activate-non-business'
import BusinessActivate from './activate-business'

import { GetUser } from '../../../utils/models/api-models'
import { AccountType } from '../../../utils/constants/enums'

const Activate = () => {
    //@ts-ignore
    const { uidb64, token } = useParams()
    const [data, setData] = useState<GetUser>()
    const navigate = useNavigate()

    useEffect(() => {
        getUser()
    }, [])

    const getUser = async () => {
        if (!uidb64) {
            navigate('/not-found')
            return
        }

        const { success, data } = await getUserActivate(uidb64)
        if (!success || (success && data?.['verified'])) {
            navigate('/not-found')
            return
        }
        data && setData(data)
    }

    const renderActivationPage = () => {
        if (
            data &&
            data.accountType === AccountType.BUSINESS &&
            data.business
        ) {
            const businessData = data.business
            return (
                <BusinessActivate
                    uuid={uidb64 || ''}
                    token={token || ''}
                    email={businessData.contacts[0].email}
                    businessName={businessData.name}
                    contactName={businessData.contacts[0].name}
                    phoneNumber={businessData.contacts[0].phoneNumber}
                    website={businessData.website}
                    profile={businessData.profile}
                />
            )
        } else if (data && data.accountType !== AccountType.BUSINESS) {
            return (
                <NonBusinessActivate uuid={uidb64 || ''} token={token || ''} />
            )
        }
    }

    return <>{renderActivationPage()}</>
}

export default Activate
