import {
    Box,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Radio,
    RadioGroup,
    Stack,
    Typography,
} from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'
import WorldTextField from '../../../../../ui-components/text-field'

interface RequirementProps {
    index: number
    reqName: string
    disable: boolean
}

const Requirement = ({ index, reqName, disable }: RequirementProps) => {
    const { control, getValues } = useFormContext()
    const question = `${reqName}.${index}.question` as string
    const value = `${reqName}.${index}.value` as string
    const comment = `${reqName}.${index}.comment` as string
    const required = `${reqName}.${index}.required` as string

    return (
        <Stack spacing={1.5}>
            <Controller
                name={`${question}` as const}
                control={control}
                render={({ field, fieldState: { error } }) => (
                    <Typography variant="body1" lineHeight={1.5}>
                        {index + 1}. {field.value}
                    </Typography>
                )}
            />
            {!getValues(required) && (
                <Controller
                    name={`${required}` as const}
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <Typography
                            variant="body1"
                            fontSize={'0.875rem'}
                            color="rgba(0, 0, 0, 0.6)"
                            lineHeight={1.43}
                            fontStyle={'italic'}
                        >
                            Optional
                        </Typography>
                    )}
                />
            )}

            <Box display="flex" justifyContent="space-between">
                <Controller
                    name={`${value}` as const}
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <FormControl
                            disabled={disable}
                            error={error ? Boolean(error) : false}
                        >
                            <RadioGroup
                                name="controlled-radio-buttons-group"
                                value={field.value}
                                onChange={(e: React.BaseSyntheticEvent) =>
                                    field.onChange(+e.target.value)
                                }
                            >
                                <FormControlLabel
                                    value={0}
                                    control={
                                        <Radio
                                            disabled={disable}
                                            sx={{
                                                '&.Mui-checked': {
                                                    color: '#262d50',
                                                },
                                            }}
                                        />
                                    }
                                    label="Comply"
                                />
                                <FormControlLabel
                                    value={1}
                                    control={
                                        <Radio
                                            disabled={disable}
                                            sx={{
                                                '&.Mui-checked': {
                                                    color: '#262d50',
                                                },
                                            }}
                                        />
                                    }
                                    label="Partial"
                                />
                                <FormControlLabel
                                    value={2}
                                    control={
                                        <Radio
                                            disabled={disable}
                                            sx={{
                                                '&.Mui-checked': {
                                                    color: '#262d50',
                                                },
                                            }}
                                        />
                                    }
                                    label="Non-Compliant"
                                />
                            </RadioGroup>
                            {error?.message && (
                                <FormHelperText>{error.message}</FormHelperText>
                            )}
                        </FormControl>
                    )}
                    rules={{
                        validate: () => {
                            const val = getValues(value)
                            if (val <= -1) {
                                return 'Please indicate your compliance'
                            }
                        },
                    }}
                />
                <Controller
                    name={`${comment}` as const}
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <WorldTextField
                            placeholder="Enter any comments/notes"
                            label="Comment"
                            value={field.value}
                            multiline
                            minRows={4}
                            maxRows={4}
                            onChange={(e: React.BaseSyntheticEvent) =>
                                field.onChange(e.target.value)
                            }
                            error={Boolean(error)}
                            helperText={error?.message || ''}
                            sx={{ width: '70%' }}
                            disabled={disable}
                        />
                    )}
                    rules={{
                        maxLength: {
                            value: 500,
                            message: `Comments must be 500 characters or less`,
                        },
                    }}
                />
            </Box>
        </Stack>
    )
}

export default Requirement
