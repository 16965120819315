import { Box, IconButton, Link, Tooltip } from '@mui/material'
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid'

import NoSearchResult from '../../search/search-result-states/no-search-result'
import WhiteContainer from '../../../ui-components/white-container'
import { NoteAdd } from '@mui/icons-material'

import LogCommunication, { Info } from './log-communication'
import { useState } from 'react'
import { Opportunity } from '../../../../utils/models/api-models'

interface InviteeTableProps {
    opportunity: Opportunity
    data: Record<string, number | string>[] | null
    count: number
}

const InviteeTable = ({ opportunity, data, count }: InviteeTableProps) => {
    const [info, setInfo] = useState<Info>()
    const [open, setOpen] = useState(false)

    const columns: GridColDef[] = [
        {
            field: 'businessName',
            headerName: 'Firm',
            editable: false,
            sortable: false,
            flex: 1.75,
            renderCell: (params: GridRenderCellParams) => (
                <Link
                    href={`/platform/business/${params.row.id}`}
                    target="_blank"
                >
                    {params.row.name}
                </Link>
            ),
        },
        {
            field: 'contactName',
            headerName: 'Contact',
            editable: false,
            sortable: false,
            flex: 1,
            renderCell: (params: GridRenderCellParams) => (
                <Box>{params.row.contactName}</Box>
            ),
        },
        {
            field: 'email',
            headerName: 'Email',
            editable: false,
            sortable: false,
            flex: 1,
            renderCell: (params: GridRenderCellParams) => (
                <Box>{params.row.email}</Box>
            ),
        },
        {
            field: 'phoneNumber',
            headerName: 'Phone Number',
            editable: false,
            sortable: false,
            flex: 1,
            renderCell: (params: GridRenderCellParams) => (
                <Box>+{params.row.phoneNumber}</Box>
            ),
        },
        {
            field: 'tools',
            headerName: '',
            editable: false,
            sortable: false,
            flex: 0.5,
            renderCell: (params: GridRenderCellParams) => (
                <Box>
                    <Tooltip title={'Log Communication'}>
                        <IconButton
                            onClick={() => {
                                setOpen(true)
                                setInfo({
                                    opportunity: opportunity.id,
                                    businessName: params.row.name as string,
                                    business: params.row.id as number,
                                })
                            }}
                        >
                            <NoteAdd sx={{ height: '20px' }} />
                        </IconButton>
                    </Tooltip>
                </Box>
            ),
        },
    ]
    return (
        <WhiteContainer>
            <Box
                sx={{
                    height: '100vh',
                    width: '100%',
                }}
            >
                <DataGrid
                    rows={data || []}
                    columns={columns}
                    pageSize={10}
                    disableColumnFilter
                    disableColumnMenu
                    disableSelectionOnClick
                    disableColumnSelector
                    rowCount={count}
                    paginationMode="client"
                    getRowHeight={() => 'auto'}
                    sx={{
                        border: 0,
                        '& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cell:focus-within':
                            {
                                outline: 'none',
                            },
                        '& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus':
                            {
                                outline: 'none',
                            },
                        '.MuiDataGrid-columnSeparator': {
                            display: 'none',
                        },
                        '& .MuiDataGrid-columnHeaderTitle': {
                            fontWeight: 'bold',
                            color: '#262d50',
                        },
                        '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell':
                            {
                                py: '8px',
                            },
                        '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell':
                            {
                                py: '15px',
                            },
                        '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell':
                            { py: '22px' },
                    }}
                    components={{
                        NoRowsOverlay: () => (
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                sx={{
                                    height: '100%',
                                    width: '100%',
                                }}
                            >
                                <NoSearchResult
                                    title="No Invitees."
                                    subtitle="Your scope of work has no invitees."
                                />
                            </Box>
                        ),
                    }}
                />
            </Box>
            <LogCommunication info={info} open={open} setOpen={setOpen} />
        </WhiteContainer>
    )
}

export default InviteeTable
