import jwt_decode from 'jwt-decode'
import axios from 'axios'

import { baseURL } from '../api-base'
import { CheckTokenResponse } from '../../../utils/models/api-models'

const checkToken = (): CheckTokenResponse | Promise<CheckTokenResponse> => {
    if (!localStorage.getItem('accessToken')) {
        return { success: false, accessToken: null, refreshToken: null }
    }
    //@ts-ignore
    const decoded = jwt_decode(localStorage.getItem('accessToken'))
    let today = new Date()
    //@ts-ignore
    if (decoded?.exp * 1000 > today.getTime()) {
        return { success: true, accessToken: null, refreshToken: null }
    } else {
        if (!localStorage.getItem('refreshToken')) {
            return { success: false, accessToken: null, refreshToken: null }
        }

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
            url: '/auth/refresh-token/',
            baseURL: baseURL,
            method: 'post',
            data: {
                refresh: localStorage.getItem('refreshToken'),
            },
        }

        return (
            axios
                //@ts-ignore
                .request(config)
                .then((res: any) => {
                    const { access } = res.data
                    localStorage.setItem('accessToken', access)
                    return {
                        success: true,
                        accessToken: access,
                        refreshToken: localStorage.getItem('refreshToken'),
                    }
                })
                .catch((err: any) => {
                    return {
                        success: false,
                        accessToken: null,
                        refreshToken: null,
                    }
                })
        )
    }
}

export default checkToken
