import { Stack, Box, Typography } from '@mui/material'
import { Opportunity as OpportunityModel } from '../../../../../../utils/models/api-models'

const OpportunityInfo = ({
    opportunity,
}: {
    opportunity: OpportunityModel
}) => (
    <Stack spacing={2}>
        <Box component={'span'}>
            <Typography
                variant="h2"
                fontSize={16}
                fontWeight={300}
                lineHeight={1.7}
            >
                Description
            </Typography>
            <Typography variant="body1">{opportunity?.description}</Typography>
        </Box>
        <Box component={'span'}>
            <Typography
                variant="h2"
                fontSize={16}
                fontWeight={300}
                lineHeight={1.7}
            >
                Status
            </Typography>
            <Typography variant="body1">
                {opportunity?.status ? 'Open' : 'Closed'}
            </Typography>
        </Box>
        <Box component={'span'}>
            <Typography
                variant="h2"
                fontSize={16}
                fontWeight={300}
                lineHeight={1.7}
            >
                Due Date
            </Typography>
            <Typography variant="body1">{opportunity?.endDate}</Typography>
        </Box>
        <Box component={'span'}>
            <Typography
                variant="h2"
                fontSize={16}
                fontWeight={300}
                lineHeight={1.7}
            >
                Pricing Required in Response
            </Typography>
            <Typography variant="body1">
                {opportunity?.pricing ? 'Yes' : 'No'}
            </Typography>
        </Box>
    </Stack>
)

export default OpportunityInfo
