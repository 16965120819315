import callAPI from '../api-base'

import { AccountType } from '../../../utils/constants/enums'

interface SignUpApiProps {
    email: string
    password: string
    firstName: string
    lastName: string
    type: AccountType
}

export const signUpAPI = ({
    email,
    password,
    firstName,
    lastName,
    type,
}: SignUpApiProps): boolean | Promise<boolean> => {
    return callAPI({
        url: 'auth/register/',
        type: 'post',
        body: {
            email: email,
            password: password,
            account_type: type,
            first_name: firstName,
            last_name: lastName,
        },
    })
        .then((res: any) => {
            return true
        })
        .catch((err: any) => {
            return false
        })
}

export default signUpAPI
