import {
    Box,
    Button,
    List,
    ListItem,
    ListItemText,
    Stack,
    Tab,
    Tabs,
    Typography,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

import { getOpportunity } from '../../../../api/v1/opportunity'

import { Opportunity, Reqs } from '../../../../utils/models/api-models'

import Page from '../../../ui-components/page'
import { getUserInfo } from '../../../../selectors/login-selector'
import { useSelector } from 'react-redux'
import WhiteContainer from '../../../ui-components/white-container'
import Responses from './responses'
import InviteeTable from './invitee-table'

const OpportunityPage = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const { id } = useParams()
    const navigate = useNavigate()
    const [opportunity, setOpportunity] = useState<Opportunity>()
    const userInfo = useSelector(getUserInfo)
    const [tab, setTab] = useState(0)

    const fetchOpportunity = async () => {
        const { success, data } = await getOpportunity({
            id: id as unknown as number,
        })
        if (!success || !data) {
            navigate('/404-not-found')
            return
        }
        setOpportunity(data || [])
    }

    useEffect(() => {
        fetchOpportunity()
    }, [id])

    const listRequirements = (items: Reqs[] | null | undefined) => {
        if (!items || (items && items.length === 0)) {
            items = [{ id: -1, value: 'None Available', required: false }]
        }
        return (
            <List
                sx={{
                    width: '100%',
                    py: 0,
                }}
            >
                {items.map((item, index) => (
                    <ListItem sx={{ py: 0, display: 'list-item' }}>
                        <ListItemText
                            sx={{ mb: 2 }}
                            primary={
                                item.id !== -1
                                    ? `${index + 1}. ${item.value}`
                                    : item.value
                            }
                            secondary={
                                item.id === -1
                                    ? null
                                    : !item.required && 'Optional'
                            }
                        />
                    </ListItem>
                ))}
            </List>
        )
    }

    const opportunityView = () => (
        <Box component={'span'} display="flex">
            <WhiteContainer sx={{ width: '30%', mr: '25px' }}>
                <Stack spacing={2}>
                    <Box component={'span'}>
                        <Typography
                            variant="h2"
                            fontSize={16}
                            fontWeight={300}
                            lineHeight={1.7}
                        >
                            Description
                        </Typography>
                        <Typography variant="body1">
                            {opportunity?.description}
                        </Typography>
                    </Box>
                    <Box component={'span'}>
                        <Typography
                            variant="h2"
                            fontSize={16}
                            fontWeight={300}
                            lineHeight={1.7}
                        >
                            Status
                        </Typography>
                        <Typography variant="body1">
                            {opportunity?.status ? 'Open' : 'Closed'}
                        </Typography>
                    </Box>
                    <Box component={'span'}>
                        <Typography
                            variant="h2"
                            fontSize={16}
                            fontWeight={300}
                            lineHeight={1.7}
                        >
                            Due Date
                        </Typography>
                        <Typography variant="body1">
                            {opportunity?.endDate}
                        </Typography>
                    </Box>
                    <Box component={'span'}>
                        <Typography
                            variant="h2"
                            fontSize={16}
                            fontWeight={300}
                            lineHeight={1.7}
                        >
                            Pricing Required in Response
                        </Typography>
                        <Typography variant="body1">
                            {opportunity?.pricing ? 'Yes' : 'No'}
                        </Typography>
                    </Box>
                </Stack>
            </WhiteContainer>
            <WhiteContainer sx={{ width: '60%', ml: '25px' }}>
                <Stack spacing={0}>
                    <Box component={'span'}>
                        <Typography
                            variant="h2"
                            fontSize={16}
                            fontWeight={300}
                            lineHeight={1.7}
                        >
                            Technical Requirements
                        </Typography>
                        <Typography variant="body1">
                            {listRequirements(opportunity?.technicalReqs)}
                        </Typography>
                    </Box>
                    <Box component={'span'}>
                        <Typography
                            variant="h2"
                            fontSize={16}
                            fontWeight={300}
                            lineHeight={1.7}
                        >
                            Commercial Requirements
                        </Typography>
                        <Typography variant="body1">
                            {listRequirements(opportunity?.commercialReqs)}
                        </Typography>
                    </Box>
                </Stack>
            </WhiteContainer>
        </Box>
    )

    const tabToView = (tab: number) => {
        if (tab === 0) {
            return opportunityView()
        }
        if (tab === 1 && userInfo.userType !== 'BUSINESS' && opportunity) {
            return <Responses opportunity={opportunity} />
        }
        if (tab === 2 && userInfo.userType !== 'BUSINESS' && opportunity) {
            return (
                <InviteeTable
                    opportunity={opportunity}
                    data={opportunity.bidInvitees}
                    count={opportunity.bidInvitees?.length || 0}
                />
            )
        } else {
            return opportunityView()
        }
    }

    const changeTab = (val: number) => {
        setTab(val)
        if (userInfo.userType !== 'BUSINESS') {
            searchParams.set('tab', val.toString())
            setSearchParams(searchParams)
        }
    }

    const getSearchParams = () => {
        const tabValue = searchParams.get('tab')
        if (tabValue) {
            setTab(+tabValue as number)
        }
    }

    useEffect(() => {
        getSearchParams()
    }, [searchParams])

    return (
        <Page
            header={opportunity?.title as string}
            actionButtons={
                userInfo.userType === 'BUSINESS'
                    ? [
                          <Button
                              color="primary"
                              href={`/platform/scope-of-work/${id}/response`}
                          >
                              Respond
                          </Button>,
                      ]
                    : [
                          <Button
                              color="primary"
                              href={`/platform/scope-of-work/edit?id=${id}`}
                          >
                              Edit
                          </Button>,
                      ]
            }
        >
            <Box component={'span'}>
                {userInfo.userType !== 'BUSINESS' && (
                    <Box display="flex" mb={4}>
                        <Tabs value={tab}>
                            <Tab
                                label={'Overview'}
                                value={0}
                                onClick={() => changeTab(0)}
                            />
                            <Tab
                                label={'Responses'}
                                value={1}
                                onClick={() => changeTab(1)}
                            />
                            <Tab
                                label={'Invitees'}
                                value={2}
                                onClick={() => changeTab(2)}
                            />
                        </Tabs>
                    </Box>
                )}
                {tabToView(tab)}
            </Box>
        </Page>
    )
}

export default OpportunityPage
