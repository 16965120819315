import callAPI from '../api-base'

import { SignIn } from '../../../utils/models/api-models'

interface SignInApiProps {
    email: string
    password: string
}

const signInAPI = ({
    email,
    password,
}: SignInApiProps): SignIn | Promise<SignIn> => {
    return callAPI({
        url: 'auth/access-token/',
        type: 'post',
        body: {
            email: email,
            password: password,
        },
    })
        .then((res: any) => {
            const { access, refresh, account } = res.data
            if (access === null) {
                return {
                    success: false,
                    accessToken: null,
                    refreshToken: null,
                    user: null,
                    id: null,
                }
            }
            localStorage.setItem('accessToken', access)
            localStorage.setItem('refreshToken', refresh)

            return {
                success: true,
                accessToken: access,
                refreshToken: refresh,
                user: {
                    businessId: account.business_id,
                    email: account.email,
                    type: account.type,
                    isSuper: account.is_super,
                    id: account.id,
                },
            }
        })
        .catch((err: any) => {
            return {
                success: false,
                accessToken: null,
                refreshToken: null,
                user: null,
            }
        })
}

export default signInAPI
