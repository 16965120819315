export const ValueToCompliance = {
    0: 'Comply',
    1: 'Partial',
    2: 'Non-Compliant',
}

export const evaluateOptions = [
    {
        value: 0,
        label: 'Open',
    },
    {
        value: 1,
        label: 'Shortlisted',
    },
    {
        value: 2,
        label: 'Disqualified',
    },
    {
        value: 3,
        label: 'Selected',
    },
]
