import React from 'react'
import { Box } from '@mui/material'
import WorldTextField from '../../ui-components/text-field'

import { useFormContext, Controller } from 'react-hook-form'

const AccountInformation = () => {
    const { control, getValues } = useFormContext()
    const { type, email } = getValues()
    const isBusiness = type === 'BUSINESS'

    return (
        <>
            <Box>
                <Controller
                    name={'email' as const}
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <WorldTextField
                            label="Email"
                            placeholder="Enter email"
                            fullWidth
                            value={isBusiness ? email : field.value}
                            error={Boolean(error)}
                            disabled={isBusiness}
                            helperText={error?.message || ''}
                            onChange={(e: React.BaseSyntheticEvent) =>
                                field.onChange(e.target.value)
                            }
                        />
                    )}
                    rules={{
                        required: {
                            value: true,
                            message: 'Email is required',
                        },
                        maxLength: {
                            value: 60,
                            message: 'Email must be 60 characters or less',
                        },
                        pattern: {
                            value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                            message: 'Must be an email',
                        },
                    }}
                />
            </Box>
            <Box mt={2}>
                <Controller
                    name={'firstName' as const}
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <WorldTextField
                            label="First Name"
                            placeholder="Enter first name"
                            fullWidth
                            value={field.value}
                            error={Boolean(error)}
                            helperText={error?.message || ''}
                            onChange={(e: React.BaseSyntheticEvent) =>
                                field.onChange(e.target.value)
                            }
                        />
                    )}
                    rules={{
                        required: {
                            value: true,
                            message: 'First name is required',
                        },
                        maxLength: {
                            value: 100,
                            message:
                                'First name must be 100 characters or less',
                        },
                        pattern: {
                            value: /^[^\s]+(?:$|.*[^\s]+$)/,
                            message:
                                'First name can not start or end with a space',
                        },
                    }}
                />
            </Box>
            <Box mt={2}>
                <Controller
                    name={'lastName' as const}
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <WorldTextField
                            label="Last Name"
                            placeholder="Enter last name"
                            fullWidth
                            value={field.value}
                            error={Boolean(error)}
                            helperText={error?.message || ''}
                            onChange={(e: React.BaseSyntheticEvent) =>
                                field.onChange(e.target.value)
                            }
                        />
                    )}
                    rules={{
                        required: {
                            value: true,
                            message: 'Last name is required',
                        },
                        maxLength: {
                            value: 100,
                            message: 'Last name must be 100 characters or less',
                        },
                        pattern: {
                            value: /^[^\s]+(?:$|.*[^\s]+$)/,
                            message:
                                'Last name can not start or end with a space',
                        },
                    }}
                />
            </Box>
            <Box mt={2}>
                <Controller
                    name={'password' as const}
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <WorldTextField
                            label="Password"
                            placeholder="Enter password"
                            fullWidth
                            type="password"
                            value={field.value}
                            error={Boolean(error)}
                            helperText={error?.message || ''}
                            onChange={(e: React.BaseSyntheticEvent) =>
                                field.onChange(e.target.value)
                            }
                        />
                    )}
                    rules={{
                        required: {
                            value: true,
                            message: 'Password is required',
                        },
                        minLength: {
                            value: 8,
                            message: 'Password must be 8 characters or longer',
                        },
                    }}
                />
            </Box>
        </>
    )
}

export default AccountInformation
