import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { setOpen as changeNotif } from '../../../../../slices/notification-slice'

import {
    Button,
    Dialog,
    DialogContent,
    DialogActions,
    Box,
    DialogTitle,
} from '@mui/material'

import { useForm, Controller } from 'react-hook-form'

import { Option } from '../../../../../utils/models/api-models'

import {
    Failure,
    AlreadyInvitedOpportunity,
    InvitedToOpportunity,
} from '../../../../../utils/constants/messages'
import {
    getOpportunity,
    getOpportunitiesFilter,
} from '../../../../../api/v1/opportunity'
import { inviteToOpportunity } from '../../../../../api/v1/opportunity'
import WorldSearchSingleSelect from '../../../../ui-components/search-single-select'

interface AddToOpportunityProps {
    open: boolean
    setOpen: (val: boolean) => void
    id: number
}

const AddToOpportunity = ({ open, setOpen, id }: AddToOpportunityProps) => {
    const dispatch = useDispatch()
    const [disable, setDisable] = useState(false)

    const [opportunities, setOpportunities] = useState<Option[]>([])

    const { control, trigger, getValues } = useForm({
        defaultValues: {
            opportunity: -1,
        },
    })

    const getOpportunities = async () => {
        const { success, data } = await getOpportunitiesFilter({
            draft: false,
            closed: false,
        })
        if (success && data) {
            setOpportunities(data)
        }
    }

    const onClickAdd = async () => {
        setDisable(true)
        if (!(await trigger())) {
            setDisable(false)
            return
        }

        const { opportunity } = getValues()
        const { success, data } = await getOpportunity({ id: +opportunity })
        if (!success || data === null) {
            dispatch(
                changeNotif({
                    open: true,
                    message: Failure,
                })
            )
            setDisable(false)
            return
        }
        // check if exists
        const { bidInvitees } = data
        const ids = new Set(bidInvitees.map((i) => +i?.['id'] || -1))
        if (ids.has(id)) {
            dispatch(
                changeNotif({
                    open: true,
                    message: AlreadyInvitedOpportunity,
                })
            )
            setDisable(false)
            return
        }
        // if not then add
        const res = await inviteToOpportunity({
            businessId: id,
            id: +opportunity,
        })

        dispatch(
            changeNotif({
                open: true,
                message: res.success ? InvitedToOpportunity : Failure,
            })
        )
        setDisable(false)
        setOpen(false)
    }

    useEffect(() => {
        if (open === true) {
            getOpportunities()
        }
    }, [open])

    return (
        <>
            <Dialog fullWidth open={open} onClose={() => setOpen(false)}>
                <DialogTitle sx={{ fontWeight: 'bold' }}>
                    Invite to Scope of Work
                </DialogTitle>
                <DialogContent>
                    <Box my={2}>
                        <Controller
                            name={'opportunity' as const}
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                                <WorldSearchSingleSelect
                                    options={opportunities}
                                    onChange={(selected) => {
                                        if (selected) {
                                            field.onChange(selected.value)
                                        }
                                    }}
                                    value={
                                        opportunities.find(
                                            (i) => i.value === field.value
                                        ) || null
                                    }
                                    error={error ? Boolean(error) : false}
                                    errorMessage={error?.message || ''}
                                    disable={disable}
                                    placeholder="Select an opportunity"
                                    label="Select an Opportunity"
                                />
                            )}
                            rules={{
                                required: {
                                    value: true,
                                    message: 'Opportunity is required',
                                },
                                validate: () => {
                                    const { opportunity } = getValues()
                                    if (opportunity === -1) {
                                        return 'Opportunity is required'
                                    }
                                },
                            }}
                        />
                    </Box>
                </DialogContent>
                <DialogActions
                    sx={{
                        mb: 2,
                        mx: 2,
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <Button
                        variant="text"
                        color="primary"
                        onClick={() => onClickAdd()}
                        sx={{
                            textTransform: 'none',
                        }}
                    >
                        Invite to Scope of Work
                    </Button>
                    <Button
                        variant="text"
                        color="secondary"
                        onClick={() => setOpen(false)}
                        sx={{
                            textTransform: 'none',
                        }}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default AddToOpportunity
