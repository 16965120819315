import { Box, Button, Divider, Typography } from '@mui/material'
import { useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useDispatch } from 'react-redux'

import Table from './table'
import moment from 'moment'

import WorldTextField from '../../ui-components/text-field'

import { Experience } from '../../../utils/models/api-models'

import {
    Failure,
    CreateExperience,
    RemoveExperience,
} from '../../../utils/constants/messages'
import { setOpen } from '../../../slices/notification-slice'
import WorldSwitch from '../../ui-components/switch'
import { DesktopDatePicker } from '@mui/x-date-pickers'
import {
    deleteBusinessExperience,
    upsertBusinessExperience,
} from '../../../api/v1/business-experience'

const Experiences = ({
    fetchBusiness,
    experiences,
}: {
    fetchBusiness: () => Promise<void>
    experiences: Experience[]
}) => {
    const dispatch = useDispatch()
    const { control, reset, getValues, trigger, watch } = useForm({
        defaultValues: {
            governmentAgency: '',
            primeContractor: '',
            workedDirectly: false,
            service: '',
            startDate: '',
            endDate: '',
            active: true,
        },
    })

    const [disable, setDisable] = useState(false)

    const handleSubmit = async () => {
        setDisable(true)
        if (!(await trigger())) {
            setDisable(false)
            return
        }
        const {
            governmentAgency,
            primeContractor,
            workedDirectly,
            service,
            startDate,
            endDate,
            active,
        } = getValues()
        const { success } = await upsertBusinessExperience({
            governmentAgency: governmentAgency.trim(),
            primeContractor: workedDirectly
                ? undefined
                : primeContractor.trim(),
            workedDirectlyWithGovernment: workedDirectly,
            service: service.trim().length === 0 ? undefined : service.trim(),
            startDate: startDate,
            endDate: active ? undefined : endDate,
            active: active,
        })
        dispatch(
            setOpen({
                open: true,
                message: success ? CreateExperience : Failure,
            })
        )
        reset()
        await fetchBusiness()
        setDisable(false)
    }

    const handleDelete = async (id: number) => {
        setDisable(true)
        const { success } = await deleteBusinessExperience({ id: id })
        dispatch(
            setOpen({
                open: true,
                message: success ? RemoveExperience : Failure,
            })
        )
        await fetchBusiness()
        setDisable(false)
    }

    return (
        <>
            <Typography variant="h6" mt={2}>
                Add a new experience
            </Typography>
            <Typography variant="caption" sx={{ width: 150 }}>
                Let contractors know about your past work.
            </Typography>
            <Box display="flex" justifyContent="center" mt={6}>
                <Typography variant="subtitle2" width={200} sx={{ mr: 4 }}>
                    Government Agency
                </Typography>
                <Controller
                    name={'governmentAgency' as const}
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <WorldTextField
                            placeholder="GDOT"
                            value={field.value}
                            variant="outlined"
                            disabled={disable}
                            error={Boolean(error)}
                            helperText={error?.message || ''}
                            onChange={(e) => field.onChange(e.target.value)}
                            sx={{
                                width: 400,
                            }}
                        />
                    )}
                    rules={{
                        required: {
                            value: true,
                            message: 'Government agency name is required',
                        },
                        maxLength: {
                            value: 250,
                            message:
                                'Government agency name must be 250 characters or less',
                        },
                        pattern: {
                            value: /^[^\s]+(?:$|.*[^\s]+$)/,
                            message:
                                'Government agency name can not start or end with a space',
                        },
                    }}
                />
            </Box>
            <Box display="flex" justifyContent="center" mt={4}>
                <Typography variant="subtitle2" width={200} sx={{ mr: 4 }}>
                    Worked Directly With Government Agency?
                </Typography>
                <Box width={400}>
                    <Controller
                        name={'workedDirectly' as const}
                        control={control}
                        render={({ field }) => (
                            <WorldSwitch
                                label={field.value ? 'Yes' : 'No'}
                                switchProps={{
                                    onChange: (e) =>
                                        field.onChange(e.target.checked),
                                    checked: field.value,
                                    disabled: disable,
                                    required: true,
                                }}
                            />
                        )}
                    />
                </Box>
            </Box>
            {!watch('workedDirectly') && (
                <Box display="flex" justifyContent="center" mt={4}>
                    <Typography variant="subtitle2" width={200} sx={{ mr: 4 }}>
                        Prime Contractor
                    </Typography>
                    <Controller
                        name={'primeContractor' as const}
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <WorldTextField
                                placeholder="DBEWorld"
                                value={field.value}
                                variant="outlined"
                                disabled={disable}
                                error={Boolean(error)}
                                helperText={error?.message || ''}
                                onChange={(e) => field.onChange(e.target.value)}
                                sx={{
                                    width: 400,
                                }}
                            />
                        )}
                        rules={{
                            maxLength: {
                                value: 250,
                                message:
                                    'Prime contractor name must be 250 characters or less',
                            },
                            pattern: {
                                value: /^[^\s]+(?:$|.*[^\s]+$)/,
                                message:
                                    'Prime contractor name can not start or end with a space',
                            },
                            validate: () => {
                                const { workedDirectly, primeContractor } =
                                    getValues()
                                if (
                                    !workedDirectly &&
                                    primeContractor.length === 0
                                ) {
                                    return 'Prime contractor name is required'
                                }
                            },
                        }}
                    />
                </Box>
            )}
            <Box display="flex" justifyContent="center" mt={4}>
                <Typography variant="subtitle2" width={200} sx={{ mr: 4 }}>
                    What did you do?
                </Typography>
                <Controller
                    name={'service' as const}
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <WorldTextField
                            placeholder="We provided construction services"
                            value={field.value}
                            variant="outlined"
                            multiline
                            minRows={3}
                            maxRows={5}
                            disabled={disable}
                            error={Boolean(error)}
                            helperText={error?.message || ''}
                            onChange={(e) => field.onChange(e.target.value)}
                            sx={{
                                width: 400,
                            }}
                        />
                    )}
                    rules={{
                        maxLength: {
                            value: 1000,
                            message: 'It must be 1000 characters or less',
                        },
                        pattern: {
                            value: /^[^\s]+(?:$|.*[^\s]+$)/,
                            message: 'It can not start or end with a space',
                        },
                    }}
                />
            </Box>
            <Box display="flex" justifyContent="center" mt={4}>
                <Typography variant="subtitle2" width={200} sx={{ mr: 4 }}>
                    Start date
                </Typography>
                <Controller
                    name={'startDate' as const}
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <DesktopDatePicker
                            inputFormat="YYYY-MM-DD"
                            value={field.value}
                            disabled={disable}
                            onChange={(e: any) => {
                                field.onChange(e ? e.format('YYYY-MM-DD') : '')
                            }}
                            disableHighlightToday
                            disableFuture={true}
                            renderInput={(params) => (
                                <WorldTextField
                                    label="End Date"
                                    variant="outlined"
                                    sx={{ width: 400 }}
                                    disabled={disable}
                                    {...params}
                                    error={Boolean(error)}
                                    helperText={error?.message || ''}
                                />
                            )}
                        />
                    )}
                    rules={{
                        required: {
                            value: true,
                            message: 'Start date is required',
                        },
                        validate: () => {
                            const date = moment(
                                getValues('startDate'),
                                'YYYY-MM-DD'
                            )

                            if (!date.isValid()) {
                                return 'Start date must be valid'
                            }
                        },
                    }}
                />
            </Box>
            <Box display="flex" justifyContent="center" mt={4}>
                <Typography variant="subtitle2" width={200} sx={{ mr: 4 }}>
                    Active?
                </Typography>
                <Box width={400}>
                    <Controller
                        name={'active' as const}
                        control={control}
                        render={({ field }) => (
                            <WorldSwitch
                                label={field.value ? 'Yes' : 'No'}
                                switchProps={{
                                    onChange: (e) =>
                                        field.onChange(e.target.checked),
                                    checked: field.value,
                                    disabled: disable,
                                    required: true,
                                }}
                            />
                        )}
                    />
                </Box>
            </Box>

            {!watch('active') && (
                <Box display="flex" justifyContent="center" mt={4}>
                    <Typography variant="subtitle2" width={200} sx={{ mr: 4 }}>
                        End date
                    </Typography>
                    <Controller
                        name={'endDate' as const}
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <DesktopDatePicker
                                inputFormat="YYYY-MM-DD"
                                value={field.value}
                                disabled={disable}
                                onChange={(e: any) => {
                                    field.onChange(
                                        e ? e.format('YYYY-MM-DD') : ''
                                    )
                                }}
                                disableHighlightToday
                                disableFuture={true}
                                renderInput={(params) => (
                                    <WorldTextField
                                        label="End Date"
                                        variant="outlined"
                                        sx={{ width: 400 }}
                                        disabled={disable}
                                        {...params}
                                        error={Boolean(error)}
                                        helperText={error?.message || ''}
                                    />
                                )}
                            />
                        )}
                        rules={{
                            validate: () => {
                                const date = moment(
                                    getValues('endDate'),
                                    'YYYY-MM-DD'
                                )
                                const { active, startDate } = getValues()
                                const start = moment(startDate, 'YYYY-MM-DD')
                                if (!date.isValid() || active) {
                                    return 'Valid end date is required'
                                }
                                if (start > date) {
                                    return 'End date must be after start date'
                                }
                            },
                        }}
                    />
                </Box>
            )}

            <Box display="flex" justifyContent="center" mt={6}>
                <Button
                    variant="text"
                    color="primary"
                    sx={{
                        px: 2,
                    }}
                    disabled={disable}
                    onClick={() => handleSubmit()}
                >
                    Add Experience
                </Button>
            </Box>
            <Divider variant="middle" sx={{ mt: 6, mb: 2 }} />
            <Typography variant="h6">Existing experiences</Typography>
            <Typography variant="caption" sx={{ width: 150 }}>
                View and delete your existing experiences.
            </Typography>
            <Box mt={4}>
                {experiences.length === 0 ? (
                    <Typography variant="subtitle2">
                        There are no existing experiences.
                    </Typography>
                ) : (
                    <Table
                        headers={[
                            'Agency',
                            'Contractor',
                            'Start Date',
                            'End Date',
                        ]}
                        values={[
                            'governmentAgency',
                            'primeContractor',
                            'startDate',
                            'endDate',
                        ]}
                        rows={experiences}
                        onDelete={handleDelete}
                    />
                )}
            </Box>
        </>
    )
}

export default Experiences
