import callAPI from '../api-base'
import { StatusResponse } from '../../../utils/models/api-models'

const ClientCommunicationAPI = () => {
    const createClientCommunication = ({
        note,
        opportunity,
        business,
        type,
    }: {
        note: string
        opportunity: number
        business: number
        type: number
    }): StatusResponse | Promise<StatusResponse> => {
        return callAPI({
            url: 'client-communication/',
            type: 'post',
            body: {
                note: note,
                opportunity: opportunity,
                business: business,
                type: type,
            },
            auth: true,
        })
            .then((res: any) => {
                const { success } = res.data
                return { success: success }
            })
            .catch((err: any) => {
                return {
                    success: false,
                }
            })
    }
    return { createClientCommunication }
}

export const { createClientCommunication } = ClientCommunicationAPI()
