import { Box, Button, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useForm, Controller } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { getMyBusiness, updateMyBusiness } from '../../../api/v1/business'

import WorldTextField from '../../ui-components/text-field'
import WorldSelect from '../../ui-components/select'

import { HEADCOUNT } from '../../../utils/constants/info-constants'

import { Failure, UpdateCompanyInfo } from '../../../utils/constants/messages'
import { setOpen } from '../../../slices/notification-slice'

const CompanyInfo = ({
    fetchBusiness,
    name,
    headcount,
    website,
    profile,
}: {
    fetchBusiness: () => Promise<void>
    name: string
    headcount: string
    website: string
    profile: string
}) => {
    const dispatch = useDispatch()

    const { control, getValues, trigger } = useForm({
        defaultValues: {
            name: name,
            profile: profile,
            headcount: headcount,
            website: website,
        },
    })

    const [disable, setDisable] = useState(false)
    const navigate = useNavigate()

    const handleSubmit = async () => {
        setDisable(true)

        if (!(await trigger())) {
            setDisable(false)
            return
        }

        const { name, profile, website, headcount } = getValues()
        const { success } = await updateMyBusiness({
            name,
            profile,
            headcount,
            website,
        })

        dispatch(
            setOpen({
                open: true,
                message: success ? UpdateCompanyInfo : Failure,
            })
        )
        await fetchBusiness()

        setDisable(false)
    }

    return (
        <>
            <Box display="flex" justifyContent="center" mt={8}>
                <Typography variant="subtitle2" width={200} sx={{ mr: 4 }}>
                    Business Name
                </Typography>
                <Controller
                    name={'name' as const}
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <WorldTextField
                            placeholder="Enter business"
                            value={field.value}
                            variant="outlined"
                            disabled={disable}
                            error={Boolean(error)}
                            helperText={error?.message || ''}
                            onChange={(e) => field.onChange(e.target.value)}
                            sx={{
                                width: 400,
                            }}
                        />
                    )}
                    rules={{
                        required: {
                            value: true,
                            message: 'Business name is required',
                        },
                        maxLength: {
                            value: 150,
                            message:
                                'Business name must be 150 characters or less',
                        },
                        pattern: {
                            value: /^[^\s]+(?:$|.*[^\s]+$)/,
                            message:
                                'Business name can not start or end with a space',
                        },
                    }}
                />
            </Box>
            <Box display="flex" justifyContent="center" mt={4}>
                <Typography variant="subtitle2" width={200} sx={{ mr: 4 }}>
                    Headcount
                </Typography>
                <Controller
                    name={'headcount' as const}
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <WorldSelect
                            placeholder="Enter headcount"
                            options={HEADCOUNT}
                            disabled={disable}
                            value={field.value}
                            error={Boolean(error)}
                            onChange={(e) => field.onChange(e.target.value)}
                            sx={{
                                width: 400,
                            }}
                        />
                    )}
                    rules={{
                        required: {
                            value: true,
                            message: 'Headcount is required',
                        },
                    }}
                />
            </Box>
            <Box display="flex" justifyContent="center" mt={4}>
                <Box width={200} sx={{ mr: 4 }}>
                    <Typography variant="subtitle2" mb={1}>
                        Website (Optional)
                    </Typography>
                    <Typography variant="subtitle2">
                        For example, https://www.google.com should be written as
                        www.google.com.
                    </Typography>
                </Box>
                <Controller
                    name={'website' as const}
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <WorldTextField
                            placeholder="Enter website"
                            value={field.value}
                            variant="outlined"
                            disabled={disable}
                            error={Boolean(error)}
                            helperText={error?.message || ''}
                            onChange={(e) => field.onChange(e.target.value)}
                            sx={{
                                width: 400,
                            }}
                        />
                    )}
                    rules={{
                        maxLength: {
                            value: 150,
                            message: 'Website must be 150 characters or less',
                        },
                        pattern: {
                            value: /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
                            message: 'Please enter a website',
                        },
                    }}
                />
            </Box>
            <Box display="flex" justifyContent="center" mt={4}>
                <Typography variant="subtitle2" width={200} sx={{ mr: 4 }}>
                    About Us
                </Typography>
                <Controller
                    name={'profile' as const}
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <WorldTextField
                            placeholder="Enter information about your business"
                            value={field.value}
                            multiline
                            minRows={3}
                            maxRows={5}
                            variant="outlined"
                            disabled={disable}
                            error={Boolean(error)}
                            helperText={error?.message || ''}
                            onChange={(e) => field.onChange(e.target.value)}
                            sx={{
                                width: 400,
                            }}
                        />
                    )}
                    rules={{
                        required: {
                            value: true,
                            message: 'The about us section is required',
                        },
                        maxLength: {
                            value: 1000,
                            message:
                                'The about us section must be 1000 characters or less',
                        },
                    }}
                />
            </Box>
            <Box display="flex" justifyContent="center" mt={6}>
                <Button
                    variant="text"
                    color="primary"
                    sx={{
                        px: 2,
                    }}
                    disabled={disable}
                    onClick={() => handleSubmit()}
                >
                    Save Company Info
                </Button>
            </Box>
        </>
    )
}

export default CompanyInfo
