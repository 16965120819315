import { Box, Typography } from '@mui/material'

import Table from './table'

interface SearchResultsProps {
    data: any[]
    count: number
    setPage: (page: number) => void
    disable: boolean
    page: number
}

const SearchResults = ({
    data,
    count,
    setPage,
    page,
    disable,
}: SearchResultsProps) => {
    return (
        <>
            <Typography variant="h6" fontWeight="bold">
                Search Results
            </Typography>
            <Box mt={1} height={'100%'}>
                <Table
                    rows={data}
                    count={count}
                    page={page}
                    setPage={setPage}
                    disable={disable}
                />
            </Box>
        </>
    )
}

export default SearchResults
