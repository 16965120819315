import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { Delete, Edit, Check, Close } from '@mui/icons-material'
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    IconButton,
    Typography,
} from '@mui/material'

import { useForm, Controller } from 'react-hook-form'

import WorldTextField from '../../ui-components/text-field'

import { Option } from '../../../utils/models/api-models'
import { Failure, UpdateWatchlistName } from '../../../utils/constants/messages'
import { setOpen } from '../../../slices/notification-slice'

import { deleteWatchlist, upsertWatchlist } from '../../../api/v1/watchlist'

interface NameEditCellProps {
    watchlistId?: number
    name?: string
    fetchWatchlists: (selected?: Option) => void
}

const NameEditCell = ({
    watchlistId,
    name,
    fetchWatchlists,
}: NameEditCellProps): JSX.Element => {
    const dispatch = useDispatch()
    const [editing, setEditing] = useState(false)
    const [deleteConfirm, setDeleteConfirm] = useState(false)

    const { control, trigger, getValues, reset } = useForm({
        defaultValues: {
            watchlistName: '',
        },
    })

    useEffect(() => {
        reset({ watchlistName: name })
    }, [name])

    if (!watchlistId) {
        return <></>
    }

    const deleteMyWatchlist = async () => {
        await deleteWatchlist({
            id: watchlistId,
        })
        fetchWatchlists()
        setDeleteConfirm(false)
    }

    const editName = async () => {
        if (!(await trigger())) {
            return
        }
        const { watchlistName } = getValues()
        const { success } = await upsertWatchlist({
            name: watchlistName,
            id: watchlistId,
        })
        fetchWatchlists({
            label: watchlistName ?? name ?? '',
            value: watchlistId,
        })
        if (success) {
            setEditing(false)
        }

        dispatch(
            setOpen({
                open: true,
                message: success ? UpdateWatchlistName : Failure,
            })
        )
    }

    const nameCell = () => {
        if (editing) {
            return (
                <Controller
                    name={'watchlistName' as const}
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <WorldTextField
                            placeholder="Enter watchlist name"
                            value={field.value}
                            error={Boolean(error)}
                            fullWidth
                            label="Watchlist Name"
                            helperText={error?.message || ''}
                            onChange={(e) => field.onChange(e.target.value)}
                            sx={{
                                width: 400,
                            }}
                        />
                    )}
                    rules={{
                        required: {
                            value: true,
                            message: 'Watchlist name is required',
                        },
                        maxLength: {
                            value: 50,
                            message:
                                'Watchlist name must be 50 characters or less',
                        },
                        pattern: {
                            value: /^[^\s]+(?:$|.*[^\s]+$)/,
                            message:
                                'Watchlist name can not start or end with a space',
                        },
                    }}
                />
            )
        }

        return (
            <Typography variant="h6" fontWeight="bold">
                {name}
            </Typography>
        )
    }

    const toolbar = () => {
        if (editing) {
            return (
                <>
                    <IconButton
                        sx={{ ...iconButtonStyle, mr: 1 }}
                        onClick={() => editName()}
                    >
                        <Check sx={iconButtonStyle} />
                    </IconButton>
                    <IconButton
                        sx={iconButtonStyle}
                        onClick={() => setEditing(false)}
                    >
                        <Close sx={iconButtonStyle} />
                    </IconButton>
                </>
            )
        }

        return (
            <>
                <IconButton
                    sx={{ ...iconButtonStyle, mr: 1 }}
                    onClick={() => setEditing(true)}
                >
                    <Edit sx={iconButtonStyle} />
                </IconButton>
                <IconButton
                    sx={iconButtonStyle}
                    onClick={() => setDeleteConfirm(true)}
                >
                    <Delete sx={iconButtonStyle} />
                </IconButton>
            </>
        )
    }

    return (
        <>
            <Box display="flex" justifyContent="space-between">
                {nameCell()}
                <Box display="flex">{toolbar()}</Box>
            </Box>
            <Dialog
                open={deleteConfirm}
                maxWidth="sm"
                onClose={() => setDeleteConfirm(false)}
            >
                <DialogTitle
                    sx={{
                        width: 200,
                        mx: 'auto',
                        fontWeight: 'light',
                    }}
                >
                    Delete Watchlist?
                </DialogTitle>
                <DialogActions
                    sx={{ justifyContent: 'space-between', mx: 2, mb: 2 }}
                >
                    <Button
                        variant="text"
                        color="primary"
                        sx={{
                            width: 100,
                        }}
                        onClick={async () => await deleteMyWatchlist()}
                    >
                        Yes
                    </Button>
                    <Button
                        variant="text"
                        color="secondary"
                        sx={{
                            width: 100,
                        }}
                        onClick={() => setDeleteConfirm(false)}
                    >
                        No
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

const iconButtonStyle = {
    height: 25,
    width: 25,
}

export default NameEditCell
