import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import LandingPage from '../../components/landing-page'
import Login from '../../components/landing-page/login'
import Error from '../../components/universal/error'
import ResetPassword from '../../components/landing-page/reset-password/reset-password'
import ResetPasswordForm from '../../components/landing-page/reset-password/reset-password-form'
import ResendActivation from '../../components/landing-page/activation/resend-activation'
import Activate from '../../components/landing-page/activation'
import Register from '../../components/landing-page/register'

import PlatformPage from '../../components/platform'
import Search from '../../components/platform/search'
import UserSettings from '../../components/platform/user-settings'
import Business from '../../components/platform/business'
import BusinessEditor from '../../components/platform/business-editor'
import Watchlist from '../../components/platform/watchlist'
import OpportunityPage from '../../components/platform/opportunity/opportunity-page'
import Opportunity from '../../components/platform/opportunity'
import UpsertOpportunity from '../../components/platform/opportunity/prime/scope-of-work/upsert-scope-of-work'
import UpsertResponse from '../../components/platform/opportunity/business/response/upsert-response'
import GoodFaithEffortReport from '../../components/platform/opportunity/prime/opportunity/good-faith-effort-report'
import DetailedResponse from '../../components/platform/opportunity/prime/response/detailed-response'

import LoggedOutRoute from './logged-out-route'
import LoggedInRoute from './logged-in-route'
import Super from '../../components/platform/super'

const RouterComponent = () => (
    <>
        <Router>
            <Routes>
                <Route element={<LoggedInRoute />}>
                    <Route
                        path="platform/search"
                        element={<PlatformPage component={<Search />} />}
                    />

                    <Route
                        path="platform/settings"
                        element={<PlatformPage component={<UserSettings />} />}
                    />

                    <Route
                        path="platform/business/:id"
                        element={<PlatformPage component={<Business />} />}
                    />

                    <Route
                        path="platform/scope-of-work/:id"
                        element={
                            <PlatformPage component={<OpportunityPage />} />
                        }
                    />

                    <Route
                        path="platform/opportunity"
                        element={<PlatformPage component={<Opportunity />} />}
                    />

                    <Route
                        path="platform/scope-of-work/edit"
                        element={
                            <PlatformPage
                                allowed="prime"
                                component={<UpsertOpportunity />}
                            />
                        }
                    />

                    <Route
                        path="platform/business/edit"
                        element={
                            <PlatformPage
                                allowed="business"
                                component={<BusinessEditor />}
                            />
                        }
                    />

                    <Route
                        path="platform/scope-of-work/:id/response"
                        element={
                            <PlatformPage
                                allowed="business"
                                component={<UpsertResponse />}
                            />
                        }
                    />

                    <Route
                        path="platform/watchlist"
                        element={
                            <PlatformPage
                                allowed="prime"
                                component={<Watchlist />}
                            />
                        }
                    />

                    <Route
                        path="platform/good-faith-effort-report/:id"
                        element={
                            <PlatformPage
                                allowed="prime"
                                component={<GoodFaithEffortReport />}
                            />
                        }
                    />

                    <Route
                        path="platform/scope-of-work/:opId/response/:respId"
                        element={
                            <PlatformPage
                                allowed="prime"
                                component={<DetailedResponse />}
                            />
                        }
                    />

                    <Route
                        path="platform/super"
                        element={
                            <PlatformPage
                                allowed="all"
                                isSuperOnly={true}
                                component={<Super />}
                            />
                        }
                    />
                </Route>

                <Route path="*" element={<Error />} />

                <Route element={<LoggedOutRoute />}>
                    <Route path="login" element={<Login />} />
                    <Route path="/" element={<LandingPage />} />
                    <Route path="register" element={<Register />} />
                    <Route
                        path="resend-activation"
                        element={<ResendActivation />}
                    />
                    <Route path="reset-password" element={<ResetPassword />} />
                    <Route
                        path="reset-password/:uidb64/:token"
                        element={<ResetPasswordForm />}
                    />
                    <Route
                        path="activate/:uidb64/:token"
                        element={<Activate />}
                    />
                </Route>
            </Routes>
        </Router>
    </>
)

export default RouterComponent
