import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack,
    TablePagination,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { OpportunityGroup } from '../../../../../../utils/models/api-models'
import { setOpen as openNotification } from '../../../../../../slices/notification-slice'
import { useDispatch } from 'react-redux'
import {
    CreateOpportunityGroup,
    Failure,
    RemoveOpportunity,
    RemoveOpportunityGroup,
    UpdateOpportunityGroup,
} from '../../../../../../utils/constants/messages'
import NoSearchResult from '../../../../search/search-result-states/no-search-result'
import Row from './row'
import React from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import WorldTextField from '../../../../../ui-components/text-field'
import { deleteOpportunity as deleteOpportunityAPI } from '../../../../../../api/v1/opportunity'
import CopySow from './copy-sow'
import {
    getOpportunityGroups,
    deleteOpportunityGroup as deleteOpportunityGroupAPI,
    upsertOpportunityGroup as upsertOpportunityGroupAPI,
} from '../../../../../../api/v1/opportunity-group'

const OpportunityTable = ({
    openCreateOpGroup,
    setOpenCreateOpGroup,
    openCopySow,
    setOpenCopySow,
}: {
    openCreateOpGroup: boolean
    setOpenCreateOpGroup: (val: boolean) => void
    openCopySow: boolean
    setOpenCopySow: (val: boolean) => void
}) => {
    const [opportunityGroups, setOpportunityGroups] = useState<
        OpportunityGroup[]
    >([])
    const [id, setId] = useState<number | null>(null)
    const [opportunityGroupCount, setOpportunityGroupCount] =
        useState<number>(0)
    const [opportunityGroupPage, setOpportunityGroupPage] = useState<number>(0)
    const [openDeleteOpGroup, setOpenDeleteOpGroup] = useState<boolean>(false)
    const [openDeleteOp, setOpenDeleteOp] = useState<boolean>(false)
    const [existingSowId, setExistingSowId] = useState<number | null>(null)

    const dispatch = useDispatch()

    const methods = useForm({
        defaultValues: {
            title: '',
        },
    })

    const { getValues, control, trigger, setError, setValue } = methods

    const upsertOpportunityGroup = async () => {
        const { title } = getValues()
        if (!(await trigger()) || title.trim().length === 0) {
            setError('title', { type: 'custom', message: 'Title is required' })
            setValue('title', '')
            return
        }
        let success: boolean = false
        if (id) {
            const result = await upsertOpportunityGroupAPI({
                id: id,
                name: title,
            })
            success = result.success
        } else {
            const result = await upsertOpportunityGroupAPI({ name: title })
            success = result.success
        }
        dispatch(
            openNotification({
                open: true,
                message: id
                    ? success
                        ? UpdateOpportunityGroup
                        : Failure
                    : success
                    ? CreateOpportunityGroup
                    : Failure,
            })
        )
        await getOpportunities()
        setOpenCreateOpGroup(false)
        setValue('title', '')
        setId(null)
    }

    const deleteOpportunityGroup = async () => {
        if (!id) {
            return
        }
        const result = await deleteOpportunityGroupAPI({
            id: id,
        })
        dispatch(
            openNotification({
                message: result.success ? RemoveOpportunityGroup : Failure,
                open: true,
            })
        )
        await getOpportunities()
        setId(null)
        setOpenDeleteOpGroup(false)
    }

    const deleteOpportunity = async () => {
        if (!id) {
            return
        }
        const result = await deleteOpportunityAPI({
            id: id,
        })
        dispatch(
            openNotification({
                message: result.success ? RemoveOpportunity : Failure,
                open: true,
            })
        )
        await getOpportunities()
        setId(null)
        setOpenDeleteOp(false)
    }

    const getOpportunities = async () => {
        const { success, data } = await getOpportunityGroups({
            page: opportunityGroupPage + 1,
        })
        if (!success) {
            dispatch(
                openNotification({
                    open: true,
                    message: Failure,
                })
            )
            return
        }
        setOpportunityGroups(data?.data || [])
        setOpportunityGroupCount(data?.count || 0)
    }

    useEffect(() => {
        getOpportunities()
    }, [opportunityGroupPage])

    const displayOpportunityGroups = () => {
        return opportunityGroups.map((o) => (
            <FormProvider {...methods}>
                <Row
                    opportunityGroup={o}
                    openOpportunityGroup={(id: number) => {
                        setId(id)
                        setOpenCreateOpGroup(true)
                    }}
                    openDeleteOpportunityGroup={(id: number) => {
                        setId(id)
                        setOpenDeleteOpGroup(true)
                    }}
                    openDeleteOpportunity={(id: number) => {
                        setId(id)
                        setOpenDeleteOp(true)
                    }}
                    openCopySow={(id: number) => {
                        setExistingSowId(id)
                        setOpenCopySow(true)
                    }}
                />
            </FormProvider>
        ))
    }

    return (
        <React.Fragment>
            <Stack spacing={2} sx={{ minHeight: '100vh', width: '100%' }}>
                {opportunityGroups.length > 0 ? (
                    displayOpportunityGroups()
                ) : (
                    <Box height="500px">
                        <NoSearchResult
                            title={'Opportunity is Empty'}
                            subtitle="Plase create an opportuntiy."
                        />
                    </Box>
                )}
            </Stack>
            <TablePagination
                sx={{ my: 4 }}
                component="div"
                count={opportunityGroupCount}
                page={opportunityGroupPage}
                labelRowsPerPage=""
                rowsPerPageOptions={[10]}
                onPageChange={(
                    event: React.MouseEvent<HTMLButtonElement> | null,
                    newPage: number
                ) => {
                    setOpportunityGroupPage(newPage)
                }}
                rowsPerPage={10}
            />
            <Dialog
                fullWidth
                key="upsert-op-group"
                open={openCreateOpGroup}
                onClose={() => {
                    setOpenCreateOpGroup(false)
                    setId(null)
                    setValue('title', '')
                }}
            >
                <DialogTitle sx={{ fontWeight: 'light' }}>
                    {id ? 'Edit' : 'Create'} Opportunity
                </DialogTitle>
                <DialogContent>
                    <Box my={1}>
                        <Controller
                            name={'title' as const}
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                                <WorldTextField
                                    placeholder="Opportunity"
                                    label="Title"
                                    fullWidth
                                    value={field.value}
                                    onChange={(e: React.BaseSyntheticEvent) =>
                                        field.onChange(e.target.value)
                                    }
                                    error={Boolean(error)}
                                    helperText={error?.message || ''}
                                />
                            )}
                            rules={{
                                required: {
                                    value: true,
                                    message: 'Title is required',
                                },
                                maxLength: {
                                    value: 150,
                                    message: `Title must be 150 characters or less`,
                                },
                            }}
                        />
                    </Box>
                </DialogContent>
                <DialogActions
                    sx={{ justifyContent: 'space-between', mx: 2, mb: 2 }}
                >
                    <Button
                        variant="text"
                        color="secondary"
                        sx={{
                            width: 100,
                        }}
                        onClick={() => {
                            setOpenCreateOpGroup(false)
                            setId(null)
                            setValue('title', '')
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="text"
                        color="primary"
                        sx={{
                            width: 100,
                        }}
                        onClick={() => upsertOpportunityGroup()}
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                key="delete-op-group"
                open={openDeleteOpGroup}
                onClose={() => {
                    setOpenDeleteOpGroup(false)
                }}
            >
                <DialogTitle sx={{ fontWeight: 'light' }}>
                    Delete Opportunity
                </DialogTitle>
                <DialogActions
                    sx={{ justifyContent: 'space-between', mx: 2, mb: 2 }}
                >
                    <Button
                        variant="text"
                        color="secondary"
                        sx={{
                            width: 100,
                        }}
                        onClick={() => {
                            setOpenDeleteOpGroup(false)
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="text"
                        color="primary"
                        sx={{
                            width: 100,
                        }}
                        onClick={() => deleteOpportunityGroup()}
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                key="delete-opportunity"
                open={openDeleteOp}
                onClose={() => {
                    setOpenDeleteOp(false)
                }}
            >
                <DialogTitle sx={{ fontWeight: 'light' }}>
                    Delete Scope of Work
                </DialogTitle>
                <DialogActions
                    sx={{ justifyContent: 'space-between', mx: 2, mb: 2 }}
                >
                    <Button
                        variant="text"
                        color="secondary"
                        sx={{
                            width: 100,
                        }}
                        onClick={() => {
                            setOpenDeleteOp(false)
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="text"
                        color="primary"
                        sx={{
                            width: 100,
                        }}
                        onClick={() => deleteOpportunity()}
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
            <CopySow
                open={openCopySow}
                setOpen={(val: boolean) => {
                    setOpenCopySow(val)
                    if (!val) {
                        setExistingSowId(null)
                    }
                }}
                existingSowId={existingSowId}
            />
        </React.Fragment>
    )
}

export default OpportunityTable
