import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Step, StepLabel, Stepper, Button, Box } from '@mui/material'
import { useForm, FormProvider, set } from 'react-hook-form'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { getOpportunity } from '../../../../../api/v1/opportunity'
import Page from '../../../../ui-components/page'
import { Opportunity, Reqs } from '../../../../../utils/models/api-models'
import Overview from './upsert/overview'
import moment from 'moment'
import Requirements from './upsert/requirements'
import BusinessInvitation from './upsert/business-invitation'
import { setOpen } from '../../../../../slices/notification-slice'
import {
    Failure,
    CreateOpportunity,
    UpdateOpportunity,
} from '../../../../../utils/constants/messages'
import { upsertOpportunity } from '../../../../../api/v1/opportunity'

const fetchOpportunity = async (id: number) => {
    const { success, data } = await getOpportunity({ id: id })
    if (success) {
        return data
    }
    return null
}

const UpsertOpportunity = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [searchParams, _] = useSearchParams()
    const [disable, setDisable] = useState(false)
    const [id, setId] = useState(-1)
    const [stepId, setStepId] = useState(0)
    const [editorType, setEditorType] = useState<0 | 1>(0)
    const [opportunity, setOpportunity] = useState<Opportunity | null>(null)

    const form = useForm({
        defaultValues: {
            name: '',
            description: '',
            endDate: '',
            opGroup: -1,
            pricing: false,
            open: true,
            technicalReqs: [] as Reqs[],
            commercialReqs: [] as Reqs[],
            invitees: [] as Record<string, string | number>[],
            emailTemplate: '',
            useCustomEmailTemplate: false,
            sendEmailToMe: false,
            metadata: {},
        },
    })

    const getSearchParams = async () => {
        const id = searchParams.get('id')
        const opGroupId = searchParams.get('opportunityId')
        if (opGroupId) {
            form.setValue('opGroup', +opGroupId)
        }
        if (!id) {
            return
        }
        const data = await fetchOpportunity(+id)
        setId(+id)
        if (data) {
            setOpportunity(data)
            setEditorType(1)
            form.reset({
                name: data.title,
                description: data.description || '',
                endDate: moment(data.endDate, 'YYYY-MM-DD').format(
                    'YYYY-MM-DD'
                ),
                pricing: data.pricing,
                opGroup: data.opportunityGroupId,
                technicalReqs: data.technicalReqs
                    ? (data.technicalReqs as Reqs[])
                    : [],
                commercialReqs: data.commercialReqs
                    ? (data.commercialReqs as Reqs[])
                    : [],
                open: data.open,
                invitees: data.bidInvitees || [],
                emailTemplate:
                    data.notificationTemplates.filter(
                        (i) => i.type != 'SMS'
                    )?.[0]?.template || '',
                useCustomEmailTemplate: data.useCustomEmailTemplate,
                sendEmailToMe: data.metadata?.['ccEmail'] === 1 || false,
                metadata: data.metadata || {},
            })
        }
    }

    const goNextPage = async ({ values }: { values: String[] }) => {
        // @ts-ignore
        if (!(await form.trigger(values))) {
            return
        }
        setStepId((id) => id + 1)
    }

    const goBackPage = async () => {
        setStepId((id) => id - 1)
    }

    const buildMetadata = () => {
        const metadata = opportunity?.metadata || {}
        if (!('ccEmail' in metadata)) {
            metadata['ccEmail'] = 0
        }
        metadata['ccEmail'] = form.getValues().sendEmailToMe ? 1 : 0
        return metadata
    }

    const submit = async (draft: boolean) => {
        if (!(await form.trigger('emailTemplate'))) {
            return
        }
        setDisable(true)
        const {
            name,
            description,
            endDate,
            pricing,
            opGroup,
            technicalReqs,
            commercialReqs,
            open,
            invitees,
            useCustomEmailTemplate,
            emailTemplate,
        } = form.getValues()

        const params = {
            title: name,
            description: description,
            endDate,
            pricing,
            bidInvitees: invitees,
            opportunityGroupId: opGroup,
            technicalReqs,
            commercialReqs,
            draft,
            emailTemplate: useCustomEmailTemplate ? emailTemplate : null,
            useCustomEmailTemplate,
            metadata: buildMetadata(),
        }

        let submitSuccess = false

        if (editorType === 1) {
            const { success } = await upsertOpportunity({
                ...params,
                id: id,
                open: open,
            })
            submitSuccess = success
        } else {
            const { success } = await upsertOpportunity({
                ...params,
            })
            submitSuccess = success
        }

        setDisable(false)

        dispatch(
            setOpen({
                open: true,
                message: submitSuccess
                    ? editorType === 0
                        ? CreateOpportunity
                        : UpdateOpportunity
                    : Failure,
            })
        )

        navigate('/platform/opportunity')
    }

    useEffect(() => {
        getSearchParams()
    }, [searchParams])

    const actionButtons: Record<number, JSX.Element[]> = {
        0: [
            <Button
                color="primary"
                variant="text"
                onClick={() =>
                    goNextPage({
                        values: ['name', 'description', 'endDate', 'opGroup'],
                    })
                }
            >
                Next
            </Button>,
        ],
        1: [
            <Button color="secondary" onClick={() => goBackPage()}>
                Back
            </Button>,
            <Button
                color="primary"
                onClick={() =>
                    goNextPage({
                        values: ['technicalReqs'],
                    })
                }
            >
                Next
            </Button>,
        ],
        2: [
            <Button color="secondary" onClick={() => goBackPage()}>
                Back
            </Button>,
            <Button
                color="primary"
                onClick={() =>
                    goNextPage({
                        values: ['commercialReqs'],
                    })
                }
            >
                Next
            </Button>,
        ],
        3: [
            <Button color="secondary" onClick={() => goBackPage()}>
                Back
            </Button>,
            <Button color="primary" onClick={() => submit(true)}>
                Draft
            </Button>,
            <Button color="primary" onClick={() => submit(false)}>
                Publish
            </Button>,
        ],
    }

    const forms: Record<number, JSX.Element> = {
        0: (
            <FormProvider {...form}>
                <Overview editorType={editorType} disable={disable} />
            </FormProvider>
        ),
        1: (
            <FormProvider {...form}>
                <Requirements
                    key="technicalRequirements"
                    editorType={editorType}
                    disable={disable}
                    type={0}
                />
            </FormProvider>
        ),
        2: (
            <FormProvider {...form}>
                <Requirements
                    key="commercialRequirements"
                    editorType={editorType}
                    disable={disable}
                    type={1}
                />
            </FormProvider>
        ),
        3: (
            <FormProvider {...form}>
                <BusinessInvitation disable={disable} />
            </FormProvider>
        ),
    }

    return (
        <Page
            header="Scope of Work Editor"
            actionButtons={actionButtons[stepId]}
            stepper={
                <Stepper activeStep={stepId}>
                    <Step key="overview">
                        <StepLabel>Overview</StepLabel>
                    </Step>
                    <Step key="technical-reqs">
                        <StepLabel>Technical Requirements</StepLabel>
                    </Step>
                    <Step key="commercial-reqs">
                        <StepLabel>Commercial Requirements</StepLabel>
                    </Step>
                    <Step key="invitation">
                        <StepLabel>Invitation</StepLabel>
                    </Step>
                </Stepper>
            }
        >
            <Box
                px={4}
                py={2}
                bgcolor="white"
                boxShadow="rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px"
                borderRadius={2}
            >
                <Box my={2}>{forms[stepId]}</Box>
            </Box>
        </Page>
    )
}

export default UpsertOpportunity
