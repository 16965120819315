import {
    ChevronRight,
    ExpandLess,
    Delete,
    Visibility,
    Edit,
    Assessment,
    ContentCopy,
} from '@mui/icons-material'
import {
    Box,
    IconButton,
    Typography,
    Divider,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
    Button,
    Stack,
    Link,
} from '@mui/material'
import { OpportunityGroup } from '../../../../../../utils/models/api-models'
import { useState } from 'react'
import { useFormContext } from 'react-hook-form'

const Row = ({
    opportunityGroup,
    openOpportunityGroup,
    openDeleteOpportunityGroup,
    openDeleteOpportunity,
    openCopySow,
}: {
    opportunityGroup: OpportunityGroup
    openOpportunityGroup: (val: number) => void
    openDeleteOpportunityGroup: (val: number) => void
    openDeleteOpportunity: (val: number) => void
    openCopySow: (val: number) => void
}) => {
    const [showOpportunities, setShowOpportunities] = useState<boolean>(false)

    const { setValue } = useFormContext()

    const opportunities = () => {
        if (opportunityGroup.opportunities.length === 0) {
            return (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                    }}
                >
                    <Typography variant="body1">
                        No Scope of Work Created
                    </Typography>
                    <Button
                        href={`/platform/scope-of-work/edit?opportunityId=${opportunityGroup.id}`}
                        variant="text"
                        color="primary"
                        sx={{
                            px: 2,
                            mt: 1,
                        }}
                    >
                        Create Scope of Work
                    </Button>
                </Box>
            )
        }

        return (
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ fontWeight: 'bold' }}>
                            Scope of Work
                        </TableCell>
                        <TableCell sx={{ fontWeight: 'bold' }}>
                            Status
                        </TableCell>
                        <TableCell sx={{ fontWeight: 'bold' }}>
                            Due Date
                        </TableCell>
                        <TableCell sx={{ fontWeight: 'bold' }}>
                            # of Responses
                        </TableCell>
                        <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                            <Button
                                href={`/platform/scope-of-work/edit?opportunityId=${opportunityGroup.id}`}
                                variant="text"
                                color="primary"
                                sx={{
                                    px: 2,
                                }}
                            >
                                Create Scope of Work
                            </Button>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {opportunityGroup.opportunities.map((op) => (
                        <TableRow>
                            <TableCell>
                                <Link
                                    href={`/platform/scope-of-work/${op.id}`}
                                    sx={{ textDecoration: 'None' }}
                                >
                                    {op.title}
                                </Link>
                            </TableCell>
                            <TableCell>
                                {op.draft
                                    ? 'In-Draft'
                                    : op.status
                                    ? 'Open'
                                    : 'Closed'}
                            </TableCell>
                            <TableCell>{op.endDate}</TableCell>
                            <TableCell>{op.responses}</TableCell>
                            <TableCell align="right">
                                <Tooltip title="Edit">
                                    <IconButton
                                        href={`/platform/scope-of-work/edit?id=${op.id}`}
                                    >
                                        <Edit sx={{ height: '20px' }} />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="View Responses">
                                    <IconButton
                                        href={`/platform/scope-of-work/${op.id}?tab=1`}
                                    >
                                        <Visibility sx={{ height: '20px' }} />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Copy SOW">
                                    <IconButton
                                        onClick={() => openCopySow(op.id)}
                                    >
                                        <ContentCopy sx={{ height: '20px' }} />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Delete">
                                    <IconButton
                                        onClick={() =>
                                            openDeleteOpportunity(op.id)
                                        }
                                    >
                                        <Delete sx={{ height: '20px' }} />
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        )
    }

    return (
        <Box component={'span'}>
            <Box
                display="flex"
                alignItems={'center'}
                px={1}
                py={1}
                justifyContent={'space-between'}
            >
                <Box
                    display="flex"
                    onClick={() => setShowOpportunities(!showOpportunities)}
                    sx={{ cursor: 'pointer' }}
                    width="100%"
                >
                    <IconButton
                        disableRipple
                        onClick={() => setShowOpportunities(!showOpportunities)}
                    >
                        {showOpportunities ? (
                            <ExpandLess
                                onClick={() =>
                                    setShowOpportunities(!showOpportunities)
                                }
                                sx={{ height: '25px', width: '25px' }}
                            />
                        ) : (
                            <ChevronRight
                                onClick={() =>
                                    setShowOpportunities(!showOpportunities)
                                }
                                sx={{ height: '25px', width: '25px' }}
                            />
                        )}
                    </IconButton>
                    <Typography
                        variant="h2"
                        fontSize={20}
                        fontWeight={300}
                        lineHeight={1.7}
                        pl={2}
                    >
                        {opportunityGroup.name}
                    </Typography>
                </Box>
                <Stack direction="row">
                    <Tooltip title="Edit">
                        <IconButton
                            sx={{
                                height: '100%',
                                align: 'right',
                            }}
                            onClick={(e: React.MouseEvent<HTMLElement>) => {
                                e.preventDefault()
                                openOpportunityGroup(opportunityGroup.id)
                                setValue('title', opportunityGroup.name)
                            }}
                        >
                            <Edit sx={{ height: '20px' }} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                        <IconButton
                            sx={{
                                height: '100%',
                                align: 'right',
                            }}
                            onClick={(e: React.MouseEvent<HTMLElement>) => {
                                openDeleteOpportunityGroup(opportunityGroup.id)
                                e.preventDefault()
                            }}
                        >
                            <Delete sx={{ height: '20px' }} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Good Faith Effort Report">
                        <IconButton
                            sx={{
                                height: '100%',
                                align: 'right',
                            }}
                            target="_blank"
                            href={`/platform/good-faith-effort-report/${opportunityGroup.id}`}
                        >
                            <Assessment sx={{ height: '20px' }} />
                        </IconButton>
                    </Tooltip>
                </Stack>
            </Box>
            <Divider />
            {showOpportunities && <Box py={4}>{opportunities()}</Box>}
        </Box>
    )
}

export default Row
