import axios from 'axios'

const PROD_URL = 'https://api.dbeworld.io/api/v1'
const DEV_URL = 'http://0.0.0.0:1337/api/v1'

interface CallApiProps {
    url: string
    type: string
    body: unknown
    auth?: boolean
    headers?: Record<string, any>
}

export const baseURL =
    process.env.NODE_ENV === 'development' ? DEV_URL : PROD_URL

const callAPI = ({
    url,
    type,
    body = {},
    auth = false,
    headers = { 'Content-Type': 'application/json' },
}: CallApiProps) => {
    let config = {
        headers: headers,
        baseURL: baseURL,
        url: url,
        method: type,
        data: body,
    }

    if (auth) {
        //@ts-ignore
        config.headers['Authorization'] = `Bearer ${
            localStorage.getItem('accessToken') || ''
        }`
    }

    //@ts-ignore
    return axios.request(config)
}

export default callAPI
