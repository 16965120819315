import RouterComponent from './utils/router/router'
import { ThemeProvider } from '@mui/material'
import theme from './utils/theme'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers'

function App() {
    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <ThemeProvider theme={theme}>
                <RouterComponent />
            </ThemeProvider>
        </LocalizationProvider>
    )
}

export default App
