import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface SideDrawerState {
    open: boolean
}

const initialState: SideDrawerState = {
    open: true,
}

export const sideDrawerSlice = createSlice({
    name: 'sideDrawer',
    initialState,
    reducers: {
        setOpen: (state, action: PayloadAction<SideDrawerState>) => {
            state.open = action.payload.open
        },
    },
})

export const { setOpen } = sideDrawerSlice.actions

export default sideDrawerSlice
