import { Alert, Button, Stack, Step, StepLabel, Stepper } from '@mui/material'
import { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
    ResponseRequirement,
    Opportunity as OpportuntiyType,
} from '../../../../../../utils/models/api-models'
import { getOpportunity } from '../../../../../../api/v1/opportunity'
import {
    getOpportunityResponses,
    upsertOpportunityResponse,
} from '../../../../../../api/v1/opportunity-response'
import { useForm, FormProvider } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { setOpen } from '../../../../../../slices/notification-slice'
import {
    CreateOpportunityResponse,
    Failure,
    UpdateOpportunityResponse,
} from '../../../../../../utils/constants/messages'
import Page from '../../../../../ui-components/page'
import Requirments from './requirements'
import Pricing from './pricing'
import Overview from './overview'
import { Requirement } from './utils'

interface FormValues {
    pricing: string | File
    technicalReqs: Requirement[]
    commercialReqs: Requirement[]
}

const UpsertResponse = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [stepId, setStepId] = useState(0)
    const [respId, setRespId] = useState(0)

    const [opportunity, setOpportunity] = useState<OpportuntiyType>()
    const [disable, setDisable] = useState(false)

    const methods = useForm<FormValues>({
        defaultValues: {
            pricing: '',
            technicalReqs: [] as Requirement[],
            commercialReqs: [] as Requirement[],
        },
    })

    const fetchOpportunity = async () => {
        setDisable(true)
        const { success, data } = await getOpportunity({
            id: id as unknown as number,
        })

        if (!success || !data) {
            navigate('/404-not-found')
            return
        }

        const technicalReqs = data?.technicalReqs || []
        const commercialReqs = data?.commercialReqs || []

        const tReqs: Requirement[] =
            technicalReqs.map((tr) => {
                return {
                    id: tr.id,
                    question: tr.value,
                    value: -1,
                    required: tr.required,
                    comment: '',
                }
            }) || []

        const cReqs: Requirement[] = commercialReqs.map((cr) => {
            return {
                id: cr.id,
                question: cr.value,
                value: -1,
                required: cr.required,
                comment: '',
            }
        })

        methods.reset({
            pricing: '',
            technicalReqs: tReqs,
            commercialReqs: cReqs,
        })

        if (!data.status) {
            setDisable(true)
        }

        setOpportunity(data)
    }

    const getResponse = async () => {
        setDisable(true)
        if (!opportunity) {
            setDisable(false)
            return
        }
        const { success, data } = await getOpportunityResponses({
            opportunityId: opportunity?.id ? +opportunity.id : undefined,
        })

        if (success && data && data.count > 0) {
            const info = data?.data?.[0]
            if (!info) {
                setDisable(false)
                return
            }

            setRespId(info.id)

            const technicalReqs = info.technicalReqs || []
            const commercialReqs = info.commercialReqs || []
            const tReqsHash: Record<number, ResponseRequirement> = {}
            const cReqsHash: Record<number, ResponseRequirement> = {}

            technicalReqs.forEach((r) => {
                tReqsHash[r.id] = r
            })

            commercialReqs.forEach((r) => {
                cReqsHash[r.id] = r
            })

            const opTReqs = methods.getValues('technicalReqs')
            const opCReqs = methods.getValues('commercialReqs')

            const rspTReqs = opTReqs.map((r) => {
                const req = tReqsHash[r.id]
                if (req) {
                    return {
                        id: r.id,
                        question: req.question,
                        value: req.value,
                        comment: req.comment,
                        required: req.required,
                    }
                } else {
                    return {
                        id: r.id,
                        question: r.question,
                        value: r.value,
                        comment: '',
                        required: r.required,
                    }
                }
            })

            const rspCReqs = opCReqs.map((r) => {
                const req = cReqsHash[r.id]
                if (req) {
                    return {
                        id: r.id,
                        question: req.question,
                        value: req.value,
                        comment: req.comment,
                        required: req.required,
                    }
                } else {
                    return {
                        id: r.id,
                        question: r.question,
                        value: r.value,
                        comment: '',
                        required: r.required,
                    }
                }
            })

            methods.reset({
                pricing: info.pricing as string,
                technicalReqs: rspTReqs,
                commercialReqs: rspCReqs,
            })
        }
        setDisable(false)
    }

    const goNextPage = async ({ values }: { values: String[] }) => {
        // @ts-ignore
        if (!(await methods.trigger(values))) {
            return
        }
        setStepId((id) => id + 1)
    }

    const goBackPage = async () => {
        setStepId((id) => id - 1)
    }

    useEffect(() => {
        fetchOpportunity()
    }, [id])

    useEffect(() => {
        fetchOpportunity()
    }, [])

    const upsertResponse = async (draft: boolean) => {
        setDisable(true)
        const { pricing, technicalReqs, commercialReqs } = methods.getValues()
        if (opportunity?.pricing === true && !pricing) {
            return
        }

        const params = {
            technicalReqs,
            commercialReqs,
            pricing,
            isDraft: draft,
        }

        let apiSuccess = false

        if (respId !== 0) {
            const { success } = await upsertOpportunityResponse({
                ...params,
                id: respId,
            })
            apiSuccess = success
        } else {
            const { success } = await upsertOpportunityResponse({
                ...params,
                opportunity: opportunity?.id,
            })
            apiSuccess = success
        }

        dispatch(
            setOpen({
                open: apiSuccess,
                message: apiSuccess
                    ? respId
                        ? UpdateOpportunityResponse
                        : CreateOpportunityResponse
                    : Failure,
            })
        )
        setDisable(false)

        if (apiSuccess) {
            navigate('/platform/opportunity')
        }
        return
    }

    useEffect(() => {
        if (opportunity) {
            getResponse()
            if (!opportunity.status) {
                setStepId(3)
            }
        }
    }, [opportunity])

    const actionButtons: Record<number, JSX.Element[]> = {
        0: [
            <Button
                color="primary"
                variant="text"
                onClick={() =>
                    goNextPage({
                        values: ['technicalReqs'],
                    })
                }
            >
                Next
            </Button>,
        ],
        1: [
            <Button color="secondary" onClick={() => goBackPage()}>
                Back
            </Button>,
            <Button
                color="primary"
                onClick={() =>
                    goNextPage({
                        values: ['commercialReqs'],
                    })
                }
            >
                Next
            </Button>,
        ],
        2: [
            <Button color="secondary" onClick={() => goBackPage()}>
                Back
            </Button>,
            <Button
                color="primary"
                onClick={() =>
                    goNextPage({
                        values: ['pricing'],
                    })
                }
            >
                Next
            </Button>,
        ],
        3: [
            <Button color="secondary" onClick={() => goBackPage()}>
                Back
            </Button>,
            <Button
                disabled={disable || !opportunity?.status}
                color="primary"
                onClick={async () => await upsertResponse(true)}
                sx={{ width: 100 }}
            >
                Save Draft
            </Button>,
            <Button
                disabled={disable || !opportunity?.status}
                color="primary"
                onClick={async () => await upsertResponse(false)}
            >
                Publish
            </Button>,
        ],
    }

    const forms: Record<number, JSX.Element> = {
        0: (
            <FormProvider {...methods}>
                <Requirments
                    key="technicalRequirements"
                    disable={disable}
                    type={0}
                    opportunity={opportunity!}
                />
            </FormProvider>
        ),
        1: (
            <FormProvider {...methods}>
                <Requirments
                    key="commercialRequirements"
                    disable={disable}
                    type={1}
                    opportunity={opportunity!}
                />
            </FormProvider>
        ),
        2: (
            <FormProvider {...methods}>
                <Pricing
                    disable={disable}
                    required={opportunity?.pricing === true}
                    id={respId}
                    opportunity={opportunity!}
                />
            </FormProvider>
        ),
        3: (
            <FormProvider {...methods}>
                <Overview
                    opportunity={opportunity!}
                    respId={respId > 0 ? respId : null}
                />
            </FormProvider>
        ),
    }

    return (
        <Page
            key={'my-response-for-opportunity-' + id}
            header={opportunity?.title || 'Response'}
            actionButtons={!opportunity?.status ? [] : actionButtons[stepId]}
            stepper={
                <Stack spacing={2}>
                    <Stepper activeStep={stepId}>
                        <Step key="technical-reqs">
                            <StepLabel>Technical Requirements</StepLabel>
                        </Step>
                        <Step key="commercial-reqs">
                            <StepLabel>Commercial Requirements</StepLabel>
                        </Step>
                        <Step key="pricing">
                            <StepLabel>Pricing</StepLabel>
                        </Step>
                        <Step key="review">
                            <StepLabel>Review</StepLabel>
                        </Step>
                    </Stepper>
                    {opportunity?.status === false && (
                        <Alert severity="error">
                            This opportunity has closed. Any changes made will
                            not be saved.
                        </Alert>
                    )}
                </Stack>
            }
        >
            {forms[stepId]}
        </Page>
    )
}

export default UpsertResponse
