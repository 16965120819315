import {
    Location,
    Response,
    StatusResponse,
} from '../../../utils/models/api-models'
import callAPI from '../api-base'

type LocationResponse = Response<Location>

const LocationAPI = () => {
    const upsertLocation = ({
        id,
        city,
        state,
    }: {
        city: string
        state: string
        id?: number
    }): Promise<LocationResponse> => {
        return callAPI({
            url: 'location/',
            type: 'post',
            body: {
                id: id,
                city: city.toUpperCase(),
                state: state.toUpperCase(),
            },
            auth: true,
        })
            .then((res: any) => {
                return { success: res.data.success, data: res.data.data }
            })
            .catch((err: any) => {
                return {
                    success: false,
                    data: null,
                }
            })
    }

    const deleteLocation = ({
        id,
    }: {
        id: number
    }): Promise<StatusResponse> => {
        return callAPI({
            url: 'location/',
            type: 'delete',
            body: { id: id },
            auth: true,
        })
            .then((res: any) => {
                return { success: res.data.success }
            })
            .catch((err: any) => {
                return { success: false }
            })
    }

    return { upsertLocation, deleteLocation }
}

export const { upsertLocation, deleteLocation } = LocationAPI()
