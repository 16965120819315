import { Box, Link } from '@mui/material'
import { Check, Warning } from '@mui/icons-material'
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid'

const columns: GridColDef[] = [
    {
        field: 'isVerified',
        headerName: 'Verified',
        editable: false,
        sortable: false,
        flex: 0.8,
        renderCell: (params: GridRenderCellParams) => (
            <Box display="flex" justifyContent="center">
                {params.row.isVerified ? (
                    <Check color="success" />
                ) : (
                    <Warning color="warning" />
                )}
            </Box>
        ),
    },
    {
        field: 'name',
        headerName: 'Name',
        editable: false,
        sortable: false,
        flex: 1.5,
        renderCell: (params: GridRenderCellParams) => (
            <Link
                href={'/platform/business/' + params.row.id.toString()}
                target="_blank"
            >
                {params.row.name}
            </Link>
        ),
    },
    {
        field: 'contactName',
        headerName: 'Contact',
        editable: false,
        sortable: false,
        flex: 1.5,
        renderCell: (params: GridRenderCellParams) => (
            <Box>{params.row.contacts?.[0].name}</Box>
        ),
    },
    {
        field: 'email',
        headerName: 'Email',
        editable: false,
        sortable: false,
        flex: 2,
        renderCell: (params: GridRenderCellParams) => (
            <Box>{params.row.contacts?.[0].email}</Box>
        ),
    },
]

interface TableProps {
    rows: any[]
    count: number
    setPage: (page: number) => void
    disable: boolean
    page: number
}

const Table = ({ rows, count, setPage, disable, page }: TableProps) => (
    <Box
        sx={{
            height: '100vh',
            width: '100%',
        }}
    >
        <DataGrid
            rows={rows}
            columns={columns}
            pageSize={20}
            disableColumnFilter
            disableColumnMenu
            disableSelectionOnClick
            disableColumnSelector
            onPageChange={(page, _) => setPage(page + 1)}
            rowCount={count}
            page={page - 1}
            paginationMode="server"
            getRowHeight={() => 'auto'}
            loading={disable}
            sx={{
                border: 0,
                '& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cell:focus-within':
                    {
                        outline: 'none',
                    },
                '& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus':
                    {
                        outline: 'none',
                    },
                '.MuiDataGrid-columnSeparator': {
                    display: 'none',
                },
                '& .MuiDataGrid-columnHeaderTitle': {
                    fontWeight: 'bold',
                    color: '#262d50',
                },
                '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
                    py: '8px',
                },
                '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
                    py: '15px',
                },
                '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
                    py: '22px',
                },
            }}
        />
    </Box>
)

export default Table
