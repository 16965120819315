import { Box, Stack, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import Verfication from './business-cells/verification'
import AboutUs from './business-cells/about-us'
import ContactInfo from './business-cells/contact-info'
import CompanyInfo from './business-cells/company-info'
import Services from './business-cells/services'
import { getBusinessById } from '../../../api/v1/business'

import {
    Business as BusinessType,
    Contact,
} from '../../../utils/models/api-models'
import Notes from './business-cells/notes'
import { useSelector } from 'react-redux'
import { getUserInfo } from '../../../selectors/login-selector'
import BusinessExperiences from './business-cells/business-experience'

const Business = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const [business, setBusiness] = useState<BusinessType>()
    const { userType } = useSelector(getUserInfo)

    const getBusiness = async () => {
        const { success, data } = await getBusinessById({
            id: id as unknown as number,
            authenticated: true,
        })
        if (!success || !data) {
            navigate('/404-not-found')
            return
        }
        setBusiness(data || [])
    }

    useEffect(() => {
        getBusiness()
    }, [id])

    // Variables
    const businessName = business?.name || null
    const website = business?.website || null
    const profile = business?.profile || null
    const certificates = business?.certificates || []
    const headcount = business?.headcount || ''
    const locations = business?.locations || []
    const naics = business?.naics || []
    const services = business?.services || []
    const contacts: Contact[] = business?.contacts || []
    const experiences = business?.experiences || []

    return (
        <Box>
            <Typography
                variant="h4"
                color="black"
                fontFamily='"Inter", sans-serif'
                fontWeight="bold"
                m={0}
                width={800}
            >
                {businessName}
            </Typography>
            <Box mt={2}>
                <Verfication
                    verified={business?.isVerified || false}
                    id={business?.['id'] as unknown as number}
                />
            </Box>
            <Box mt={4} display="flex">
                <Box mr={3} width="100%">
                    <Box
                        px={3}
                        py={2}
                        bgcolor="white"
                        boxShadow="rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px"
                        borderRadius={2}
                    >
                        <AboutUs profile={profile} />
                    </Box>
                    <Box
                        mt={2}
                        px={3}
                        py={2}
                        bgcolor="white"
                        boxShadow="rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px"
                        borderRadius={2}
                    >
                        <Services services={services} />
                    </Box>
                    <Box
                        mt={2}
                        px={3}
                        py={2}
                        bgcolor="white"
                        boxShadow="rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px"
                        borderRadius={2}
                    >
                        <BusinessExperiences experiences={experiences} />
                    </Box>
                    {userType !== 'BUSINESS' && (
                        <Box
                            mt={2}
                            px={3}
                            py={2}
                            bgcolor="white"
                            boxShadow="rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px"
                            borderRadius={2}
                        >
                            <Notes businessId={id ? +id : -1} />
                        </Box>
                    )}
                </Box>
                <Stack spacing={2} width="55%">
                    <ContactInfo contacts={contacts} />
                    <CompanyInfo
                        website={website ? website : ''}
                        certificates={certificates}
                        headcount={headcount}
                        locations={locations}
                        naics={naics}
                    />
                </Stack>
            </Box>
        </Box>
    )
}

export default Business
