import {
    Box,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    Stack,
    Typography,
} from '@mui/material'
import WhiteContainer from '../../../../../ui-components/white-container'
import { Opportunity } from '../../../../../../utils/models/api-models'
import { useFormContext } from 'react-hook-form'
import { Requirement } from './utils'

interface OverviewProps {
    opportunity: Opportunity
    respId: number | null
}

const Overview = ({ opportunity, respId }: OverviewProps) => {
    const { getValues } = useFormContext()

    const result = getValues()

    const displayRequirements = (reqs: Requirement[]) => (
        <Stack spacing={3}>
            {reqs.map((i: Requirement, index: number) => (
                <Box component={'span'}>
                    <Typography variant="body1">
                        {index + 1}. {i.question}
                    </Typography>
                    {!i.required && (
                        <Typography
                            variant="body1"
                            fontSize={'0.875rem'}
                            color="rgba(0, 0, 0, 0.6)"
                            lineHeight={1.43}
                            fontStyle={'italic'}
                        >
                            Optional Compliance
                        </Typography>
                    )}
                    <Box display="flex" mt={1}>
                        <FormControl disabled={true}>
                            <RadioGroup
                                name="controlled-radio-buttons-group"
                                value={i.value}
                            >
                                <FormControlLabel
                                    value={0}
                                    control={
                                        <Radio
                                            disabled={true}
                                            sx={{
                                                '&.Mui-checked': {
                                                    color: '#262d50',
                                                },
                                            }}
                                        />
                                    }
                                    label="Comply"
                                />
                                <FormControlLabel
                                    value={1}
                                    control={
                                        <Radio
                                            disabled={true}
                                            sx={{
                                                '&.Mui-checked': {
                                                    color: '#262d50',
                                                },
                                            }}
                                        />
                                    }
                                    label="Partial"
                                />
                                <FormControlLabel
                                    value={2}
                                    control={
                                        <Radio
                                            disabled={true}
                                            sx={{
                                                '&.Mui-checked': {
                                                    color: '#262d50',
                                                },
                                            }}
                                        />
                                    }
                                    label="Non-Compliant"
                                />
                            </RadioGroup>
                        </FormControl>
                        <Box component={'span'} ml={8} maxWidth={'75%'}>
                            <Typography variant="body1">Notes:</Typography>
                            <Typography variant="body1">
                                {i.comment.length > 0
                                    ? i.comment
                                    : 'No additional notes'}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            ))}
        </Stack>
    )

    return (
        <WhiteContainer>
            <Stack spacing={4}>
                <Box component={'span'}>
                    <Typography
                        variant="h2"
                        fontSize={16}
                        fontWeight={300}
                        lineHeight={1.7}
                    >
                        Name
                    </Typography>
                    <Typography>{opportunity.title}</Typography>
                </Box>
                <Box component={'span'}>
                    <Typography
                        variant="h2"
                        fontSize={16}
                        fontWeight={300}
                        lineHeight={1.7}
                    >
                        Description
                    </Typography>
                    <Typography>{opportunity.description}</Typography>
                </Box>
                <Box component={'span'} display="flex">
                    <Box component={'span'} mr={2}>
                        <Typography
                            variant="h2"
                            fontSize={16}
                            fontWeight={300}
                            lineHeight={1.7}
                        >
                            Status
                        </Typography>
                        <Typography>
                            {opportunity.status ? 'Open' : 'Closed'}
                        </Typography>
                    </Box>
                    <Box component={'span'}>
                        <Typography
                            variant="h2"
                            fontSize={16}
                            fontWeight={300}
                            lineHeight={1.7}
                        >
                            Due Date
                        </Typography>
                        <Typography>{opportunity.endDate}</Typography>
                    </Box>
                </Box>
                <Box component={'span'}>
                    <Typography
                        variant="h2"
                        fontSize={16}
                        fontWeight={300}
                        lineHeight={1.7}
                    >
                        Technical Requirements
                    </Typography>
                    {opportunity.technicalReqs?.length === 0 ? (
                        <Typography variant="body1">
                            No technical requirements
                        </Typography>
                    ) : (
                        displayRequirements(result.technicalReqs)
                    )}
                </Box>
                <Box component={'span'}>
                    <Typography
                        variant="h2"
                        fontSize={16}
                        fontWeight={300}
                        lineHeight={1.7}
                    >
                        Commercial Requirements
                    </Typography>
                    {opportunity.technicalReqs?.length === 0 ? (
                        <Typography variant="body1">
                            No commercial requirements
                        </Typography>
                    ) : (
                        displayRequirements(result.commercialReqs)
                    )}
                </Box>
                <Box component={'span'}>
                    <Typography
                        variant="h2"
                        fontSize={16}
                        fontWeight={300}
                        lineHeight={1.7}
                    >
                        Pricing
                    </Typography>
                    <Typography>
                        {!opportunity.pricing
                            ? 'Not required'
                            : respId
                            ? 'Uploaded successfully'
                            : 'No response was submitted'}
                    </Typography>
                </Box>
            </Stack>
        </WhiteContainer>
    )
}

export default Overview
