import { Box, Stack, Typography } from '@mui/material'
import { Experience } from '../../../../utils/models/api-models'

const BusinessExperiences = ({
    experiences,
}: {
    experiences: Experience[]
}) => (
    <>
        <Typography variant="caption">Past Experiences</Typography>
        {experiences.length <= 0 ? (
            <Box>
                <Typography>
                    The business has not updated this information yet.
                </Typography>
            </Box>
        ) : (
            <Stack spacing={2} pt={1}>
                {experiences.map((e) => (
                    <Box>
                        <Typography variant="body2">
                            {e.governmentAgency} ─ {e.startDate} to{' '}
                            {e.active ? 'Present' : e.endDate}
                        </Typography>
                        <Typography variant="caption">
                            Contracted through{' '}
                            {e.workedDirectlyWithGovernment
                                ? e.governmentAgency
                                : e.primeContractor}
                        </Typography>
                        <Typography>{e.service}</Typography>
                    </Box>
                ))}
            </Stack>
        )}
    </>
)

// id: number
// governmentAgency: string
// primeContractor: string
// workedDirectlyWithGovernment: boolean
// service: string
// startDate: string
// endDate: string | null
// active: boolean

export default BusinessExperiences
