import { OutlinedInputProps, TextField, TextFieldProps } from '@mui/material'

const WorldTextField = ({ ...props }: TextFieldProps) => {
    const { sx, ...properties } = props
    return (
        <TextField
            variant="filled"
            InputProps={
                { disableUnderline: true } as Partial<OutlinedInputProps>
            }
            focused
            sx={{
                '& label.Mui-focused': {
                    color: '#262d50',
                    '&.Mui-error': {
                        color: 'red',
                    },
                },
                '& .MuiFilledInput-root': {
                    border: '1px solid #e2e2e1',
                    overflow: 'hidden',
                    borderRadius: 1,
                    backgroundColor: 'transparent',
                    '&:hover': {
                        backgroundColor: 'transparent',
                    },
                    '&.Mui-focused': {
                        backgroundColor: 'transparent',
                    },
                    '&.Mui-error': {
                        border: '1px solid red',
                    },
                },
                '& .MuiOutlinedInput-root': {
                    border: '1px solid #e2e2e1',
                    overflow: 'hidden',
                    borderRadius: 1,
                    backgroundColor: 'transparent',
                    '&:hover': {
                        backgroundColor: 'transparent',
                    },
                    '&.Mui-focused': {
                        backgroundColor: 'transparent',
                        border: 0,
                    },
                    '&.Mui-error': {
                        border: '1px solid red',
                    },
                },
                '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                    border: '1px solid #e2e2e1 !important',
                    overflow: 'hidden',
                    borderRadius: 1,
                },
                ...sx,
            }}
            {...properties}
        />
    )
}

export default WorldTextField
