import {
    Service,
    Response,
    StatusResponse,
} from '../../../utils/models/api-models'
import callAPI from '../api-base'

type ServiceResponse = Response<Service>

const ServiceAPI = () => {
    const upsertService = ({
        id,
        title,
        description = '',
    }: {
        title: string
        description?: string
        id?: number
    }): Promise<ServiceResponse> => {
        return callAPI({
            url: 'service/',
            type: 'post',
            body: {
                id: id,
                title: title,
                description: description,
            },
            auth: true,
        })
            .then((res: any) => {
                return { success: res.data.success, data: res.data.data }
            })
            .catch((err: any) => {
                return {
                    success: false,
                    data: null,
                }
            })
    }

    const deleteService = ({ id }: { id: number }): Promise<StatusResponse> => {
        return callAPI({
            url: 'service/',
            type: 'delete',
            body: { id: id },
            auth: true,
        })
            .then((res: any) => {
                return { success: res.data.success }
            })
            .catch((err: any) => {
                return { success: false }
            })
    }

    return { upsertService, deleteService }
}

export const { upsertService, deleteService } = ServiceAPI()
