import {
    Button,
    Step,
    StepLabel,
    Stepper,
    Typography,
    StepContent,
    Box,
    Link,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { useForm, FormProvider } from 'react-hook-form'

import Type from './type'
import AccountInformation from './account-information'
import BusinessSearch from './business-search'
import BusinessResults, { BusinessResultType } from './business-results'
import signUpAPI from '../../../api/v1/user/sign-up'

import WorldSnackbar from '../../ui-components/snackbar'
import { AccountType } from '../../../utils/constants/enums'
import { Failure } from '../../../utils/constants/messages'

const messages = {
    business: {
        success:
            'We have sent an activation email to the email provided. Please also check span and junk email.',
        failure: Failure,
    },
    nonBusiness: {
        success:
            'We have recieved your request. One of our agents will contact you shortly.',
        failure: Failure,
    },
}

const RegistrationStepper = () => {
    const [searchParams, _] = useSearchParams()

    const [activeState, setActiveState] = useState(0)
    const [userType, setUserType] = useState<'business' | 'nonBusiness'>(
        'nonBusiness'
    )
    const [messageType, setMessageType] = useState<'success' | 'failure'>(
        'success'
    )
    const [disabled, setDisabled] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const [searchedBusiness, setSearchedBusiness] =
        useState<BusinessResultType>({
            data: [],
            totalPages: 0,
            businessEmail: '',
            businessName: '',
        })

    const methods = useForm({
        defaultValues: {
            email: '',
            password: '',
            type: AccountType.BUSINESS,
            firstName: '',
            lastName: '',
        },
    })

    const { getValues, setError, clearErrors, trigger, setValue } = methods

    const getSearchParams = () => {
        const accountEmail = searchParams.get('accountEmail')
        const accountType = searchParams.get(
            'accountType'
        ) as unknown as AccountType

        if (!accountEmail || !accountType) {
            return
        }

        setValue('type', accountType)
        setValue('email', accountEmail)
        setActiveState(3)
    }

    useEffect(() => {
        getSearchParams()
    }, [searchParams])

    const handleNext = async () => {
        clearErrors()
        if (activeState === 0) {
            const { type } = getValues()
            if (!type) {
                setError('type', {})
                return
            }
            if (type === AccountType.BUSINESS) {
                setActiveState(1)
            } else {
                setActiveState(3)
            }
        } else if (activeState === 1) {
            setActiveState(2)
        } else if (activeState === 2) {
            const { email } = getValues()
            if (!email) return
            setActiveState(3)
        } else if (activeState === 3) {
            setDisabled(true)
            if (await trigger()) {
                const { email, type, password, firstName, lastName } =
                    getValues()
                const success = await signUpAPI({
                    email: email as string,
                    password,
                    firstName,
                    lastName,
                    type,
                })
                setUserType(type === 'BUSINESS' ? 'business' : 'nonBusiness')
                setMessageType(success ? 'success' : 'failure')
                setSubmitted(true)
                setActiveState(4)
            }
            setDisabled(false)
        }
    }

    const handleBack = () => {
        clearErrors()
        if (activeState === 1) {
            setActiveState(0)
        } else if (activeState === 2) {
            setActiveState(1)
        } else if (activeState === 3) {
            const { type } = getValues()
            if (type === 'BUSINESS') {
                setActiveState(2)
            } else {
                setActiveState(0)
            }
        }
    }

    const steps = [
        {
            label: 'Account Type',
            subtitle: null,
            description: 'Select the type of account you will be creating.',
            body: () => (
                <>
                    <Box mt={3}>
                        <FormProvider {...methods}>
                            <Type />
                        </FormProvider>
                        <Button
                            variant="text"
                            color="primary"
                            sx={{
                                mt: 3,
                                px: 4,
                            }}
                            onClick={handleNext}
                        >
                            Next
                        </Button>
                    </Box>
                </>
            ),
        },
        {
            label: 'Search For Your Business',
            subtitle: 'Only required for business accounts',
            description: `
                Start by searching for your business by either inputing your business email or business name (or part of it). 
                You can also input both to help you narrow the search. 
                If you don't see your business or don't have access to the business email, contact us at contact@dbeworld.io.`,
            body: () => (
                <Box>
                    <BusinessSearch
                        handleNext={handleNext}
                        setSearchedBusiness={setSearchedBusiness}
                    />
                    <Box mt={2}>
                        <Button
                            variant="text"
                            color="secondary"
                            sx={{
                                px: 2,
                            }}
                            onClick={handleBack}
                        >
                            Back
                        </Button>
                    </Box>
                </Box>
            ),
        },
        {
            label: 'Select Your Business',
            subtitle: 'Only required for business accounts',
            description: `
                Pick your business from the results. 
                If you don't see your business or don't have access to the business email, contact us at contact@dbeworld.io.`,
            body: () => (
                <Box>
                    <FormProvider {...methods}>
                        <BusinessResults
                            result={searchedBusiness}
                            handleNext={handleNext}
                        />
                    </FormProvider>
                    <Box mt={3}>
                        <Button
                            variant="text"
                            color="secondary"
                            sx={{
                                px: 2,
                            }}
                            onClick={handleBack}
                        >
                            Back
                        </Button>
                    </Box>
                </Box>
            ),
        },
        {
            label: 'Account Information',
            subtitle: null,
            description:
                'Fill and confirm your information. By creating an account, you are agreeing to our terms and privacy policy.',
            body: () => (
                <Box mt={3}>
                    <FormProvider {...methods}>
                        <AccountInformation />
                    </FormProvider>
                    <Box mt={2} display="grid" fontSize={12}>
                        <Link
                            target="_blank"
                            href="https://app.termly.io/document/terms-of-use-for-website/48203150-6e12-4c6d-aadc-fa3a8d4e3657"
                            sx={{
                                color: 'black',
                            }}
                        >
                            Terms and Conditions
                        </Link>
                        <Link
                            target="_blank"
                            href="https://app.termly.io/document/privacy-policy/3a6a9bee-2fb7-41a7-b86d-a2ebc57d1798"
                            sx={{
                                color: 'black',
                            }}
                        >
                            Privacy Policy
                        </Link>
                    </Box>
                    <Box>
                        <Button
                            variant="text"
                            color="primary"
                            sx={{
                                mt: 3,
                                px: 4,
                            }}
                            onClick={handleNext}
                            disabled={disabled}
                        >
                            Create Account
                        </Button>
                    </Box>
                    <Box>
                        <Button
                            variant="text"
                            color="secondary"
                            sx={{
                                mt: 2,
                                px: 2,
                            }}
                            onClick={handleBack}
                            disabled={disabled}
                        >
                            Back
                        </Button>
                    </Box>
                </Box>
            ),
        },
    ]

    return (
        <>
            <Stepper activeStep={activeState} orientation="vertical">
                {steps.map((item) => {
                    return (
                        <Step key={item.label}>
                            <StepLabel
                                optional={
                                    item.subtitle && (
                                        <Typography variant="caption">
                                            {item.subtitle}
                                        </Typography>
                                    )
                                }
                            >
                                {item.label}
                            </StepLabel>
                            <StepContent sx={{ textAlign: 'left' }}>
                                <Box maxWidth={400}>
                                    <Typography
                                        variant="caption"
                                        sx={{ wordBreak: 'break-word' }}
                                    >
                                        {item.description}
                                    </Typography>
                                </Box>
                                <Box maxWidth={400}>{item.body()}</Box>
                            </StepContent>
                        </Step>
                    )
                })}
            </Stepper>
            <WorldSnackbar
                open={submitted}
                setOpen={setSubmitted}
                message={<Box>{messages[userType][messageType]}</Box>}
            />
        </>
    )
}

export default RegistrationStepper
