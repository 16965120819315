import callAPI from '../api-base'

import { StatusResponse } from '../../../utils/models/api-models'

const resendActivation = (
    email: string
): StatusResponse | Promise<StatusResponse> => {
    return callAPI({
        url: 'auth/resend-verification/',
        type: 'post',
        body: {
            email: email,
        },
    })
        .then((res: any) => {
            const { success } = res.data
            return { success: success }
        })
        .catch((err: any) => {
            return { success: false }
        })
}

export default resendActivation
