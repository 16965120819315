import { Box, Stack, Typography } from '@mui/material'
import { DesktopDatePicker } from '@mui/x-date-pickers'
import moment from 'moment'
import { useState, useEffect } from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import { getOpportunityGroupsFilter } from '../../../../../../api/v1/opportunity-group'
import WorldSwitch from '../../../../../ui-components/switch'
import { Option } from '../../../../../../utils/models/api-models'
import WorldTextField from '../../../../../ui-components/text-field'
import WorldSearchSingleSelect from '../../../../../ui-components/search-single-select'

interface OverviewProps {
    disable: boolean
    editorType: 0 | 1
}

const Overview = ({ disable, editorType }: OverviewProps) => {
    const { control, getValues } = useFormContext()

    const [opGroups, setOpGroups] = useState<Option[]>([])

    const getOpGroups = async () => {
        const { data, success } = await getOpportunityGroupsFilter()
        if (success) {
            setOpGroups(data || [])
            return
        }
    }

    useEffect(() => {
        getOpGroups()
    }, [])

    const opGroupId = getValues('opGroup') === -1 ? null : getValues('opGroup')
    const selected =
        opGroups.length > 0
            ? opGroups.filter((i) => i.value === opGroupId)[0]
            : { value: opGroupId, label: '' }

    return (
        <Box display="flex" justifyContent={'center'}>
            <Stack spacing={3}>
                <Box display="flex" justifyContent="left">
                    <Typography width={200}>Name</Typography>
                    <Controller
                        name={'name' as const}
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <WorldTextField
                                placeholder="DBE Scope of Work"
                                value={field.value}
                                variant="outlined"
                                disabled={disable}
                                error={Boolean(error)}
                                helperText={error?.message || ''}
                                onChange={(e) => field.onChange(e.target.value)}
                                sx={{
                                    width: 600,
                                }}
                            />
                        )}
                        rules={{
                            required: {
                                value: true,
                                message: 'Name is required',
                            },
                            maxLength: {
                                value: 200,
                                message: 'Name must be 200 characters or less',
                            },
                            pattern: {
                                value: /^[^\s]+(?:$|.*[^\s]+$)/,
                                message:
                                    'Name can not start or end with a space',
                            },
                        }}
                    />
                </Box>

                <Box display="flex" justifyContent="left">
                    <Typography width={200}>Description</Typography>
                    <Controller
                        name={'description' as const}
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <WorldTextField
                                placeholder="This scope of work is about..."
                                value={field.value}
                                variant="outlined"
                                disabled={disable}
                                minRows={5}
                                maxRows={5}
                                multiline
                                error={Boolean(error)}
                                helperText={error?.message || ''}
                                onChange={(e) => field.onChange(e.target.value)}
                                sx={{
                                    width: 600,
                                }}
                            />
                        )}
                        rules={{
                            required: {
                                value: true,
                                message: 'Description is required',
                            },
                            maxLength: {
                                value: 5000,
                                message:
                                    'Description must be 5000 characters or less',
                            },
                        }}
                    />
                </Box>

                <Box display="flex" justifyContent="left">
                    <Typography width={200}>Opportunity Group</Typography>
                    <Controller
                        name={'opGroup' as const}
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <WorldSearchSingleSelect
                                placeholder="Select opportunity group"
                                options={opGroups}
                                onChange={(selected) => {
                                    field.onChange(selected?.value)
                                }}
                                disable={disable}
                                error={error ? Boolean(error) : false}
                                errorMessage={error?.message || ''}
                                value={
                                    opGroups.filter(
                                        (i) => i.value === field.value
                                    )[0] || selected
                                }
                                variant="outlined"
                                sx={{ width: 600 }}
                            />
                        )}
                        rules={{
                            required: {
                                value: true,
                                message: 'Opportunity group is required',
                            },
                            validate: () => {
                                const opGroup = getValues('opGroup')
                                if (opGroup === -1) {
                                    return 'Opportunity group is required'
                                }
                            },
                        }}
                    />
                </Box>

                <Box display="flex" justifyContent="left">
                    <Typography width={200}>End Date</Typography>
                    <Controller
                        name={'endDate' as const}
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <DesktopDatePicker
                                inputFormat="YYYY-MM-DD"
                                value={field.value}
                                disabled={disable}
                                disablePast={true}
                                onChange={(e: any) => {
                                    field.onChange(
                                        e ? e.format('YYYY-MM-DD') : ''
                                    )
                                }}
                                disableHighlightToday
                                minDate={moment().add(1, 'days')}
                                renderInput={(params) => (
                                    <WorldTextField
                                        label="End Date"
                                        variant="outlined"
                                        sx={{ width: 600 }}
                                        disabled={disable}
                                        {...params}
                                        error={Boolean(error)}
                                        helperText={error?.message || ''}
                                    />
                                )}
                            />
                        )}
                        rules={{
                            required: {
                                value: true,
                                message: 'End date is required',
                            },
                            validate: () => {
                                const date = moment(
                                    getValues('endDate'),
                                    'YYYY-MM-DD'
                                )

                                if (!date.isValid()) {
                                    return 'End date must be valid'
                                }
                                if (moment().add(1, 'days') > date) {
                                    return 'End date must be at least 1 day from today'
                                }
                            },
                        }}
                    />
                </Box>

                {editorType === 1 && (
                    <Box display="flex" justifyContent="left">
                        <Typography width={200}>State</Typography>
                        <Controller
                            name={'open' as const}
                            control={control}
                            render={({ field }) => (
                                <WorldSwitch
                                    label={field.value ? 'Open' : 'Closed'}
                                    switchProps={{
                                        onChange: (e) =>
                                            field.onChange(e.target.checked),
                                        checked: field.value,
                                        disabled: disable,
                                        required: true,
                                    }}
                                />
                            )}
                            rules={{
                                required: {
                                    value: true,
                                    message: 'Pricing is required',
                                },
                            }}
                        />
                    </Box>
                )}

                <Box display="flex" justifyContent="left">
                    <Typography width={200}>Include Pricing</Typography>
                    <Controller
                        name={'pricing' as const}
                        control={control}
                        render={({ field }) => (
                            <WorldSwitch
                                label={field.value ? 'Yes' : 'No'}
                                switchProps={{
                                    onChange: (e) =>
                                        field.onChange(e.target.checked),
                                    checked: field.value,
                                    disabled: disable,
                                    required: true,
                                }}
                            />
                        )}
                        rules={{
                            required: {
                                value: true,
                                message: 'Pricing is required',
                            },
                        }}
                    />
                </Box>
            </Stack>
        </Box>
    )
}

export default Overview
