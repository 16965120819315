import { Box, Typography, Stack } from '@mui/material'

interface PageProps {
    header: string
    headerSubtitle?: string
    actionButtons?: JSX.Element[]
    children: string | JSX.Element | JSX.Element[]
    stepper?: JSX.Element
}

const Page = ({
    header,
    headerSubtitle,
    actionButtons,
    stepper,
    children,
}: PageProps) => {
    return (
        <Box>
            <Box display="flex" justifyContent="space-between">
                <Box component={'span'}>
                    {headerSubtitle && (
                        <Typography variant="body2" width="100%">
                            {headerSubtitle}
                        </Typography>
                    )}
                    <Typography
                        variant="h1"
                        fontFamily='"Inter", sans-serif'
                        fontWeight="bold"
                        width="100%"
                        fontSize={34}
                    >
                        {header}
                    </Typography>
                </Box>
                <Stack direction="row" spacing={2} alignItems={'center'}>
                    {actionButtons && actionButtons?.map((b) => b)}
                </Stack>
            </Box>
            <Box my={4}>{stepper}</Box>
            {children}
        </Box>
    )
}

export default Page
