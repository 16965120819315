import callAPI from '../api-base'

import { StatusResponse } from '../../../utils/models/api-models'

interface ActivateAccountProps {
    uuid: string
    token: string
    email?: string
    businessName?: string
    contactName?: string
    website?: string
    phoneNumber?: string
    profile?: string
}

const activateAccount = ({
    uuid,
    token,
    email,
    businessName,
    contactName,
    website,
    phoneNumber,
    profile,
}: ActivateAccountProps): StatusResponse | Promise<StatusResponse> => {
    return callAPI({
        url: `auth/activate/`,
        type: 'post',
        body: {
            uuid: uuid,
            token: token,
            business_name: businessName,
            email: email,
            contact_name: contactName,
            phone_number: phoneNumber,
            website: website,
            profile: profile,
        },
    })
        .then((res: any) => {
            const { success } = res.data
            return { success: success }
        })
        .catch((err: any) => {
            return { success: false }
        })
}

export default activateAccount
