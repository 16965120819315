import React, { useState } from 'react'
import {
    Grid,
    Typography,
    Button,
    AppBar,
    Toolbar,
    useMediaQuery,
    IconButton,
    Drawer,
    Box,
} from '@mui/material'
import { Menu, Close } from '@mui/icons-material'

import { useNavigate } from 'react-router-dom'

interface NavBarItems {
    name: string
    url: string
    icon?: JSX.Element
}

const items: NavBarItems[] = [
    {
        name: 'Login',
        url: '/login',
    },
    {
        name: 'Join',
        url: '/register',
    },
]

const NavMenu = () => {
    const navigate = useNavigate()
    const isMobile = useMediaQuery('(max-width: 960px)')
    const [openDrawer, setOpenDrawer] = useState<boolean>(false)

    const onLinkClick = (link: string) => {
        navigate(link)
    }

    return (
        <AppBar
            position="static"
            sx={{
                backgroundColor: '#fafbff',
                color: 'black',
                boxShadow: 'none',
                px: '0%',
                maxWidth: 1200,
                mx: 'auto',
            }}
        >
            <Toolbar>
                <Grid
                    container
                    justifyContent="space-between"
                    alignItems="flex-end"
                >
                    <Grid item xs={5}>
                        <Typography
                            fontSize={32}
                            color="#262d50"
                            fontWeight="bold"
                            fontFamily='"Montserrat", sans-serif'
                        >
                            dbeWorld
                        </Typography>
                    </Grid>

                    <Grid item xs={4} display="flex" justifyContent="flex-end">
                        {isMobile ? (
                            <IconButton onClick={() => setOpenDrawer(true)}>
                                <Menu sx={{ height: 30, width: 30 }} />
                            </IconButton>
                        ) : (
                            items.map((item) => (
                                <Button
                                    startIcon={item.icon}
                                    sx={{
                                        mx: 1,
                                        textTransform: 'none',
                                        color: 'black',
                                        backgroundColor: '#fafbff',
                                        fontSize: 20,
                                        padding: 0,
                                        '&:hover': {
                                            color: '#353535',
                                            backgroundColor: '#fafbff',
                                        },
                                    }}
                                    onClick={() => onLinkClick(item.url)}
                                >
                                    {item.name}
                                </Button>
                            ))
                        )}
                    </Grid>
                </Grid>
                <React.Fragment key="menu-drawer">
                    <Drawer open={openDrawer} anchor="top">
                        <Box p={3}>
                            <Box
                                justifyContent="flex-end"
                                display="flex"
                                pb={1}
                                sx={{ cursor: 'pointer' }}
                                onClick={() => setOpenDrawer(false)}
                            >
                                <Close sx={{ height: 40, width: 40 }} />
                            </Box>
                            {items.map((item) => (
                                <Box
                                    sx={{
                                        cursor: 'pointer',
                                        pb: 2,
                                        fontSize: 25,
                                        fontWeight: 'bold',
                                    }}
                                    onClick={() => onLinkClick(item.url)}
                                >
                                    {item.name}
                                </Box>
                            ))}
                        </Box>
                    </Drawer>
                </React.Fragment>
            </Toolbar>
        </AppBar>
    )
}

export default NavMenu
