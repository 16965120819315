import {
    GetUserRaw,
    GetUser,
    SignInRaw,
    SignIn,
    AccountRaw,
    Account,
} from '../../../utils/models/api-models'
import { sanitizeBusinessDataFromAPI } from '../search/util'

export const sanitizeGetUser = (value: GetUserRaw): GetUser => {
    return {
        id: value.id,
        email: value.email,
        accountType: value.account_type,
        verified: value.verified,
        business: value.business
            ? sanitizeBusinessDataFromAPI(value.business)
            : undefined,
    }
}

export const sanitizeAccount = (value: AccountRaw): Account => {
    const response: Account = {
        type: value.account_type,
        email: value.email,
        isSuper: value.is_super,
        id: value.id,
    }

    console.log(response)

    if (value?.business_id) {
        response['businessId'] = value.business_id
    }

    return response
}

export const sanitizeSignIn = (value: SignInRaw): SignIn => {
    return {
        success: value.success,
        accessToken: value.access,
        refreshToken: value.refresh,
        user: sanitizeAccount(value.account),
    }
}
