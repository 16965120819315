import { Box, Typography, Button, Stack, Tooltip } from '@mui/material'

import {
    HEADCOUNT,
    CERTIFICATES,
    CERTIFICATION_BODY,
    STATES,
    RADIUS,
} from '../../../utils/constants/info-constants'

import { NAICS } from '../../../utils/constants/naics-name'

import WorldSwitch from '../../ui-components/switch'
import WorldTextField from '../../ui-components/text-field'
import WorldSearchSelect from '../../ui-components/search-select'
import WorldTextFieldAdd from '../../ui-components/text-field-add'

import { useFormContext, Controller, useWatch } from 'react-hook-form'
import React from 'react'
import WorldSearchSingleSelect from '../../ui-components/search-single-select'
import { Info } from '@mui/icons-material'

const SearchFilters = ({
    handleSearch,
    disabledSearch,
}: {
    handleSearch: ({ pageNumber }: { pageNumber?: number }) => void
    disabledSearch: boolean
}) => {
    const methods = useFormContext()
    const { control } = methods

    const showRadiusCities = useWatch({ name: 'cities', control }).length > 0
    const showRadiusStates = useWatch({ name: 'states', control }).length == 1

    return (
        <React.Fragment>
            <Typography
                variant="h2"
                fontSize={21}
                lineHeight={1.6}
                fontWeight="bold"
            >
                Search Filters
            </Typography>
            <Stack spacing={2} mt={2}>
                <Stack spacing={0}>
                    <Controller
                        name={'verified' as const}
                        control={control}
                        render={({ field }) => (
                            <WorldSwitch
                                label="Verified"
                                switchProps={{
                                    onChange: (e) =>
                                        field.onChange(e.target.checked),
                                    value: field.value,
                                }}
                            />
                        )}
                    />

                    <Controller
                        name={'hasWebsite' as const}
                        control={control}
                        render={({ field }) => (
                            <WorldSwitch
                                label="Has Website"
                                switchProps={{
                                    onChange: (e) =>
                                        field.onChange(e.target.checked),
                                    value: field.value,
                                }}
                            />
                        )}
                    />
                </Stack>

                <Controller
                    name={'keywords' as const}
                    control={control}
                    render={({ field }) => (
                        <WorldTextFieldAdd
                            textFieldProps={{
                                label: 'Keywords',
                                placeholder: 'Add keywords',
                            }}
                            addValue={(values) => field.onChange(values)}
                        />
                    )}
                />

                <Controller
                    name={'businessName' as const}
                    control={control}
                    render={({ field }) => (
                        <WorldTextField
                            fullWidth
                            placeholder="Enter business name"
                            label="Business Name"
                            value={field.value}
                            onChange={(e) => field.onChange(e.target.value)}
                        />
                    )}
                />

                <Controller
                    name={'headcount' as const}
                    control={control}
                    render={({ field }) => (
                        <WorldSearchSelect
                            label="Headcount"
                            placeholder="Select headcount"
                            options={HEADCOUNT}
                            onChange={(selected) => field.onChange(selected)}
                            value={field.value}
                        />
                    )}
                />

                <Controller
                    name={'naicsCodes' as const}
                    control={control}
                    render={({ field }) => (
                        <WorldSearchSelect
                            label="NAICS Keyword/Number"
                            placeholder="Select NAICS"
                            options={NAICS}
                            onChange={(selected) => field.onChange(selected)}
                            value={field.value}
                            allowNewOptions={true}
                        />
                    )}
                />

                <Stack spacing={1}>
                    <Controller
                        name={'certificates' as const}
                        control={control}
                        render={({ field }) => (
                            <WorldSearchSelect
                                label="Certificates"
                                placeholder="Select certificates"
                                options={CERTIFICATES}
                                onChange={(selected) =>
                                    field.onChange(selected)
                                }
                                value={field.value}
                            />
                        )}
                    />
                    <Controller
                        name={'certificateBodies' as const}
                        control={control}
                        render={({ field }) => (
                            <WorldSearchSelect
                                label="Certificate Body"
                                placeholder="Select certificate bodies"
                                options={CERTIFICATION_BODY}
                                onChange={(selected) =>
                                    field.onChange(selected)
                                }
                                value={field.value}
                            />
                        )}
                    />
                </Stack>

                <Stack spacing={1}>
                    <Controller
                        name={'states' as const}
                        control={control}
                        render={({ field }) => (
                            <WorldSearchSelect
                                label="States"
                                placeholder="Select states"
                                options={STATES}
                                onChange={(selected) =>
                                    field.onChange(selected)
                                }
                                value={field.value}
                            />
                        )}
                    />
                    <Controller
                        name={'cities' as const}
                        control={control}
                        render={({ field }) => (
                            <WorldTextFieldAdd
                                textFieldProps={{
                                    label: 'Cities',
                                    placeholder: 'Add cities',
                                }}
                                addValue={(values) => field.onChange(values)}
                            />
                        )}
                    />
                    <Stack direction="row" spacing={1}>
                        <Controller
                            name={'locationRadius' as const}
                            control={control}
                            render={({ field }) => (
                                <WorldSearchSingleSelect
                                    label="Location Radius"
                                    placeholder="Select a radius"
                                    options={RADIUS}
                                    sx={{ width: '100%' }}
                                    disable={
                                        !(showRadiusCities && showRadiusStates)
                                    }
                                    onChange={(selected) =>
                                        field.onChange(selected)
                                    }
                                    value={field.value ?? RADIUS[0]}
                                />
                            )}
                        />
                        <Tooltip title="Radius search is only available if one (1) state is selected and there is atleast one (1) city included in the search">
                            <Info sx={{ alignSelf: 'center' }} />
                        </Tooltip>
                    </Stack>
                </Stack>
            </Stack>

            <Box mt={4} mb={2}>
                <Button
                    variant="text"
                    color="primary"
                    sx={{
                        width: '100%',
                        fontSize: 15,
                        px: 4,
                        py: 2,
                    }}
                    onClick={() => handleSearch({ pageNumber: 1 })}
                    disabled={disabledSearch}
                >
                    Search
                </Button>
            </Box>
        </React.Fragment>
    )
}

export default SearchFilters
