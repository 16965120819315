import { Box, SxProps } from '@mui/material'

const WhiteContainer = ({
    children,
    sx,
}: {
    children?: string | JSX.Element | JSX.Element[]
    sx?: SxProps
}) => {
    return (
        <Box
            px={4}
            py={2}
            bgcolor="white"
            boxShadow="rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px"
            borderRadius={2}
            display="flex"
            height={'fit-content'}
            sx={sx}
        >
            <Box my={2} width={'100%'}>
                {children}
            </Box>
        </Box>
    )
}

export default WhiteContainer
