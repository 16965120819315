import {
    TableContainer,
    Table as MUITable,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    IconButton,
} from '@mui/material'
import { Delete } from '@mui/icons-material'

interface TableProps {
    headers: string[]
    rows: any[]
    values: string[]
    onDelete: (id: number) => void
}

const Table = ({ headers, rows, values, onDelete }: TableProps) => {
    return (
        <TableContainer>
            <MUITable>
                <TableHead>
                    <TableRow>
                        {headers.map((h) => (
                            <TableCell sx={{ fontWeight: 'bold' }}>
                                {h}
                            </TableCell>
                        ))}
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((r) => (
                        <TableRow key={r.id}>
                            {values.map((v) => (
                                <TableCell>{r[v]}</TableCell>
                            ))}
                            <TableCell align="right">
                                <IconButton onClick={() => onDelete(r.id)}>
                                    <Delete color="error" />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </MUITable>
        </TableContainer>
    )
}

export default Table
