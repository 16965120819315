import { Autocomplete, SxProps } from '@mui/material'
import WorldTextField from './text-field'

import { Option } from '../../utils/models/api-models'

interface WorldSearchSingleSelectProps {
    label?: string
    placeholder: string
    options: Option[]
    value: Option | null
    onChange: (value: Option | null) => void
    sx?: SxProps
    disable?: boolean
    error?: boolean
    errorMessage?: string
    variant?: 'outlined' | 'filled' | 'standard'
}

const WorldSearchSingleSelect = ({
    label,
    placeholder,
    options,
    value,
    onChange,
    sx,
    disable = false,
    error = false,
    errorMessage = '',
    variant = 'filled',
}: WorldSearchSingleSelectProps) => (
    <Autocomplete
        sx={sx}
        options={options}
        onChange={(_, value) => onChange(value)}
        value={value}
        disabled={disable}
        getOptionLabel={(option) => option.label as string}
        renderInput={(params) => (
            <WorldTextField
                {...params}
                label={label}
                disabled={disable}
                error={error}
                variant={variant}
                helperText={errorMessage}
                placeholder={placeholder}
            />
        )}
    />
)

export default WorldSearchSingleSelect
