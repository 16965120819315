import { useEffect, useState } from 'react'

import {
    Box,
    Button,
    Chip,
    TextFieldProps,
    Tooltip,
    Typography,
} from '@mui/material'

import WorldTextField from './text-field'

interface WorldTextFieldAddProps {
    textFieldProps: TextFieldProps
    addValue: (value: string[]) => void
}

const WorldTextFieldAdd = ({
    textFieldProps,
    addValue,
}: WorldTextFieldAddProps) => {
    const [textFieldValue, setTextFieldValue] = useState<string>()
    const [allValues, setAllValues] = useState<string[]>([])

    const handleAddValues = () => {
        if (
            !textFieldValue ||
            textFieldValue.length === 0 ||
            textFieldValue.trim() === ''
        ) {
            return
        }
        setAllValues([textFieldValue, ...allValues])
        setTextFieldValue('')
    }

    const handleDelete = (value: string) => {
        const currentValues = [...allValues]
        const newValues = currentValues.filter((v: string) => v !== value)
        setAllValues(newValues)
    }

    useEffect(() => {
        addValue(allValues)
    }, [allValues])

    return (
        <>
            <Box display="flex">
                <WorldTextField
                    fullWidth
                    value={textFieldValue}
                    onChange={(event) => setTextFieldValue(event.target.value)}
                    {...textFieldProps}
                />
                <Tooltip title={`Click to add ${textFieldProps.label}`}>
                    <Button
                        variant="text"
                        color="primary"
                        sx={{
                            ml: 2,
                            fontSize: 20,
                            px: 1,
                            py: 1,
                        }}
                        onClick={() => handleAddValues()}
                    >
                        +
                    </Button>
                </Tooltip>
            </Box>
            {allValues.length > 0 && (
                <Box
                    mt={1}
                    height={50}
                    overflow="auto"
                    borderRadius={1}
                    px={3}
                    py={2}
                    sx={{
                        boxShadow: 'rgba(0, 0, 0, 0.10) 1px 2px 4px 1px inset',
                    }}
                >
                    <Typography variant="caption" fontWeight="bold">
                        {textFieldProps?.label}
                    </Typography>
                    <Box>
                        {allValues.map((v) => (
                            <Chip
                                sx={{ mr: 1, mt: 1 }}
                                label={v}
                                onDelete={() => handleDelete(v)}
                            />
                        ))}
                    </Box>
                </Box>
            )}
        </>
    )
}

export default WorldTextFieldAdd
