import { Box, Button, Divider, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import Table from './table'

import { getMyBusiness } from '../../../api/v1/business'
import { upsertLocation, deleteLocation } from '../../../api/v1/location'

import WorldTextField from '../../ui-components/text-field'
import WorldSelect from '../../ui-components/select'

import { STATES } from '../../../utils/constants/info-constants'

import { Location } from '../../../utils/models/api-models'

import {
    Failure,
    RemoveLocation,
    CreateLocation,
} from '../../../utils/constants/messages'
import { setOpen } from '../../../slices/notification-slice'

const Locations = ({
    fetchBusiness,
    locations,
}: {
    fetchBusiness: () => Promise<void>
    locations: Location[]
}) => {
    const dispatch = useDispatch()
    const { control, reset, getValues, trigger } = useForm({
        defaultValues: {
            city: '',
            state: STATES[0].value,
        },
    })

    const [disable, setDisable] = useState(false)

    const handleSubmit = async () => {
        setDisable(true)
        if (!(await trigger())) {
            setDisable(false)
            return
        }
        const { city, state } = getValues()
        const { success } = await upsertLocation({
            city: city.trim(),
            state: state.trim(),
        })
        dispatch(
            setOpen({
                open: true,
                message: success ? CreateLocation : Failure,
            })
        )
        reset()
        await fetchBusiness()
        setDisable(false)
    }

    const handleDelete = async (id: number) => {
        setDisable(true)
        const { success } = await deleteLocation({ id: id })
        dispatch(
            setOpen({
                open: true,
                message: success ? RemoveLocation : Failure,
            })
        )
        await fetchBusiness()
        setDisable(false)
    }

    return (
        <>
            <Typography variant="h6" mt={2}>
                Add a new location
            </Typography>
            <Typography variant="caption" sx={{ width: 150 }}>
                Let contractors know where your business is situated.
            </Typography>
            <Box display="flex" justifyContent="center" mt={6}>
                <Typography variant="subtitle2" width={200} sx={{ mr: 4 }}>
                    State
                </Typography>
                <Controller
                    name={'state' as const}
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <WorldSelect
                            placeholder="Enter state"
                            options={STATES}
                            disabled={disable}
                            value={field.value}
                            error={Boolean(error)}
                            onChange={(e) => field.onChange(e.target.value)}
                            sx={{
                                width: 400,
                            }}
                        />
                    )}
                    rules={{
                        required: {
                            value: true,
                            message: 'State is required',
                        },
                    }}
                />
            </Box>
            <Box display="flex" justifyContent="center" mt={6}>
                <Typography variant="subtitle2" width={200} sx={{ mr: 4 }}>
                    City
                </Typography>
                <Controller
                    name={'city' as const}
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <WorldTextField
                            placeholder="Enter city"
                            value={field.value}
                            variant="outlined"
                            disabled={disable}
                            error={Boolean(error)}
                            helperText={error?.message || ''}
                            onChange={(e) => field.onChange(e.target.value)}
                            sx={{
                                width: 400,
                            }}
                        />
                    )}
                    rules={{
                        required: {
                            value: true,
                            message: 'City is required',
                        },
                        maxLength: {
                            value: 500,
                            message: 'City must be 500 characters or less',
                        },
                        pattern: {
                            value: /^[^\s]+(?:$|.*[^\s]+$)/,
                            message: 'City can not start or end with a space',
                        },
                    }}
                />
            </Box>
            <Box display="flex" justifyContent="center" mt={6}>
                <Button
                    variant="text"
                    color="primary"
                    sx={{
                        px: 2,
                    }}
                    disabled={disable}
                    onClick={() => handleSubmit()}
                >
                    Add Location
                </Button>
            </Box>
            <Divider variant="middle" sx={{ mt: 6, mb: 2 }} />
            <Typography variant="h6">Existing locations</Typography>
            <Typography variant="caption" sx={{ width: 150 }}>
                View and delete your existing locations.
            </Typography>
            <Box mt={4}>
                {locations.length === 0 ? (
                    <Typography variant="subtitle2">
                        There are no existing locations.
                    </Typography>
                ) : (
                    <Table
                        headers={['State', 'City']}
                        values={['state', 'city']}
                        rows={locations}
                        onDelete={handleDelete}
                    />
                )}
            </Box>
        </>
    )
}

export default Locations
