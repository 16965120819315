import { Grid, Typography, Link } from '@mui/material'

const Error = () => {
    const linkStyling = {
        color: 'black',
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
            color: '#262d50',
        },
    }

    return (
        <>
            <Grid
                container
                justifyContent="flex-start"
                px={10}
                mt={10}
                direction="column"
                alignItems="center"
                textAlign="center"
            >
                <Grid item>
                    <Typography
                        variant="h5"
                        color="#262d50"
                        fontWeight="bold"
                        fontFamily='"Montserrat", sans-serif'
                    >
                        dbeWorld
                    </Typography>
                </Grid>
                <Grid item mt={4}>
                    <Typography
                        variant="h3"
                        fontWeight="bold"
                        fontFamily='"Montserrat", sans-serif'
                    >
                        Oops! Not Found
                    </Typography>
                </Grid>
                <Grid item mt={2}>
                    <Typography variant="h5" color="#7f7f7f">
                        We don't think you wanted to end up here.
                    </Typography>
                </Grid>
                <Grid item mt={6} justifyContent="flex-start"></Grid>
            </Grid>
            <Grid
                container
                justifyContent="center"
                mt={3}
                spacing={2}
                sx={{ fontSize: 12 }}
            >
                <Grid item>
                    <Link href="/register" sx={linkStyling}>
                        Register
                    </Link>
                </Grid>
                <Grid item>|</Grid>
                <Grid item>
                    <Link href="/login" sx={linkStyling}>
                        Login
                    </Link>
                </Grid>
                <Grid item>|</Grid>
                <Grid item>
                    <Link href="/resend-activation" sx={linkStyling}>
                        Resend Activation
                    </Link>
                </Grid>
                <Grid item>|</Grid>
                <Grid item>
                    <Link href="/reset-password" sx={linkStyling}>
                        Reset Password
                    </Link>
                </Grid>
            </Grid>
        </>
    )
}

export default Error
