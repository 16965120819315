import {
    Drawer,
    Toolbar,
    List,
    Link,
    ListItem,
    ListItemButton,
    ListItemText,
    Typography,
    ListItemIcon,
    IconButton,
    Tooltip,
} from '@mui/material'
import {
    Search,
    Edit,
    Visibility,
    Work,
    Settings,
    Logout,
    Store,
    ChevronLeft,
    Menu,
} from '@mui/icons-material'
import { useSelector, useDispatch } from 'react-redux'
import { getUserInfo } from '../../../selectors/login-selector'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { getSideDrawerState } from '../../../selectors/side-drawer-selector'
import { setOpen as setDrawerOpen } from '../../../slices/side-drawer-slice'

interface LinkProps {
    url: string
    label: string
    icon: () => JSX.Element
    type: 'all' | 'contractors' | 'business'
}

const SideDrawer = () => {
    const userInfo = useSelector(getUserInfo)
    const sideBarInformation = useSelector(getSideDrawerState).open

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [open, setOpen] = useState(sideBarInformation)

    const logout = () => {
        localStorage.clear()
        navigate('/')
    }

    const toggleSideBar = (value: boolean) => {
        setOpen(value)
        dispatch(setDrawerOpen({ open: value }))
    }

    const allLinks: LinkProps[] = [
        {
            url: '/platform/search',
            label: 'Directory',
            icon: () => (
                <Search
                    sx={{
                        color: 'white',
                        height: 20,
                        width: 20,
                        mr: open ? 0.5 : 0,
                    }}
                />
            ),
            type: 'all',
        },
        {
            url: '/platform/opportunity',
            label: 'Opportunity',
            icon: () => (
                <Work
                    sx={{
                        color: 'white',
                        height: 20,
                        width: 20,
                        mr: open ? 0.5 : 0,
                    }}
                />
            ),
            type: 'all',
        },
        {
            url: '/platform/watchlist',
            label: 'Watchlist',
            icon: () => (
                <Visibility
                    sx={{
                        color: 'white',
                        height: 20,
                        width: 20,
                        mr: open ? 0.5 : 0,
                    }}
                />
            ),
            type: 'contractors',
        },
        {
            url: '/platform/business/edit',
            label: 'Edit Business',
            icon: () => (
                <Edit
                    sx={{
                        color: 'white',
                        height: 20,
                        width: 20,
                        mr: open ? 0.5 : 0,
                    }}
                />
            ),
            type: 'business',
        },
        {
            url: `/platform/business/${userInfo.businessId}`,
            label: 'My Business',
            icon: () => (
                <Store
                    sx={{
                        color: 'white',
                        height: 20,
                        width: 20,
                        mr: open ? 0.5 : 0,
                    }}
                />
            ),
            type: 'business',
        },
    ]
    const bottomLinks: LinkProps[] = [
        {
            url: '/platform/settings',
            label: 'Settings',
            icon: () => (
                <Settings
                    sx={{
                        color: 'white',
                        height: 20,
                        width: 20,
                        mr: open ? 0.5 : 0,
                    }}
                />
            ),
            type: 'all',
        },
    ]

    const createLinks = (links: LinkProps[], open: Boolean) => {
        let filteredLinks: LinkProps[] = []
        if (userInfo.userType !== 'BUSINESS') {
            filteredLinks = links.filter((i) => i.type !== 'business')
        } else {
            filteredLinks = links.filter((i) => i.type !== 'contractors')
        }

        return filteredLinks.map((l) => (
            <ListItem
                key={l.label}
                disablePadding
                component={Link}
                sx={{ color: '#e5e5e5' }}
                href={l.url}
            >
                <ListItemButton>
                    <Tooltip title={open ? '' : l.label} placement="right">
                        <ListItemIcon
                            sx={{
                                width: '25px',
                                minWidth: '25px',
                                color: 'white',
                                justifyContent: 'center',
                            }}
                        >
                            {l.icon()}
                        </ListItemIcon>
                    </Tooltip>
                    {open && <ListItemText primary={l.label} />}
                </ListItemButton>
            </ListItem>
        ))
    }

    return (
        <Drawer
            PaperProps={{
                sx: {
                    backgroundColor: '#262d50',
                },
            }}
            sx={
                open
                    ? {
                          width: 170,
                          '& .MuiDrawer-paper': {
                              width: 170,
                              boxSizing: 'border-box',
                          },
                      }
                    : {
                          width: 60,
                          '& .MuiDrawer-paper': {
                              width: 60,
                              boxSizing: 'border-box',
                          },
                      }
            }
            variant="permanent"
            anchor="left"
            open={open}
        >
            {open ? (
                <Toolbar
                    sx={{ display: 'flex', justifyContent: 'space-between' }}
                >
                    <Typography
                        color="white"
                        fontWeight="bold"
                        fontFamily='"Montserrat", sans-serif'
                    >
                        dbeWorld
                    </Typography>
                    <IconButton onClick={() => toggleSideBar(false)}>
                        <ChevronLeft
                            sx={{ height: 20, width: 20, color: 'white' }}
                        />
                    </IconButton>
                </Toolbar>
            ) : (
                <Tooltip title={'Open Menu'} placement="right">
                    <IconButton onClick={() => toggleSideBar(true)}>
                        <Menu sx={{ color: 'white' }} />
                    </IconButton>
                </Tooltip>
            )}

            <List>{createLinks(allLinks, open)}</List>
            <List sx={{ position: 'absolute', bottom: '0', width: '100%' }}>
                {createLinks(bottomLinks, open)}
                <ListItem key="logout" disablePadding sx={{ color: '#e5e5e5' }}>
                    <ListItemButton onClick={logout}>
                        <Tooltip title={open ? '' : 'Logout'} placement="right">
                            <ListItemIcon
                                sx={{
                                    width: '25px',
                                    minWidth: '25px',
                                    justifyContent: 'center',
                                }}
                            >
                                <Logout
                                    sx={{
                                        height: 20,
                                        width: 20,
                                        color: 'white',
                                    }}
                                />
                            </ListItemIcon>
                        </Tooltip>
                        {open && (
                            <ListItemText
                                primary={'Logout'}
                                sx={{ color: '#ff7777' }}
                            />
                        )}
                    </ListItemButton>
                </ListItem>
            </List>
        </Drawer>
    )
}

export default SideDrawer
