import React, { useState } from 'react'
import { Grid, Typography, Button, Link, Box } from '@mui/material'

import WorldTextField from '../../ui-components/text-field'
import WorldSnackbar from '../../ui-components/snackbar'

import resetPasswordEmail from '../../../api/v1/user/reset-password-email'
import { Failure } from '../../../utils/constants/messages'

const messages = {
    success:
        'We have sent the password reset email to the email provided if it is registered to dbeWorld. Please also check span and junk email.',
    failure: Failure,
}

const ResetPassword = () => {
    const [email, setEmail] = useState<string | null>(null)
    const [error, setError] = useState<boolean>(false)
    const [submitted, setSubmitted] = useState<boolean>(false)
    const [messageType, setMessageType] = useState<'success' | 'failure'>(
        'success'
    )
    const [disable, setDisabled] = useState<boolean>(false)

    const handleResetPassword = async () => {
        setDisabled(true)
        if (!email) {
            setError(true)
            setDisabled(false)
            return
        }

        const { success } = await resetPasswordEmail(email)

        if (!success) {
            setError(true)
        } else {
            setError(false)
        }

        setDisabled(false)
        setMessageType(success ? 'success' : 'failure')
        setSubmitted(true)
    }

    const linkStyling = {
        color: 'black',
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
            color: '#262d50',
        },
    }

    return (
        <>
            <Grid
                container
                justifyContent="flex-start"
                px={10}
                mt={10}
                direction="column"
                alignItems="center"
                textAlign="center"
            >
                <Grid item>
                    <Typography
                        variant="h5"
                        color="#262d50"
                        fontWeight="bold"
                        fontFamily='"Montserrat", sans-serif'
                    >
                        dbeWorld
                    </Typography>
                </Grid>
                <Grid item mt={4}>
                    <Typography
                        variant="h3"
                        fontWeight="bold"
                        fontFamily='"Inter", sans-serif'
                    >
                        Reset Password
                    </Typography>
                </Grid>
                <Grid item mt={6} justifyContent="flex-start">
                    <WorldTextField
                        placeholder="Enter email"
                        label="Email"
                        type="text"
                        error={error}
                        fullWidth
                        value={email}
                        onChange={(e: React.BaseSyntheticEvent) =>
                            setEmail(e.target.value)
                        }
                    />
                    <Button
                        variant="text"
                        color="primary"
                        sx={{
                            mt: 3,
                            width: '100%',
                            px: 4,
                        }}
                        onClick={() => handleResetPassword()}
                        disabled={disable}
                    >
                        Reset Password
                    </Button>
                </Grid>
            </Grid>
            <Grid
                container
                justifyContent="center"
                mt={3}
                spacing={2}
                sx={{ fontSize: 12 }}
            >
                <Grid item>
                    <Link href="/register" sx={linkStyling}>
                        Register
                    </Link>
                </Grid>
                <Grid item>|</Grid>
                <Grid item>
                    <Link href="/login" sx={linkStyling}>
                        Login
                    </Link>
                </Grid>
                <Grid item>|</Grid>
                <Grid item>
                    <Link href="/resend-activation" sx={linkStyling}>
                        Resend Activation
                    </Link>
                </Grid>
            </Grid>
            <WorldSnackbar
                open={submitted}
                setOpen={setSubmitted}
                message={<Box>{messages[messageType]}</Box>}
            />
        </>
    )
}

export default ResetPassword
