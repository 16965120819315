import callAPI from '../api-base'

import { GetUserResponse } from '../../../utils/models/api-models'
import { sanitizeGetUser } from './util'

const getUserActivate = (
    uuid: string
): GetUserResponse | Promise<GetUserResponse> => {
    return callAPI({
        url: `auth/get-user/${uuid}/`,
        type: 'get',
        body: {},
    })
        .then((res: any) => {
            const { success, data } = res.data
            return { success: success, data: sanitizeGetUser(data) }
        })
        .catch((err: any) => {
            return { success: false, data: null }
        })
}

export default getUserActivate
