import { Box, Button } from '@mui/material'
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import ActionButtons from './action-buttons'

const columns: GridColDef[] = [
    {
        field: 'id',
        headerName: 'ID',
        editable: false,
        sortable: false,
        flex: 0.25,
        renderCell: (params: GridRenderCellParams) => (
            <Box>{params.row.id}</Box>
        ),
    },
    {
        field: 'email',
        headerName: 'Email',
        editable: false,
        sortable: false,
        flex: 1,
        renderCell: (params: GridRenderCellParams) => (
            <Box>{params.row.email}</Box>
        ),
    },
    {
        field: 'type',
        headerName: 'Type',
        editable: false,
        sortable: false,
        flex: 1,
        renderCell: (params: GridRenderCellParams) => (
            <Box>{params.row.type}</Box>
        ),
    },
    {
        field: 'buttons',
        headerName: '',
        editable: false,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => (
            <ActionButtons id={params.row.id} />
        ),
    },
]

interface TableProps {
    rows: any[]
    count: number
    setPage: (page: number) => void
    disable: boolean
    page: number
}

const Table = ({ rows, count, setPage, disable, page }: TableProps) => (
    <Box
        sx={{
            height: '100vh',
            width: '100%',
        }}
    >
        <DataGrid
            rows={rows}
            columns={columns}
            pageSize={20}
            disableColumnFilter
            disableColumnMenu
            disableSelectionOnClick
            disableColumnSelector
            onPageChange={(page, _) => setPage(page + 1)}
            rowCount={count}
            page={page - 1}
            paginationMode="server"
            getRowHeight={() => 'auto'}
            loading={disable}
            getRowId={(row) => row.email}
            sx={{
                border: 0,
                '& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cell:focus-within':
                    {
                        outline: 'none',
                    },
                '& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus':
                    {
                        outline: 'none',
                    },
                '.MuiDataGrid-columnSeparator': {
                    display: 'none',
                },
                '& .MuiDataGrid-columnHeaderTitle': {
                    fontWeight: 'bold',
                    color: '#262d50',
                },
                '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
                    py: '8px',
                },
                '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
                    py: '15px',
                },
                '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
                    py: '22px',
                },
            }}
        />
    </Box>
)

export default Table
