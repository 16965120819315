import { Box, Button, Divider, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useForm, Controller, useFieldArray } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { getMyBusiness, updateMyBusiness } from '../../../api/v1/business'

import WorldTextField from '../../ui-components/text-field'

import { Failure, UpdateContactInfo } from '../../../utils/constants/messages'
import { setOpen } from '../../../slices/notification-slice'
import { Contact } from '../../../utils/models/api-models'

const ContactInfo = ({
    fetchBusiness,
    contacts,
}: {
    fetchBusiness: () => Promise<void>
    contacts: Contact[]
}) => {
    const dispatch = useDispatch()

    const { control, reset, getValues, trigger } = useForm({
        defaultValues: {
            contactName: contacts?.[0]?.name,
            phoneNumber: contacts?.[0]?.phoneNumber,
            secondaryName: contacts?.[1]?.name,
            secondaryPhoneNumber: contacts?.[1]?.phoneNumber,
            secondaryEmail: contacts?.[1]?.email,
        },
    })

    useEffect(() => {
        reset({
            contactName: contacts?.[0]?.name,
            phoneNumber: contacts?.[0]?.phoneNumber,
            secondaryName: contacts?.[1]?.name,
            secondaryPhoneNumber: contacts?.[1]?.phoneNumber,
            secondaryEmail: contacts?.[1]?.email,
        })
    }, [])

    const [disable, setDisable] = useState(false)

    const handleSubmit = async () => {
        setDisable(true)

        if (!(await trigger())) {
            setDisable(false)
            return
        }

        const {
            contactName,
            phoneNumber,
            secondaryEmail,
            secondaryName,
            secondaryPhoneNumber,
        } = getValues()

        let newContacts: Contact[] = []
        newContacts.push({
            name: contactName.trim(),
            phoneNumber: phoneNumber.trim(),
            email: contacts?.[0]?.email?.trim(),
            type: 0,
        })
        if (secondaryEmail) {
            newContacts.push({
                name: secondaryName.trim(),
                phoneNumber: secondaryPhoneNumber.trim(),
                email: secondaryEmail.trim(),
                type: 1,
            })
        }

        const { success } = await updateMyBusiness({
            contacts: newContacts,
        })

        dispatch(
            setOpen({
                open: true,
                message: success ? UpdateContactInfo : Failure,
            })
        )

        await fetchBusiness()
        setDisable(false)
    }

    return (
        <>
            <Box display="flex" justifyContent="center" mt={8}>
                <Typography variant="subtitle2" width={200} sx={{ mr: 4 }}>
                    Primary Contact Name
                </Typography>
                <Controller
                    name={'contactName' as const}
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <WorldTextField
                            placeholder="Enter contact name"
                            value={field.value}
                            variant="outlined"
                            disabled={disable}
                            error={Boolean(error)}
                            helperText={error?.message || ''}
                            onChange={(e) => field.onChange(e.target.value)}
                            sx={{
                                width: 400,
                            }}
                        />
                    )}
                    rules={{
                        required: {
                            value: true,
                            message: 'Contact name is required',
                        },
                        maxLength: {
                            value: 100,
                            message:
                                'Contact name must be 100 characters or less',
                        },
                        pattern: {
                            value: /^[^\s]+(?:$|.*[^\s]+$)/,
                            message:
                                'Contact name can not start or end with a space',
                        },
                    }}
                />
            </Box>
            <Box display="flex" justifyContent="center" mt={4}>
                <Typography variant="subtitle2" width={200} sx={{ mr: 4 }}>
                    Primary Phone Number (Optional)
                </Typography>
                <Controller
                    name={'phoneNumber' as const}
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <WorldTextField
                            placeholder="Enter phone number"
                            type="number"
                            variant="outlined"
                            value={field.value}
                            disabled={disable}
                            onChange={(e) => field.onChange(e.target.value)}
                            error={Boolean(error)}
                            helperText={error?.message || ''}
                            sx={{
                                width: 400,
                            }}
                        />
                    )}
                    rules={{
                        pattern: {
                            value: /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
                            message: 'Enter a valid phone number',
                        },
                    }}
                />
            </Box>
            <Divider variant="middle" sx={{ mt: 6, mb: 2 }} />

            <Box display="flex" justifyContent="center" mt={8}>
                <Typography variant="subtitle2" width={200} sx={{ mr: 4 }}>
                    Secondary Contact Name
                </Typography>
                <Controller
                    name={'secondaryName' as const}
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <WorldTextField
                            placeholder="Enter contact name"
                            value={field.value}
                            variant="outlined"
                            disabled={disable}
                            error={Boolean(error)}
                            helperText={error?.message || ''}
                            onChange={(e) => field.onChange(e.target.value)}
                            sx={{
                                width: 400,
                            }}
                        />
                    )}
                    rules={{
                        maxLength: {
                            value: 100,
                            message:
                                'Contact name must be 100 characters or less',
                        },

                        validate: () => {
                            const {
                                secondaryEmail,
                                secondaryName,
                                secondaryPhoneNumber,
                            } = getValues()

                            if (
                                secondaryName.trim().length == 0 &&
                                (secondaryEmail.trim().length > 0 ||
                                    secondaryPhoneNumber.length > 0)
                            ) {
                                return 'Contact name is required'
                            }
                        },
                    }}
                />
            </Box>
            <Box display="flex" justifyContent="center" mt={4}>
                <Typography variant="subtitle2" width={200} sx={{ mr: 4 }}>
                    Secondary Contact Email
                </Typography>
                <Controller
                    name={'secondaryEmail' as const}
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <WorldTextField
                            placeholder="Enter email"
                            value={field.value}
                            variant="outlined"
                            disabled={disable}
                            error={Boolean(error)}
                            helperText={error?.message || ''}
                            onChange={(e) => field.onChange(e.target.value)}
                            sx={{
                                width: 400,
                            }}
                        />
                    )}
                    rules={{
                        pattern: {
                            value: /\S+@\S+\.\S+/,
                            message:
                                'Entered value does not match email format',
                        },
                        validate: () => {
                            const { secondaryEmail, secondaryName } =
                                getValues()

                            if (
                                secondaryEmail.trim().length === 0 &&
                                secondaryName.trim().length > 0
                            ) {
                                return 'Email is required'
                            }
                        },
                    }}
                />
            </Box>
            <Box display="flex" justifyContent="center" mt={4}>
                <Typography variant="subtitle2" width={200} sx={{ mr: 4 }}>
                    Secondary Phone Number (Optional)
                </Typography>
                <Controller
                    name={'secondaryPhoneNumber' as const}
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <WorldTextField
                            placeholder="Enter phone number"
                            type="number"
                            variant="outlined"
                            value={field.value}
                            disabled={disable}
                            onChange={(e) => field.onChange(e.target.value)}
                            error={Boolean(error)}
                            helperText={error?.message || ''}
                            sx={{
                                width: 400,
                            }}
                        />
                    )}
                    rules={{
                        pattern: {
                            value: /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
                            message: 'Enter a valid phone number',
                        },
                    }}
                />
            </Box>

            <Box display="flex" justifyContent="center" mt={6}>
                <Button
                    variant="text"
                    color="primary"
                    sx={{
                        px: 2,
                    }}
                    disabled={disable}
                    onClick={() => handleSubmit()}
                >
                    Save Contact Info
                </Button>
            </Box>
        </>
    )
}

export default ContactInfo
