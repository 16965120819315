import callAPI from '../api-base'

import { StatusResponse } from '../../../utils/models/api-models'

interface ResetPasswordProps {
    uuid: string
    token: string
    password: string
}

const resetPassword = ({
    uuid,
    token,
    password,
}: ResetPasswordProps): StatusResponse | Promise<StatusResponse> => {
    return callAPI({
        url: 'auth/reset-password/',
        type: 'post',
        body: {
            new_password: password,
            uuid: uuid,
            token: token,
        },
    })
        .then((res: any) => {
            const { success } = res.data
            return { success: success }
        })
        .catch((err: any) => {
            return { success: false }
        })
}

export default resetPassword
