import { Box, Snackbar } from '@mui/material'
import { useDispatch } from 'react-redux'
import { setOpen as changeNotif } from '../../slices/notification-slice'

const WorldSnackbar = ({
    message,
    open,
    setOpen,
}: {
    message: JSX.Element
    open: boolean
    setOpen: (val: boolean) => void
}) => {
    const dispatch = useDispatch()

    const handleClose = () => {
        dispatch(
            changeNotif({
                open: false,
                message: null,
            })
        )
        setOpen(false)
    }

    return (
        <Snackbar
            open={open}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            autoHideDuration={6000}
            message={<Box textAlign="center">{message}</Box>}
            onClose={handleClose}
        />
    )
}

export default WorldSnackbar
