import { useNavigate } from 'react-router-dom'
import {
    getAccounts as getAccountsAPI,
    AccountsRepsonse,
} from '../../../../api/v1/super/index'
import { useEffect, useState } from 'react'
import Table from './table'

const AccountTable = () => {
    const [accounts, setAccounts] = useState<AccountsRepsonse>()
    const [page, setPage] = useState<number>(1)

    const navigate = useNavigate()

    const getAccounts = async () => {
        const { success, data } = await getAccountsAPI({ pageNumber: page })
        if (!success || !data) {
            navigate('/404-not-found')
        }
        setAccounts({ success: success, data: data })
    }

    useEffect(() => {
        getAccounts()
    }, [page, setPage])

    return (
        <>
            <Table
                rows={accounts?.data?.data || []}
                count={accounts?.data?.count || 0}
                disable={false}
                page={page}
                setPage={(value: number) => {
                    setPage(value)
                }}
            />
        </>
    )
}

export default AccountTable
