import callAPI from '../api-base'

import { StatusResponse } from '../../../utils/models/api-models'

interface ChangePasswordApiProps {
    oldPassword: string
    newPassword: string
}

const changePasswordAPI = ({
    oldPassword,
    newPassword,
}: ChangePasswordApiProps): StatusResponse | Promise<StatusResponse> => {
    return callAPI({
        url: 'auth/change-password/',
        type: 'put',
        body: {
            old_password: oldPassword,
            new_password: newPassword,
        },
        auth: true,
    })
        .then((res: any) => {
            const { success } = res.data
            return { success: success }
        })
        .catch((err: any) => {
            return { success: false }
        })
}

export default changePasswordAPI
