import {
    Naics,
    NaicsRaw,
    Response,
    StatusResponse,
} from '../../../utils/models/api-models'
import callAPI from '../api-base'

type NaicsResponse = Response<Naics>

const NaicsAPI = () => {
    const sanitizeThenReturnNaics = (naics: NaicsRaw): Naics => {
        return {
            code: naics.naics_code,
            id: naics.id,
        }
    }

    const sanitizeThenReturnData = (responseData: any): NaicsResponse => {
        const { success, data } = responseData
        return {
            success: success,
            data: data !== null ? sanitizeThenReturnNaics(data) : null,
        }
    }

    const upsertNaics = ({
        id,
        code,
    }: {
        id?: number
        code: string
    }): Promise<NaicsResponse> => {
        return callAPI({
            url: 'naics/',
            type: 'post',
            body: {
                id: id,
                naics_code: code,
            },
            auth: true,
        })
            .then((res: any) => {
                return sanitizeThenReturnData(res.data)
            })
            .catch((err: any) => {
                return {
                    success: false,
                    data: null,
                }
            })
    }

    const deleteNaics = ({ id }: { id: number }): Promise<StatusResponse> => {
        return callAPI({
            url: 'naics/',
            type: 'delete',
            body: { id: id },
            auth: true,
        })
            .then((res: any) => {
                return { success: res.data.success }
            })
            .catch((err: any) => {
                return { success: false }
            })
    }

    return { upsertNaics, deleteNaics, sanitizeThenReturnNaics }
}

export const { upsertNaics, deleteNaics, sanitizeThenReturnNaics } = NaicsAPI()
