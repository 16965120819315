import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface NotificationState {
    open: boolean
    message: string | null
}

const initialState: NotificationState = {
    open: false,
    message: null,
}

export const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        setOpen: (state, action: PayloadAction<NotificationState>) => {
            state.open = action.payload.open
            state.message = action.payload.message
        },
    },
})

export const { setOpen } = notificationSlice.actions

export default notificationSlice
