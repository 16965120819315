import { Box, Tab, Tabs, Typography } from '@mui/material'
import { useState } from 'react'

import ChangePassword from './change-password'

const TabPages = (val: number) => {
    switch (val) {
        case 0:
            return <ChangePassword />
    }
}

const UserSettings = () => {
    const [tabPage, setTabPage] = useState(0)

    return (
        <Box>
            <Typography
                variant="h4"
                color="black"
                fontFamily='"Inter", sans-serif'
                fontWeight="bold"
            >
                Settings
            </Typography>
            <Box
                mt={4}
                px={4}
                py={2}
                bgcolor="white"
                boxShadow="rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px"
                borderRadius={2}
            >
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs
                        value={tabPage}
                        onChange={(_, val) => setTabPage(val)}
                    >
                        <Tab label="Change Password" />
                    </Tabs>
                </Box>
                <Box mt={2} mb={6}>
                    {TabPages(tabPage)}
                </Box>
            </Box>
        </Box>
    )
}

export default UserSettings
