import { configureStore } from '@reduxjs/toolkit'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import { combineReducers } from 'redux'

import accountSlice from './slices/account-slice'
import notificationSlice from './slices/notification-slice'
import sideDrawerSlice from './slices/side-drawer-slice'

const persistConfig = {
    key: 'root',
    storage,
}

const red = combineReducers({
    account: accountSlice.reducer,
    notification: notificationSlice.reducer,
    sideDrawer: sideDrawerSlice.reducer,
})
const persistedReducer = persistReducer(persistConfig, red)

export const store = configureStore({
    reducer: persistedReducer,
})

export const persistor = persistStore(store)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
