import {
    OpportunityResponse,
    Opportunity,
    Paginated,
} from '../../../../utils/models/api-models'
import WhiteContainer from '../../../ui-components/white-container'
import ResponseTable from './response-table'
import { getOpportunityResponses } from '../../../../api/v1/opportunity-response'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setOpen } from '../../../../slices/notification-slice'
import { Failure } from '../../../../utils/constants/messages'

interface ResponseProps {
    opportunity: Opportunity
}

const Responses = ({ opportunity }: ResponseProps) => {
    const dispatch = useDispatch()

    const [data, setData] = useState<Paginated<OpportunityResponse>>()

    const getResponses = async () => {
        const { success, data } = await getOpportunityResponses({
            opportunityId: opportunity.id,
        })

        if (success) {
            setData({
                data: data?.data || null,
                count: data?.count || 0,
                numPages: data?.numPages || 0,
            })
            return
        }

        dispatch(
            setOpen({
                open: true,
                message: Failure,
            })
        )
    }

    useEffect(() => {
        getResponses()
    }, [])

    return (
        <WhiteContainer>
            <ResponseTable
                data={data?.data || []}
                count={data?.count || 0}
                opportunity={opportunity}
            />
        </WhiteContainer>
    )
}

export default Responses
