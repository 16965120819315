import {
    Business,
    BusinessRaw,
    Contact,
    ContactRaw,
    Response,
} from '../../../utils/models/api-models'
import callAPI from '../api-base'
import { sanitizeThenReturnNaics as naicsSanitize } from '../naics'
import { sanitizeThenReturnExperience as experienceSanitize } from '../business-experience'

type BusinessResponse = Response<Business>

const BusinessAPI = () => {
    const sanitizeThenReturnContact = (contact: ContactRaw): Contact => {
        return {
            name: contact.name,
            email: contact.email,
            phoneNumber: contact.phone_number,
            type: contact.type,
        }
    }

    const desantizeThenReturnContact = (contact: Contact): ContactRaw => {
        return {
            name: contact.name,
            email: contact.email,
            phone_number: contact.phoneNumber,
            type: contact.type,
        }
    }

    const sanitizeThenReturnBusiness = (business: BusinessRaw): Business => {
        return {
            id: business.id,
            name: business.name,
            isVerified: business.is_verified,
            website: business.website,
            profile: business.profile,
            headcount: business.headcount,
            accountName: business.account_name,
            services: business.services,
            naics: business.naics.map((naic) => naicsSanitize(naic)),
            certificates: business.certificates,
            locations: business.locations,
            contacts: business.contacts.map((c: ContactRaw) =>
                sanitizeThenReturnContact(c)
            ),
            experiences: business.experiences.map((e) => experienceSanitize(e)),
        }
    }

    const sanitizeAndReturnData = (responseData: any): BusinessResponse => {
        const { success, data } = responseData
        return {
            success: success,
            data: data !== null ? sanitizeThenReturnBusiness(data) : null,
        }
    }

    const getBusinessById = ({
        id,
        authenticated,
    }: {
        id: number
        authenticated: boolean
    }): Promise<BusinessResponse> => {
        return callAPI({
            url: '/business/' + id.toString() + '/',
            type: 'get',
            body: {},
            auth: authenticated,
        })
            .then((res: any) => {
                return sanitizeAndReturnData(res.data)
            })
            .catch((err: any) => {
                return { success: false, data: null }
            })
    }

    const getMyBusiness = (): Promise<BusinessResponse> => {
        return callAPI({
            url: '/business/',
            type: 'get',
            body: {},
            auth: true,
        })
            .then((res: any) => {
                return sanitizeAndReturnData(res.data)
            })
            .catch((err: any) => {
                return { success: false, data: null }
            })
    }

    const updateMyBusiness = ({
        name,
        profile,
        headcount,
        website,
        contacts,
    }: {
        name?: string
        profile?: string
        headcount?: string
        website?: string
        contacts?: Contact[]
    }): Promise<BusinessResponse> => {
        return callAPI({
            url: '/business/',
            type: 'post',
            body: {
                name: name,
                profile: profile,
                headcount: headcount,
                website: website,
                contacts:
                    contacts?.map((c) => desantizeThenReturnContact(c)) || [],
            },
            auth: true,
        })
            .then((res: any) => {
                return sanitizeAndReturnData(res.data)
            })
            .catch((err: any) => {
                return { success: false, data: null }
            })
    }

    return {
        getBusinessById,
        getMyBusiness,
        updateMyBusiness,
    }
}

export const { getBusinessById, getMyBusiness, updateMyBusiness } =
    BusinessAPI()
