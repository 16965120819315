import {
    Typography,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    Stack,
    Checkbox,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import {
    getBusinessPrimeNotes,
    upsertBusinessPrimeNote,
} from '../../../../api/v1/business-note'
import { BusinessPrimeNote } from '../../../../utils/models/api-models'
import { setOpen as setNotification } from '../../../../slices/notification-slice'
import { useDispatch } from 'react-redux'
import { Failure, NoteAdded } from '../../../../utils/constants/messages'
import { NoteAdd } from '@mui/icons-material'
import { Controller, useForm } from 'react-hook-form'
import WorldTextField from '../../../ui-components/text-field'

interface NotesProps {
    businessId: number
}

const Notes = ({ businessId }: NotesProps): JSX.Element => {
    const dispatch = useDispatch()
    const [notes, setNotes] = useState<BusinessPrimeNote>()
    const [openAddNoteDialog, setOpenAddNoteDialog] = useState<boolean>(false)
    const [disable, setDisable] = useState(false)

    const { control, reset, getValues, watch, trigger } = useForm({
        defaultValues: {
            nameBoolean: false,
            name: '',
            note: '',
        },
    })

    useEffect(() => {
        fetchNotes(businessId)
    }, [businessId])

    const showNameForm = watch('nameBoolean')
    const fetchNotes = async (id: number) => {
        if (id == -1) {
            return
        }

        const { success, data } = await getBusinessPrimeNotes({ id: id })

        if (!success) {
            dispatch(
                setNotification({
                    open: true,
                    message: Failure,
                })
            )
            return
        }

        if (success && data) {
            setNotes(data)
        }
    }

    const createNote = async () => {
        setDisable(true)
        if (!(await trigger())) {
            setDisable(false)
            return
        }

        const { success } = await upsertBusinessPrimeNote({
            businessId: businessId,
            note: getValues('note').trim(),
            name: getValues('nameBoolean')
                ? getValues('name').trim()
                : undefined,
        })

        if (success) {
            await fetchNotes(businessId)
            setOpenAddNoteDialog(false)
            reset()
        }

        dispatch(
            setNotification({
                open: true,
                message: success ? NoteAdded : Failure,
            })
        )
        setDisable(false)
    }

    return (
        <React.Fragment>
            <Typography variant="caption">Notes</Typography>

            {notes && notes?.notes?.length > 0 ? (
                <Stack spacing={2}>
                    {notes.notes.map((note, index) => (
                        <Box key={index}>
                            <Typography variant="body1">{note.note}</Typography>
                            <Typography variant="caption">
                                by {note.name} on {note.createdAt}
                                {' UTC'}
                            </Typography>
                        </Box>
                    ))}
                </Stack>
            ) : (
                <Box>
                    <Typography>No notes have been created.</Typography>
                </Box>
            )}

            <Button
                startIcon={<NoteAdd />}
                variant="text"
                disabled={disable}
                color="primary"
                sx={{
                    textTransform: 'none',
                    mt: 2,
                }}
                onClick={() => setOpenAddNoteDialog(true)}
            >
                Add Note
            </Button>

            <Dialog
                open={openAddNoteDialog}
                fullWidth
                maxWidth="sm"
                onClose={() => setOpenAddNoteDialog(false)}
            >
                <DialogTitle
                    sx={{
                        mx: 'auto',
                        fontWeight: 'light',
                    }}
                >
                    Add Note
                </DialogTitle>
                <DialogContent>
                    <Stack spacing={2} my={2}>
                        <Controller
                            name={'note' as const}
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                                <WorldTextField
                                    label="Note"
                                    placeholder="This company has really great..."
                                    value={field.value}
                                    variant="outlined"
                                    disabled={disable}
                                    multiline
                                    minRows={3}
                                    maxRows={3}
                                    error={Boolean(error)}
                                    helperText={error?.message || ''}
                                    onChange={(e) =>
                                        field.onChange(e.target.value)
                                    }
                                />
                            )}
                            rules={{
                                required: {
                                    value: true,
                                    message: 'Note is required',
                                },
                                maxLength: {
                                    value: 2000,
                                    message:
                                        'Note must be 2000 characters or less',
                                },
                            }}
                        />
                        <Box>
                            <Controller
                                name={'nameBoolean' as const}
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <Checkbox
                                        checked={field.value}
                                        onChange={(e) => {
                                            field.onChange(e.target.checked)
                                        }}
                                    />
                                )}
                            />
                            Use Custom Name
                        </Box>
                        {showNameForm && (
                            <Controller
                                name={'name' as const}
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <WorldTextField
                                        label="Name"
                                        placeholder="Joe Smith"
                                        value={field.value}
                                        variant="outlined"
                                        disabled={disable}
                                        error={Boolean(error)}
                                        helperText={error?.message || ''}
                                        onChange={(e) =>
                                            field.onChange(e.target.value)
                                        }
                                    />
                                )}
                                rules={{
                                    maxLength: {
                                        value: 500,
                                        message:
                                            'Name must be 500 characters or less',
                                    },
                                    validate: () => {
                                        if (
                                            getValues('nameBoolean') &&
                                            getValues('name').trim() === ''
                                        ) {
                                            return 'Name is required'
                                        }
                                    },
                                }}
                            />
                        )}
                    </Stack>
                </DialogContent>
                <DialogActions
                    sx={{ justifyContent: 'space-between', mx: 2, mb: 2 }}
                >
                    <Button
                        variant="text"
                        color="secondary"
                        sx={{
                            width: 100,
                        }}
                        onClick={() => setOpenAddNoteDialog(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="text"
                        color="primary"
                        sx={{
                            width: 100,
                        }}
                        onClick={() => createNote()}
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

export default Notes
