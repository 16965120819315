import { Close } from '@mui/icons-material'
import {
    Alert,
    Box,
    Button,
    CircularProgress,
    IconButton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    Checkbox,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useFormContext, useFieldArray, Controller } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { getWatchlists as getWatchlistsAPI } from '../../../../../../api/v1/watchlist'
import { setOpen } from '../../../../../../slices/notification-slice'
import { Failure } from '../../../../../../utils/constants/messages'
import { Option } from '../../../../../../utils/models/api-models'
import WorldSearchSingleSelect from '../../../../../ui-components/search-single-select'
import { getWatchlist } from '../../../../../../api/v1/watchlist'
import WorldTextField from '../../../../../ui-components/text-field'
import { getOpportunity } from '../../../../../../api/v1/opportunity'

const defaultEmail = `Hi {{CONTACT_NAME}},

You have been invited to submit a response to a scope of work as a DBE subcontractor to potentially provide the following service(s):

{{OPP_NAME}}

{{OPP_DESC}}

The response has been requested by {{FIRST_NAME}} and is due in {{NUM_DAYS}} days. View the opportunity using the link below.

https://www.dbeworld.io/platform/scope-of-work/{{OPP_ID}}

Thank you, 
Dustin

Co-Founder
DBE World
www.dbeworld.io`

const BusinessInvitation = ({ disable }: { disable: boolean }) => {
    const dispatch = useDispatch()
    const [watchlists, setWatchlists] = useState<Option[]>([])
    const [watchlist, setWatchlist] = useState<Option>({ value: -1, label: '' })
    const [localDisable, setLocalDisable] = useState(false)
    const [openEmailTemplates, setOpenEmailTemplates] = useState(false)

    const { control, getValues, setValue } = useFormContext()
    const { fields, remove } = useFieldArray({
        name: 'invitees',
        control: control,
    })

    const metadata = getValues('metadata')

    const removeDuplicates = (
        array: Record<string, string | number>[]
    ): Record<string, string | number>[] => {
        const seen = new Set()
        return array.filter((item) => {
            const value = item['id']
            if (seen.has(value)) {
                return false
            }
            seen.add(value)
            return true
        })
    }

    const getWatchlists = async () => {
        const { data, success } = await getWatchlistsAPI()
        if (success) {
            setWatchlists(
                data?.map((d) => {
                    return {
                        value: d.id,
                        label: d.name,
                    }
                }) || []
            )
            return
        }
    }

    const importInviteesFromTemplateSow = async () => {
        setLocalDisable(true)

        const sowId = getValues('metadata').sow
        const { success, data } = await getOpportunity({ id: sowId })
        if (!success) {
            dispatch(
                setOpen({
                    open: true,
                    message: Failure,
                })
            )
            setLocalDisable(false)
            return
        }

        if (data?.bidInvitees) {
            const invitees = getValues('invitees')
            const newInvitees = data.bidInvitees.map((b) => {
                return {
                    id: b.id,
                    name: b.name,
                }
            })

            setValue(
                'invitees',
                removeDuplicates([...invitees, ...newInvitees])
            )
        }

        setLocalDisable(false)
    }

    const importWatchlist = async () => {
        setLocalDisable(true)
        if (!watchlist || (watchlist && watchlist.value === -1)) {
            setLocalDisable(false)
            return
        }

        const { success, data } = await getWatchlist({
            id: +watchlist.value,
        })

        if (success) {
            const invitees = getValues('invitees')
            const newInvitees = data?.businesses || []

            setValue(
                'invitees',
                removeDuplicates([...invitees, ...newInvitees])
            )
        } else {
            dispatch(
                setOpen({
                    open: true,
                    message: Failure,
                })
            )
        }
        setLocalDisable(false)
    }

    useEffect(() => {
        getWatchlists()
        setOpenEmailTemplates(getValues('useCustomEmailTemplate'))
    }, [])

    return (
        <React.Fragment>
            <Stack spacing={3}>
                <Typography variant="h2" fontSize={20}>
                    Import from Watchlist
                </Typography>
                <Alert severity="info">
                    You can now import multiple watchlists and cherry pick
                    businessses out of it. It will also handle any duplicate
                    businesses in the list.
                </Alert>
                <WorldSearchSingleSelect
                    placeholder="Select watchlist"
                    options={watchlists}
                    onChange={(selected) => selected && setWatchlist(selected)}
                    disable={disable}
                    value={watchlist}
                    variant="outlined"
                />
                <Button
                    onClick={async () => await importWatchlist()}
                    variant="text"
                    color="primary"
                    sx={{
                        width: 150,
                    }}
                    disabled={disable || localDisable}
                >
                    Import
                </Button>
            </Stack>
            {metadata?.copy === 1 && (
                <Stack spacing={3} mt={6}>
                    <Typography variant="h2" fontSize={20}>
                        Import Invitees from Original Scope of Work
                    </Typography>
                    <Alert severity="info">
                        This scope of work is a copy. You can import the
                        invitees of the original scope of work. It will also
                        handle any duplicate businesses in the list.
                    </Alert>
                    <Button
                        onClick={async () =>
                            await importInviteesFromTemplateSow()
                        }
                        variant="text"
                        color="primary"
                        sx={{
                            width: 150,
                        }}
                        disabled={disable || localDisable}
                    >
                        Import
                    </Button>
                </Stack>
            )}
            <Stack mt={6} spacing={3}>
                <Typography variant="h2" fontSize={20}>
                    Notifications
                </Typography>
                <Typography variant="body1" display="flex" alignItems="center">
                    <Controller
                        name={'sendEmailToMe' as const}
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <Checkbox
                                checked={field.value}
                                onChange={(e) => {
                                    field.onChange(e.target.checked)
                                }}
                            />
                        )}
                    />
                    CC All Email Communications
                </Typography>
                <Typography variant="body1" display="flex" alignItems="center">
                    <Controller
                        name={'useCustomEmailTemplate' as const}
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <Checkbox
                                checked={field.value}
                                onChange={(e) => {
                                    setOpenEmailTemplates(e.target.checked)
                                    field.onChange(e.target.checked)
                                }}
                            />
                        )}
                    />
                    Use Custom Email Template
                </Typography>
                {openEmailTemplates && (
                    <React.Fragment>
                        <Alert severity="info">
                            <Box>
                                Instead of using our email template, you can
                                write your own and utilize variables to pass
                                data in the email. Following are the variables
                                available for you to use.
                                {<br />}
                                {<br />}
                            </Box>
                            <Box>
                                {'{'}
                                {'{'}
                                {'CONTACT_NAME'}
                                {'}'}
                                {'}'} = Contact name available for the business
                                {<br />}
                                {'{'}
                                {'{'}
                                {'OPP_ID'}
                                {'}'}
                                {'}'} = Scope of work ID
                                {<br />}
                                {'{'}
                                {'{'}
                                {'OPP_NAME'}
                                {'}'}
                                {'}'} = Scope of work name
                                {<br />}
                                {'{'}
                                {'{'}
                                {'OPP_DESC'}
                                {'}'}
                                {'}'} = Scope of work description
                                {<br />}
                                {'{'}
                                {'{'}
                                {'BUSINESS_NAME'}
                                {'}'}
                                {'}'} = Business name
                                {<br />}
                                {'{'}
                                {'{'}
                                {'NUM_DAYS'}
                                {'}'}
                                {'}'} = Number of days until the opportunity is
                                due
                                {<br />}
                                {'{'}
                                {'{'}
                                {'DUE_DATE'}
                                {'}'}
                                {'}'} = Scope of work due date
                            </Box>
                        </Alert>
                        <Alert severity="warning">
                            <Box>
                                Note that all emails will automatically contain
                                the following:
                            </Box>
                            <br />
                            <Box>
                                If you have not signed up yet, please do before
                                checking the opportunity. {<br />} To register,
                                visit
                                https://www.dbeworld.io/register?accountEmail=
                                BUSINESS_EMAIL&accountType=BUSINESS. {<br />}If
                                you do not wish to receive emails from us.
                                Please reply back to this email.
                            </Box>
                        </Alert>
                        <Controller
                            name={'emailTemplate' as const}
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                                <WorldTextField
                                    placeholder={defaultEmail}
                                    value={field.value}
                                    variant="outlined"
                                    disabled={disable}
                                    minRows={5}
                                    multiline
                                    error={Boolean(error)}
                                    helperText={
                                        error?.message ||
                                        `${field.value.length}/1000`
                                    }
                                    onChange={(e) =>
                                        field.onChange(e.target.value)
                                    }
                                    fullWidth
                                />
                            )}
                            rules={{
                                required: {
                                    value: openEmailTemplates,
                                    message: 'Email is required',
                                },
                                maxLength: {
                                    value: 1000,
                                    message:
                                        'Email must be 1000 characters or less',
                                },
                                validate: () => {
                                    const {
                                        emailTemplate,
                                        useCustomEmailTemplate,
                                    } = getValues()
                                    if (
                                        emailTemplate.trim().length === 0 &&
                                        useCustomEmailTemplate
                                    ) {
                                        return 'Must include email template'
                                    }
                                },
                            }}
                        />
                    </React.Fragment>
                )}
            </Stack>
            <Typography variant="h2" fontSize={20} mt={8} mb={3}>
                Invitees
            </Typography>
            {localDisable ? (
                <Box
                    display="flex"
                    height="100%"
                    justifyContent="center"
                    alignItems="center"
                >
                    <CircularProgress color="inherit" />
                </Box>
            ) : (
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ fontWeight: 'bold' }}>
                                Business ID
                            </TableCell>
                            <TableCell sx={{ fontWeight: 'bold' }}>
                                Name
                            </TableCell>
                            <TableCell
                                align="right"
                                sx={{ fontWeight: 'bold' }}
                            ></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {fields.map((f, index) => (
                            <TableRow key={f.id}>
                                <TableCell sx={{ width: 100 }}>
                                    <Controller
                                        name={`invitees.${index}.id` as const}
                                        control={control}
                                        render={({ field }) => (
                                            <Typography variant="body1">
                                                <a
                                                    href={`/platform/business/${field.value}`}
                                                >
                                                    {field.value}
                                                </a>
                                            </Typography>
                                        )}
                                    />
                                </TableCell>
                                <TableCell>
                                    <Controller
                                        name={`invitees.${index}.name` as const}
                                        control={control}
                                        render={({ field }) => (
                                            <Typography variant="body1">
                                                {field.value}
                                            </Typography>
                                        )}
                                    />
                                </TableCell>
                                <TableCell align="right" width={50}>
                                    <IconButton
                                        sx={{ ml: 1, borderRadius: 0 }}
                                        onClick={() => remove(index)}
                                    >
                                        <Close />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            )}
        </React.Fragment>
    )
}

export default BusinessInvitation
