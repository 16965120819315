import { Box, Button, Divider, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useForm, Controller, set } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import Table from './table'

import { getMyBusiness } from '../../../api/v1/business'
import { upsertNaics, deleteNaics } from '../../../api/v1/naics'

import WorldTextField from '../../ui-components/text-field'

import { Naics as NaicsType } from '../../../utils/models/api-models'

import {
    Failure,
    CreateNaics,
    RemoveNaics,
} from '../../../utils/constants/messages'
import { setOpen } from '../../../slices/notification-slice'

const Naics = ({
    fetchBusiness,
    naics,
}: {
    fetchBusiness: () => Promise<void>
    naics: NaicsType[]
}) => {
    const dispatch = useDispatch()
    const { control, reset, getValues, trigger } = useForm({
        defaultValues: {
            naicsCode: '',
        },
    })

    const [disable, setDisable] = useState(false)

    const handleSubmit = async () => {
        setDisable(true)
        if (!(await trigger())) {
            setDisable(false)
            return
        }
        const { naicsCode } = getValues()
        const { success } = await upsertNaics({
            code: naicsCode.trim(),
        })
        reset()
        dispatch(
            setOpen({
                open: true,
                message: success ? CreateNaics : Failure,
            })
        )
        await fetchBusiness()
        setDisable(false)
    }

    const handleDelete = async (id: number) => {
        setDisable(true)
        const { success } = await deleteNaics({ id: id })
        dispatch(
            setOpen({
                open: true,
                message: success ? RemoveNaics : Failure,
            })
        )
        await fetchBusiness()
        setDisable(false)
    }

    return (
        <>
            <Typography variant="h6" mt={2}>
                Add a new NAICS code
            </Typography>
            <Typography variant="caption" sx={{ width: 150 }}>
                Let contractors know about all of your business's NAICS codes.
            </Typography>
            <Box display="flex" justifyContent="center" mt={6}>
                <Typography variant="subtitle2" width={200} sx={{ mr: 4 }}>
                    NAICS Code
                </Typography>
                <Controller
                    name={'naicsCode' as const}
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <WorldTextField
                            placeholder="Enter code"
                            value={field.value}
                            variant="outlined"
                            disabled={disable}
                            type="number"
                            error={Boolean(error)}
                            helperText={error?.message || ''}
                            onChange={(e) => field.onChange(e.target.value)}
                            sx={{
                                width: 400,
                            }}
                        />
                    )}
                    rules={{
                        required: {
                            value: true,
                            message: 'NAICS code is required',
                        },
                        maxLength: {
                            value: 200,
                            message:
                                'NAICS code must be 200 characters or less',
                        },
                        pattern: {
                            value: /^[0-9]*$/,
                            message: 'Only numbers are allowed',
                        },
                    }}
                />
            </Box>
            <Box display="flex" justifyContent="center" mt={6}>
                <Button
                    variant="text"
                    color="primary"
                    sx={{
                        px: 2,
                    }}
                    disabled={disable}
                    onClick={() => handleSubmit()}
                >
                    Add NAICS Code
                </Button>
            </Box>
            <Divider variant="middle" sx={{ mt: 6, mb: 2 }} />
            <Typography variant="h6">Existing NAICS codes</Typography>
            <Typography variant="caption" sx={{ width: 150 }}>
                View and delete your existing NAICS codes.
            </Typography>
            <Box mt={4}>
                {naics.length === 0 ? (
                    <Typography variant="subtitle2">
                        There are no existing NAICS codes.
                    </Typography>
                ) : (
                    <Table
                        headers={['Code']}
                        values={['code']}
                        rows={naics}
                        onDelete={handleDelete}
                    />
                )}
            </Box>
        </>
    )
}

export default Naics
