import { createTheme } from '@mui/material/styles'

const theme = createTheme({
    typography: {
        fontFamily: `Helvetica`,
        button: {
            fontFamily: `Helvetica`,
            textTransform: 'none',
            fontWeight: 'bold',
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                textPrimary: {
                    color: '#fafbff',
                    backgroundColor: '#262d50',
                    border: 1,
                    borderColor: '#262d50',
                    borderWidth: 1,
                    '&:hover': {
                        backgroundColor: '#000000',
                        border: 1,
                        borderColor: '#262d50',
                        borderWidth: 1,
                    },
                },
                textSecondary: {
                    background: '#fafbff',
                    color: '#262d50',
                    border: 1,
                    borderColor: '#262d50',
                    borderWidth: 1,
                    '&:hover': {
                        backgroundColor: '#ffffff',
                    },
                },
            },
        },
        MuiStepper: {
            styleOverrides: {
                root: {
                    '& .MuiSvgIcon-root.Mui-active': {
                        color: '#262d50',
                    },
                    '& .MuiSvgIcon-root.Mui-completed': {
                        color: '#00D100',
                    },
                },
            },
        },
        MuiCheckbox: {
            defaultProps: {
                disableFocusRipple: true,
                disableRipple: true,
                style: { color: '#262d50' },
            },
            styleOverrides: {
                root: {},
            },
        },
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true,
            },
            styleOverrides: {
                root: {
                    ':disabled': {
                        color: 'white !important',
                        backgroundColor: 'rgba(0, 0, 0, 0.38)',
                    },
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    backgroundColor: 'white',
                    disableAnimation: true,
                    '&.Mui-disabled': {
                        backgroundColor: '#e0e0e0',
                    },
                },
            },
        },
        MuiSwitch: {
            styleOverrides: {
                switchBase: {},
                colorPrimary: {
                    '&.Mui-checked': {
                        // Controls checked color for the thumb
                        color: '#262d50',
                    },
                },
                track: {
                    '.Mui-checked.Mui-checked + &': {
                        // Controls checked color for the track
                        opacity: 0.7,
                        backgroundColor: '#262d50',
                    },
                },
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    '& .MuiFilledInput-root': {
                        '&::before': {
                            borderBottom: '0 !important',
                        },
                        '&::after': {
                            borderBottom: '0',
                        },
                        '&.Mui-disabled:hover': {
                            backgroundColor: '#e0e0e0',
                        },
                    },
                },
                popper: {
                    '& .MuiAutocomplete-listbox': {
                        '& .MuiAutocomplete-option[aria-selected="true"]': {
                            opacity: 0.7,
                            backgroundColor: '#262d50 !important',
                            color: 'white',
                            '&:hover': {
                                opacity: 0.7,
                                backgroundColor: '#262d50 !important',
                                color: 'white',
                            },
                        },
                    },
                },
                tag: {
                    marginTop: 8,
                },
            },
        },
        MuiTabs: {
            defaultProps: {
                TabIndicatorProps: {
                    style: {
                        backgroundColor: '#262d50',
                    },
                },
            },
            styleOverrides: {
                root: {
                    '& .Mui-selected': {
                        color: 'black !important',
                    },
                },
            },
        },
    },
})

export default theme
