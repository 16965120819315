import { Grid, Link } from '@mui/material'

const Footer = () => (
    <Grid
        container
        bgcolor="white"
        py={5}
        justifyContent="center"
        sx={{
            boxShadow:
                'rgb(0 0 0 / 5%) 0px 6px 24px 0px, rgb(0 0 0 / 8%) 1px 0px 0px 0px',
        }}
    >
        <Grid item sx={{ mx: 1 }}>
            <Link
                target="_blank"
                href="https://app.termly.io/document/terms-of-use-for-website/48203150-6e12-4c6d-aadc-fa3a8d4e3657"
                sx={{
                    color: 'black',
                    textDecoration: 'none',
                }}
            >
                Terms & Conditions
            </Link>
        </Grid>
        <Grid item sx={{ mx: 1 }}>
            <Link
                target="_blank"
                href="https://app.termly.io/document/privacy-policy/3a6a9bee-2fb7-41a7-b86d-a2ebc57d1798"
                sx={{
                    color: 'black',
                    textDecoration: 'none',
                }}
            >
                Privacy
            </Link>
        </Grid>
    </Grid>
)

export default Footer
