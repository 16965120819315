import React, { useState } from 'react'
import { Grid, Typography, Button, Link, Box } from '@mui/material'
import activateAccount from '../../../api/v1/user/activate-user'
import { Controller, useForm } from 'react-hook-form'
import WorldTextField from '../../ui-components/text-field'
import WorldSnackbar from '../../ui-components/snackbar'
import { Failure } from '../../../utils/constants/messages'

const messages = {
    success: 'Your account has been activated. Please log in.',
    failure: Failure,
}

interface BusinessActivateProps {
    uuid: string
    token: string
    businessName: string
    email: string
    contactName: string
    phoneNumber: string
    website: string
    profile: string
}

const BusinessActivate = ({
    uuid,
    token,
    businessName,
    email,
    contactName,
    phoneNumber,
    website,
    profile,
}: BusinessActivateProps): JSX.Element => {
    const [messageType, setMessageType] = useState<'success' | 'failure'>(
        'success'
    )
    const [success, setSuccess] = useState(false)
    const [openSnackbar, setOpenSnackbar] = useState(false)
    const [disable, setDisabled] = useState(false)

    const { getValues, control, trigger } = useForm({
        defaultValues: {
            businessName: businessName,
            email: email,
            contactName: contactName,
            website: website,
            phoneNumber: phoneNumber,
            profile: profile,
        },
    })

    const handleBusinessActivation = async () => {
        setDisabled(true)
        if (await trigger()) {
            const { success } = await activateAccount({
                uuid,
                token,
                ...getValues(),
            })
            if (success) {
                setSuccess(true)
            }
            setOpenSnackbar(true)
            setMessageType(success ? 'success' : 'failure')
        }
        setDisabled(false)
    }

    return (
        <>
            <Grid
                container
                justifyContent="flex-start"
                px={10}
                mt={10}
                direction="column"
                alignItems="center"
                textAlign="center"
            >
                <Grid item>
                    <Typography
                        variant="h5"
                        color="#262d50"
                        fontWeight="bold"
                        fontFamily='"Montserrat", sans-serif'
                    >
                        dbeWorld
                    </Typography>
                </Grid>
                <Grid item mt={4}>
                    <Typography
                        variant="h3"
                        fontWeight="bold"
                        fontFamily='"Inter", sans-serif'
                    >
                        Activate Business
                    </Typography>
                </Grid>
                <Grid item mt={4}>
                    {!success && (
                        <Box maxWidth={500}>
                            <Typography variant="h6" color="#7f7f7f">
                                Please check if the following information is
                                correct.
                            </Typography>
                            <Controller
                                name={'email' as const}
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <WorldTextField
                                        placeholder="Enter email"
                                        label="Business Contact Email"
                                        fullWidth
                                        value={field.value}
                                        onChange={(
                                            e: React.BaseSyntheticEvent
                                        ) => field.onChange(e.target.value)}
                                        error={Boolean(error)}
                                        helperText={error?.message || ''}
                                    />
                                )}
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'Email is required',
                                    },
                                    maxLength: {
                                        value: 60,
                                        message: `Email must be 60 characters or less`,
                                    },
                                    pattern: {
                                        value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                                        message: 'Must be an email',
                                    },
                                }}
                            />
                            <Controller
                                name={'businessName' as const}
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <WorldTextField
                                        placeholder="Enter business name"
                                        label="Business Name"
                                        value={field.value}
                                        fullWidth
                                        onChange={(
                                            e: React.BaseSyntheticEvent
                                        ) => field.onChange(e.target.value)}
                                        error={Boolean(error)}
                                        helperText={error?.message || ''}
                                        sx={{ mt: 2 }}
                                    />
                                )}
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'Business name is required',
                                    },
                                    maxLength: {
                                        value: 200,
                                        message: `Business name must be 200 characters or less`,
                                    },
                                }}
                            />
                            <Controller
                                name={'contactName' as const}
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <WorldTextField
                                        placeholder="Enter contact name"
                                        label="Contact Name"
                                        value={field.value}
                                        fullWidth
                                        onChange={(
                                            e: React.BaseSyntheticEvent
                                        ) => field.onChange(e.target.value)}
                                        error={Boolean(error)}
                                        helperText={error?.message || ''}
                                        sx={{ mt: 2 }}
                                    />
                                )}
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'Contact name is required',
                                    },
                                    maxLength: {
                                        value: 200,
                                        message: `Contact name must be 200 characters or less`,
                                    },
                                }}
                            />
                            <Controller
                                name={'website' as const}
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <WorldTextField
                                        placeholder="Enter website"
                                        label="Website"
                                        fullWidth
                                        value={field.value}
                                        onChange={(
                                            e: React.BaseSyntheticEvent
                                        ) => field.onChange(e.target.value)}
                                        error={Boolean(error)}
                                        helperText={error?.message || ''}
                                        sx={{ mt: 2 }}
                                    />
                                )}
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'Website is required',
                                    },
                                    maxLength: {
                                        value: 200,
                                        message: `Website must be 200 characters or less`,
                                    },
                                }}
                            />
                            <Controller
                                name={'phoneNumber' as const}
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <WorldTextField
                                        placeholder="Enter phone number"
                                        label="Phone Number"
                                        value={field.value}
                                        fullWidth
                                        onChange={(
                                            e: React.BaseSyntheticEvent
                                        ) => field.onChange(e.target.value)}
                                        error={Boolean(error)}
                                        helperText={error?.message || ''}
                                        sx={{ mt: 2 }}
                                    />
                                )}
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'Phone number is required',
                                    },
                                    pattern: {
                                        value: /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
                                        message: 'Enter a valid phone number',
                                    },
                                }}
                            />
                            <Controller
                                name={'profile' as const}
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <WorldTextField
                                        placeholder="Enter profile"
                                        label="Profile"
                                        multiline
                                        minRows={4}
                                        maxRows={8}
                                        fullWidth
                                        value={field.value}
                                        onChange={(
                                            e: React.BaseSyntheticEvent
                                        ) => field.onChange(e.target.value)}
                                        error={Boolean(error)}
                                        helperText={error?.message || ''}
                                        sx={{ mt: 2 }}
                                    />
                                )}
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'Profile is required',
                                    },
                                    maxLength: {
                                        value: 100,
                                        message: `Profile must be 1000 characters or less`,
                                    },
                                }}
                            />
                            <Button
                                sx={{
                                    mt: 3,
                                    width: '100%',
                                    px: 4,
                                }}
                                variant="text"
                                color="primary"
                                onClick={() => handleBusinessActivation()}
                                disabled={disable}
                            >
                                Save & Activate
                            </Button>
                        </Box>
                    )}
                </Grid>
            </Grid>
            <Grid
                container
                justifyContent="center"
                my={3}
                spacing={2}
                sx={{ fontSize: 12 }}
            >
                <Grid item>
                    <Link href="/register" sx={linkStyling}>
                        Register
                    </Link>
                </Grid>
                <Grid item>|</Grid>
                <Grid item>
                    <Link href="/login" sx={linkStyling}>
                        Login
                    </Link>
                </Grid>
                <Grid item>|</Grid>
                <Grid item>
                    <Link href="/resend-activation" sx={linkStyling}>
                        Resend Activation
                    </Link>
                </Grid>
                <Grid item>|</Grid>
                <Grid item>
                    <Link href="/reset-password" sx={linkStyling}>
                        Reset Password
                    </Link>
                </Grid>
            </Grid>
            <WorldSnackbar
                open={openSnackbar}
                setOpen={setOpenSnackbar}
                message={<Box>{messages[messageType]}</Box>}
            />
        </>
    )
}

const linkStyling = {
    color: 'black',
    textDecoration: 'none',
    '&:hover': {
        textDecoration: 'underline',
        color: '#262d50',
    },
}

export default BusinessActivate
