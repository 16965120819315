import {
    AnonBusinessRaw,
    AnonBusiness,
    Paginated,
    PaginatedRaw,
    ContactRaw,
    Contact,
} from '../../../utils/models/api-models'

type FindBusiness = Paginated<AnonBusiness>
type FindBusinessRaw = PaginatedRaw<AnonBusinessRaw>

export const sanitizeBusinessDataFromAPI = (
    value: AnonBusinessRaw
): AnonBusiness => {
    return {
        id: value.id,
        name: value.name,
        profile: value.profile,
        isVerified: value.is_verified,
        website: value.website,
        headcount: value.headcount,
        locations: value.locations,
        contacts: value.contacts.map((c: ContactRaw) =>
            sanitizeContactFromAPI(c)
        ),
        emailMatch: value.email_match,
    }
}

const sanitizeContactFromAPI = (value: ContactRaw): Contact => {
    return {
        name: value.name,
        email: value.email,
        phoneNumber: value.phone_number,
        type: value.type,
    }
}

export const sanitizeFindBusinessFromAPI = (
    value: FindBusinessRaw
): FindBusiness => {
    return {
        numPages: value.num_pages,
        count: value.count,
        data:
            (value.data &&
                value.data.map((b) => sanitizeBusinessDataFromAPI(b))) ||
            [],
    }
}
