import {
    Experience,
    ExperienceRaw,
    Response,
    StatusResponse,
} from '../../../utils/models/api-models'
import callAPI from '../api-base'

type BusinessExperienceResponse = Response<Experience>

const BusinessExperienceAPI = () => {
    const sanitizeThenReturnExperience = (
        experience: ExperienceRaw
    ): Experience => {
        return {
            id: experience.id,
            governmentAgency: experience.government_agency,
            primeContractor: experience.prime_contractor,
            workedDirectlyWithGovernment:
                experience.worked_directly_with_government,
            service: experience.service,
            startDate: experience.start_date,
            endDate: experience.end_date,
            active: experience.active,
        }
    }

    const sanitizeThenReturnData = (
        responseData: any
    ): BusinessExperienceResponse => {
        const { success, data } = responseData
        return {
            success: success,
            data: data !== null ? sanitizeThenReturnExperience(data) : null,
        }
    }

    const upsertBusinessExperience = ({
        id,
        governmentAgency,
        primeContractor,
        workedDirectlyWithGovernment,
        service,
        startDate,
        endDate,
        active,
    }: {
        id?: number
        governmentAgency: string
        primeContractor?: string
        workedDirectlyWithGovernment?: boolean
        service?: string
        startDate: string
        endDate?: string
        active?: boolean
    }): Promise<BusinessExperienceResponse> => {
        return callAPI({
            url: 'business-experience/',
            type: 'post',
            body: {
                id: id,
                government_agency: governmentAgency,
                prime_contractor: primeContractor,
                worked_directly_with_government: workedDirectlyWithGovernment,
                service: service,
                start_date: startDate,
                end_date: endDate,
                active: active,
            },
            auth: true,
        })
            .then((res: any) => {
                return sanitizeThenReturnData(res.data)
            })
            .catch((err: any) => {
                return {
                    success: false,
                    data: null,
                }
            })
    }

    const deleteBusinessExperience = ({
        id,
    }: {
        id: number
    }): Promise<StatusResponse> => {
        return callAPI({
            url: 'business-experience/',
            type: 'delete',
            body: { id: id },
            auth: true,
        })
            .then((res: any) => {
                return { success: res.data.success }
            })
            .catch((err: any) => {
                return { success: false }
            })
    }

    return {
        upsertBusinessExperience,
        deleteBusinessExperience,
        sanitizeThenReturnExperience,
    }
}

export const {
    upsertBusinessExperience,
    deleteBusinessExperience,
    sanitizeThenReturnExperience,
} = BusinessExperienceAPI()
