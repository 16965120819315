import { Typography, Box, Stack } from '@mui/material'
import { useFormContext, useFieldArray } from 'react-hook-form'
import Requirement from './requirement'
import WhiteContainer from '../../../../../ui-components/white-container'
import { Opportunity } from '../../../../../../utils/models/api-models'
import OpportunityInfo from './opportunity'

interface RequirementsProps {
    disable: boolean
    type: 0 | 1
    opportunity: Opportunity
}

const Requirements = ({ disable, type, opportunity }: RequirementsProps) => {
    const { control } = useFormContext()
    const requirementType = type === 0 ? 'technicalReqs' : 'commercialReqs'

    const { fields } = useFieldArray({
        name: requirementType,
        control: control,
    })

    // if (fields.length <= 0) {
    //     return <Typography variant="body1">No Requirements</Typography>
    // }

    return (
        <Box component={'span'} display="flex">
            <WhiteContainer sx={{ width: '30%', mr: '25px' }}>
                <OpportunityInfo opportunity={opportunity} />
            </WhiteContainer>
            <WhiteContainer sx={{ width: '60%', ml: '25px' }}>
                {fields.length <= 0 ? (
                    <Typography variant="body1">No Requirements</Typography>
                ) : (
                    <Box component={'span'}>
                        <Typography
                            variant="h2"
                            fontSize={16}
                            fontWeight={300}
                            lineHeight={1.7}
                        >
                            {type === 0
                                ? 'Technical Requirements'
                                : 'Commercial Requirements'}
                        </Typography>
                        <Stack spacing={4}>
                            {fields.map((f, index) => (
                                <Requirement
                                    key={f.id}
                                    reqName={requirementType}
                                    index={index}
                                    disable={disable}
                                />
                            ))}
                        </Stack>
                    </Box>
                )}
            </WhiteContainer>
        </Box>
    )
}

export default Requirements
