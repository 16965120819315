import Page from '../../ui-components/page'
import WhiteContainer from '../../ui-components/white-container'
import AccountsTable from './account-table'

const Super = () => {
    return (
        <Page header="Super">
            <WhiteContainer>
                <AccountsTable />
            </WhiteContainer>
        </Page>
    )
}

export default Super
