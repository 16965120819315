import callAPI from '../api-base'

import {
    Response,
    Paginated,
    AnonBusiness,
} from '../../../utils/models/api-models'
import { sanitizeFindBusinessFromAPI } from './util'

type FindBusinessResponse = Response<Paginated<AnonBusiness>>

interface searchAPIParams {
    city: string[]
    state: string[]
    naics: string[]
    headcount: string[]
    certification: string[]
    certifyingBody: string[]
    keywords: string[]
    businessName: string
    verified: boolean
    website: boolean
    page: number
    locationRadius?: number
}

export const searchAPI = ({
    city,
    state,
    naics,
    headcount,
    certification,
    certifyingBody,
    keywords,
    businessName,
    verified,
    website,
    page,
    locationRadius,
}: searchAPIParams): FindBusinessResponse | Promise<FindBusinessResponse> => {
    return callAPI({
        url: 'search/',
        type: 'post',
        body: {
            city: city,
            state: state,
            naics: naics,
            headcount: headcount,
            certification: certification,
            certifying_body: certifyingBody,
            keywords: keywords,
            business_name: businessName,
            verified: verified,
            has_website: website,
            page_number: page,
            radius: locationRadius,
        },
        auth: true,
    })
        .then((res: any) => {
            const { success } = res.data
            return {
                success: success,
                data: sanitizeFindBusinessFromAPI(res.data.data),
            }
        })
        .catch((err: any) => {
            return { success: false, data: null }
        })
}

export default searchAPI
