import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Stack,
    Stepper,
    Step,
    StepLabel,
    Box,
    Button,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import WorldTextField from '../../../../../ui-components/text-field'
import { Controller, useForm } from 'react-hook-form'
import { Option } from '../../../../../../utils/models/api-models'
import { getOpportunityGroupsFilter } from '../../../../../../api/v1/opportunity-group'
import WorldSearchSingleSelect from '../../../../../ui-components/search-single-select'
import { setOpen as setNotification } from '../../../../../../slices/notification-slice'
import { useDispatch } from 'react-redux'
import {
    CopyOpportunity,
    Failure,
} from '../../../../../../utils/constants/messages'
import {
    copyOpportunity,
    getOpportunity,
    getOpportunitiesFilter,
} from '../../../../../../api/v1/opportunity'
import { useNavigate } from 'react-router-dom'

const CopySow = ({
    open,
    setOpen,
    existingSowId,
}: {
    open: boolean
    setOpen: (val: boolean) => void
    existingSowId: number | null
}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [disabled, setDisabled] = useState<boolean>(false)
    const [opportunities, setOpportunities] = useState<Option[]>([])
    const [scopeOfWorks, setScopeOfWorks] = useState<Option[]>([])
    const [stepper, setStepper] = useState<number>(0)

    const { getValues, control, trigger, setValue, reset } = useForm({
        defaultValues: {
            id: -1,
            title: '',
            sow: -1,
            opportunity: -1,
        },
    })

    const setClose = () => {
        setOpen(false)
        reset({
            opportunity: -1,
            sow: -1,
            title: '',
            id: -1,
        })
    }

    const getOpportunities = async () => {
        setDisabled(true)
        const { data, success } = await getOpportunityGroupsFilter()
        if (success) {
            setOpportunities(data || [])
            setDisabled(false)
            return
        }
        dispatch(
            setNotification({
                open: true,
                message: Failure,
            })
        )
        setDisabled(false)
    }

    const getScopeOfWorks = async () => {
        setDisabled(true)
        const { data, success } = await getOpportunitiesFilter({})
        if (success) {
            setScopeOfWorks(data || [])
            setDisabled(false)
            return
        }
        dispatch(
            setNotification({
                open: true,
                message: Failure,
            })
        )
        setDisabled(false)
    }

    const addSowInfo = async () => {
        setDisabled(true)
        const isValid = await trigger(['sow'])
        if (isValid) {
            const sowId = getValues('sow')
            const sow = await getOpportunity({
                id: sowId,
            })
            if (sow.success && sow.data) {
                setValue('title', sow.data.title + ' (Copy)')
                setValue('opportunity', sow.data.opportunityGroupId)
                setDisabled(false)
                setStepper(1)
                return
            }
            setDisabled(false)
            dispatch(
                setNotification({
                    open: true,
                    message: Failure,
                })
            )
        }
    }

    const copySow = async () => {
        setDisabled(true)
        const isValid = await trigger(['title', 'opportunity'])
        if (!isValid) {
            setDisabled(false)
            return
        }
        const { title, sow, opportunity } = getValues()

        const { success, data } = await copyOpportunity({
            title,
            existingOpportunityId: sow,
            opportunityGroupId: opportunity,
        })

        if (success && data !== null) {
            setDisabled(false)
            setClose()
            dispatch(
                setNotification({
                    open: true,
                    message: CopyOpportunity,
                })
            )
            navigate(`/platform/scope-of-work/edit?id=${data.id}`)
            return
        }
        setDisabled(false)
        dispatch(
            setNotification({
                open: true,
                message: Failure,
            })
        )
        return
    }

    useEffect(() => {
        getOpportunities()
    }, [])

    useEffect(() => {
        getScopeOfWorks()
    }, [])

    useEffect(() => {
        if (existingSowId) {
            setValue('sow', existingSowId)
            addSowInfo()
            setStepper(1)
        }
    }, [existingSowId])

    const opportunityId =
        getValues('opportunity') === -1 ? null : getValues('opportunity')
    const selectedOpportunity =
        opportunities.length > 0
            ? opportunities.filter((i) => i.value === opportunityId)[0]
            : { value: opportunityId, label: '' }

    const sowId = getValues('sow') === -1 ? null : getValues('sow')
    const selectedSow =
        scopeOfWorks.length > 0
            ? scopeOfWorks.filter((i) => i.value === sowId)[0]
            : { value: sowId, label: '' }

    const Overview = () => (
        <Stack my={1} spacing={2}>
            <Controller
                name={'title' as const}
                control={control}
                render={({ field, fieldState: { error } }) => (
                    <WorldTextField
                        placeholder="Scope of Work"
                        label="Title"
                        fullWidth
                        disabled={disabled}
                        value={field.value}
                        onChange={(e: React.BaseSyntheticEvent) =>
                            field.onChange(e.target.value)
                        }
                        error={Boolean(error)}
                        helperText={error?.message || ''}
                    />
                )}
                rules={{
                    required: {
                        value: true,
                        message: 'Title is required',
                    },
                    maxLength: {
                        value: 150,
                        message: `Title must be 200 characters or less`,
                    },
                    validate: () => {
                        if (getValues('title').trim() === '') {
                            return 'Title is required'
                        }
                    },
                }}
            />
            <Controller
                name={'opportunity' as const}
                control={control}
                render={({ field, fieldState: { error } }) => (
                    <WorldSearchSingleSelect
                        placeholder="Select opportunity"
                        options={opportunities}
                        onChange={(selected) => {
                            field.onChange(selected?.value)
                        }}
                        disable={disabled}
                        label="Opportunity"
                        error={error ? Boolean(error) : false}
                        errorMessage={error?.message || ''}
                        value={
                            opportunities.filter(
                                (i) => i.value === field.value
                            )[0] || selectedOpportunity
                        }
                        variant="outlined"
                    />
                )}
                rules={{
                    required: {
                        value: true,
                        message: 'Opportunity is required',
                    },
                    validate: () => {
                        const opportunity = getValues('opportunity')
                        if (opportunity === -1) {
                            return 'Opportunity is required'
                        }
                    },
                }}
            />
        </Stack>
    )

    const SowSelect = () => (
        <Box my={1}>
            <Controller
                name={'sow' as const}
                control={control}
                render={({ field, fieldState: { error } }) => (
                    <WorldSearchSingleSelect
                        placeholder="Select scope of work"
                        options={scopeOfWorks}
                        onChange={(selected) => {
                            field.onChange(selected?.value)
                        }}
                        disable={disabled}
                        label="Scope of Work"
                        error={error ? Boolean(error) : false}
                        errorMessage={error?.message || ''}
                        value={
                            scopeOfWorks.filter(
                                (i) => i.value === field.value
                            )[0] || selectedSow
                        }
                        variant="outlined"
                    />
                )}
                rules={{
                    required: {
                        value: true,
                        message: 'Scope of Work is required',
                    },
                    validate: () => {
                        const sow = getValues('sow')
                        if (sow === -1) {
                            return 'Scope of Work is required'
                        }
                    },
                }}
            />
        </Box>
    )

    const forms: Record<number, JSX.Element> = {
        0: <SowSelect />,
        1: <Overview />,
    }

    const actionButtons: Record<number, JSX.Element> = {
        0: (
            <Box sx={{ justifyContent: 'space-between', mx: 2, mb: 2 }}>
                <Button
                    variant="text"
                    color="secondary"
                    sx={{
                        width: 100,
                    }}
                    onClick={() => {
                        setClose()
                    }}
                >
                    Cancel
                </Button>
                <Button
                    variant="text"
                    color="primary"
                    sx={{
                        width: 100,
                    }}
                    onClick={async () => {
                        await addSowInfo()
                    }}
                >
                    Next
                </Button>
            </Box>
        ),
        1: (
            <Box sx={{ justifyContent: 'space-between', mx: 2, mb: 2 }}>
                <Button
                    variant="text"
                    color="secondary"
                    sx={{
                        width: 100,
                    }}
                    onClick={() => {
                        setStepper(0)
                    }}
                >
                    Back
                </Button>
                <Button
                    variant="text"
                    color="primary"
                    sx={{
                        width: 100,
                    }}
                    onClick={async () => await copySow()}
                >
                    Create
                </Button>
            </Box>
        ),
    }

    return (
        <React.Fragment>
            <Dialog
                fullWidth
                key="copy-sow"
                open={open}
                onClose={() => setClose()}
            >
                <DialogTitle sx={{ fontWeight: 'light' }}>
                    Copy Scope of Work
                </DialogTitle>
                <DialogContent>
                    <Stepper activeStep={stepper} sx={{ mb: 4 }}>
                        <Step key="sow-select">
                            <StepLabel>Scope of Work</StepLabel>
                        </Step>
                        <Step key="sow-info">
                            <StepLabel>Overview</StepLabel>
                        </Step>
                    </Stepper>
                    {forms[stepper]}
                </DialogContent>
                <DialogActions>{actionButtons[stepper]}</DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

export default CopySow
