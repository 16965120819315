import callAPI from '../api-base'

import {
    Response,
    BusinessPrimeNote,
    BusinessPrimeNoteRaw,
} from '../../../utils/models/api-models'

type BusinessPrimeNoteResponse = Response<BusinessPrimeNote>

const BusinessPrimeNoteAPI = () => {
    const sanitizeBusinessPrimeNote = (
        businessNote: BusinessPrimeNoteRaw
    ): BusinessPrimeNote => {
        const { id, business, created_at, notes, last_modified_at } =
            businessNote

        return {
            id: id,
            createdAt: created_at,
            lastModifiedAt: last_modified_at,
            business: {
                id: business.id,
                name: business.name,
                contactName: business.contact_name,
                phoneNumber: business.phone_number,
                email: business.email,
            },
            notes: notes.map((noteInfo) => {
                return {
                    createdAt: noteInfo.created_at,
                    name: noteInfo.name,
                    note: noteInfo.note,
                }
            }),
        }
    }

    const getBusinessPrimeNotes = ({
        id,
    }: {
        id: number
    }): BusinessPrimeNoteResponse | Promise<BusinessPrimeNoteResponse> => {
        return callAPI({
            url: 'business-note/?id=' + id.toString(),
            type: 'get',
            body: {},
            auth: true,
        })
            .then((res: any) => {
                const { success, data } = res.data
                return {
                    success: success,
                    data: data ? sanitizeBusinessPrimeNote(data) : null,
                }
            })
            .catch((err: any) => {
                return {
                    success: false,
                    data: null,
                }
            })
    }

    const upsertBusinessPrimeNote = ({
        businessId,
        name,
        note,
    }: {
        businessId: number
        name?: string
        note: string
    }): BusinessPrimeNoteResponse | Promise<BusinessPrimeNoteResponse> => {
        return callAPI({
            url: 'business-note/',
            type: 'post',
            body: {
                business: businessId,
                note: note,
                name,
            },
            auth: true,
        })
            .then((res: any) => {
                const { success, data } = res.data
                const sanitizedData = sanitizeBusinessPrimeNote(data)
                return { success: success, data: sanitizedData }
            })
            .catch((err: any) => {
                return {
                    success: false,
                    data: null,
                }
            })
    }

    return {
        getBusinessPrimeNotes,
        upsertBusinessPrimeNote,
    }
}

export const { getBusinessPrimeNotes, upsertBusinessPrimeNote } =
    BusinessPrimeNoteAPI()
