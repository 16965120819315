import {
    FilterResponse,
    MyInvitedOpportunities,
    MyInvitedOpportunitiesRaw,
    Opportunity,
    OpportunityRaw,
    Paginated,
    Reqs,
    Response,
    StatusResponse,
} from '../../../utils/models/api-models'
import callAPI from '../api-base'

type OpportunityResponse = Response<Opportunity>

const OpportunityAPI = () => {
    const sanitizeOpportunity = (value: OpportunityRaw): Opportunity => {
        return {
            id: value.id,
            title: value.title,
            description: value.description,
            endDate: value.end_date,
            public: value.public,
            pricing: value.pricing,
            bidInvitees: value.bid_invitees.map((invitee) => {
                return {
                    id: invitee.id,
                    name: invitee.name,
                    email: invitee.email,
                    phoneNumber: invitee.phone_number,
                    contactName: invitee.contact_name,
                }
            }),
            bidInvitationType: value.bid_invitation_type,
            awardedTo: value.awarded_to,
            opportunityGroupId: value.opportunity_group_id,
            open: value.open,
            status: value.status,
            totalResponses: value.total_responses,
            technicalReqs: value.technical_reqs,
            commercialReqs: value.commercial_reqs,
            metadata: value.metadata,
            draft: value.draft,
            notificationTemplates: value.notification_templates,
            useCustomEmailTemplate: value.use_custom_email_template,
            createdAt: value.created_at,
            lastModifiedAt: value.last_modified_at,
        }
    }

    const sanitizeMyOpportunityWithResponse = (
        value: MyInvitedOpportunitiesRaw
    ): MyInvitedOpportunities => {
        return {
            id: value.id,
            title: value.title,
            opportunityStatus: value.opportunity_status,
            responseStatus: value.response_status,
            responseDraft: value.response_draft,
            responseId: value.response_id,
            endDate: value.end_date,
            contactName: value.contact_name,
        }
    }

    const copyOpportunity = ({
        title,
        existingOpportunityId,
        opportunityGroupId,
    }: {
        title: string
        existingOpportunityId: number
        opportunityGroupId: number
    }): OpportunityResponse | Promise<OpportunityResponse> => {
        return callAPI({
            url: 'opportunity/copy/',
            type: 'post',
            body: {
                title,
                opportunity: existingOpportunityId,
                opportunity_group: opportunityGroupId,
            },
            auth: true,
        })
            .then((res: any) => {
                const { success, data } = res.data
                return {
                    success: success,
                    data: data ? sanitizeOpportunity(data) : null,
                }
            })
            .catch((err: any) => {
                return {
                    success: false,
                    data: null,
                }
            })
    }

    const inviteToOpportunity = ({
        id,
        businessId,
    }: {
        id: number
        businessId: number
    }): StatusResponse | Promise<StatusResponse> => {
        return callAPI({
            url: 'opportunity-invitation/',
            type: 'post',
            body: {
                business_id: businessId,
                id,
            },
            auth: true,
        })
            .then((res: any) => {
                const { success } = res.data
                return { success: success }
            })
            .catch((err: any) => {
                return {
                    success: false,
                }
            })
    }

    const getOpportunity = ({ id }: { id: number }) => {
        return callAPI({
            url: '/opportunity/?id=' + id.toString(),
            type: 'get',
            body: {},
            auth: true,
        })
            .then((res: any) => {
                const { success, data } = res.data
                return {
                    success: success as boolean,
                    data: data ? sanitizeOpportunity(data) : null,
                }
            })
            .catch((err: any) => {
                return { success: false, data: null }
            })
    }

    const getInvitedOpportunitiesForMyBusiness = ({
        page = 1,
    }: {
        page?: number
    }):
        | Response<Paginated<MyInvitedOpportunities>>
        | Promise<Response<Paginated<MyInvitedOpportunities>>> => {
        return callAPI({
            url: `opportunity/?page=${page.toString()}`,
            type: 'get',
            body: {},
            auth: true,
        })
            .then((res: any) => {
                const { success, data } = res.data
                return {
                    success: success,
                    data: {
                        numPages: data?.num_pages || 0,
                        count: data?.count || 0,
                        data: data?.data
                            ? data.data?.map((o: MyInvitedOpportunitiesRaw) =>
                                  sanitizeMyOpportunityWithResponse(o)
                              )
                            : [],
                    },
                }
            })
            .catch((err: any) => {
                return {
                    success: false,
                    data: null,
                }
            })
    }

    const deleteOpportunity = ({
        id,
    }: {
        id: number
    }): StatusResponse | Promise<StatusResponse> => {
        return callAPI({
            url: 'opportunity/',
            type: 'delete',
            body: { id: id },
            auth: true,
        })
            .then((res: any) => {
                const { success } = res.data
                return { success: success }
            })
            .catch((err: any) => {
                return {
                    success: false,
                }
            })
    }

    const upsertOpportunity = ({
        title,
        description,
        endDate,
        pricing,
        bidInvitees,
        technicalReqs,
        commercialReqs,
        open,
        id,
        draft,
        emailTemplate,
        useCustomEmailTemplate,
        metadata,
        opportunityGroupId,
    }: {
        id?: number
        open?: boolean
        title?: string
        description?: string
        endDate?: string
        pricing?: boolean
        bidInvitees?: Record<string, number | string>[]
        opportunityGroupId?: number
        technicalReqs?: Reqs[] | null
        commercialReqs?: Reqs[] | null
        draft?: boolean
        emailTemplate?: string | null
        useCustomEmailTemplate?: boolean
        metadata?: Record<string, string | number>
    }): StatusResponse | Promise<StatusResponse> => {
        let payload: any = {
            title,
            description,
            end_date: endDate,
            technical_reqs: technicalReqs,
            commercial_reqs: commercialReqs,
            pricing,
            bid_invitees: bidInvitees?.map((i) => i.id) ?? [],
            draft,
            email_template: emailTemplate,
            use_custom_email_template: useCustomEmailTemplate,
            metadata,
        }

        if (id) {
            payload['id'] = id
            payload['open'] = open
        } else {
            payload['opportunity_group'] = opportunityGroupId
        }

        return callAPI({
            url: 'opportunity/',
            type: 'post',
            body: {
                ...payload,
            },
            auth: true,
        })
            .then((res: any) => {
                const { success } = res.data
                return { success: success }
            })
            .catch((err: any) => {
                return {
                    success: false,
                }
            })
    }

    const getOpportunitiesFilter = ({
        draft,
        closed,
    }: {
        draft?: Boolean
        closed?: Boolean
    }): FilterResponse | Promise<FilterResponse> => {
        let url = 'filters/opportunities/'

        if (draft !== undefined) {
            url += `?is_draft=${draft ? 'True' : 'False'}`
        }

        if (closed !== undefined) {
            const separator = draft !== undefined ? '&' : '?'
            url += `${separator}is_closed=${closed ? 'True' : 'False'}`
        }

        return callAPI({
            url: url,
            type: 'get',
            body: {},
            auth: true,
        })
            .then((res: any) => {
                const { success, data } = res.data
                return {
                    success: success,
                    data: data,
                }
            })
            .catch((err: any) => {
                return {
                    success: false,
                    data: null,
                }
            })
    }

    return {
        copyOpportunity,
        inviteToOpportunity,
        getOpportunity,
        getInvitedOpportunitiesForMyBusiness,
        deleteOpportunity,
        upsertOpportunity,
        sanitizeOpportunity,
        getOpportunitiesFilter,
    }
}

export const {
    copyOpportunity,
    inviteToOpportunity,
    getOpportunity,
    getInvitedOpportunitiesForMyBusiness,
    deleteOpportunity,
    upsertOpportunity,
    sanitizeOpportunity,
    getOpportunitiesFilter,
} = OpportunityAPI()
