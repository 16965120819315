import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    IconButton,
    Tooltip,
} from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'

export interface WatchlistType {
    buisnessIds: Set<number>
    id: number
    includesNewId: boolean
    name: string
}

interface WatchlistTableProps {
    rows: Record<number, WatchlistType>
    updateWatchlist: (watchlistNumebr: number) => void
}

const WatchlistTable = ({ rows, updateWatchlist }: WatchlistTableProps) => (
    <Table>
        <TableHead>
            <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }}>Name</TableCell>
                <TableCell
                    align="right"
                    sx={{ fontWeight: 'bold' }}
                ></TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {Object.keys(rows).map((r) => (
                <TableRow>
                    <TableCell>{rows[+r]?.name}</TableCell>
                    <TableCell align="right">
                        <Tooltip
                            title={rows[+r]?.includesNewId ? 'Remove' : 'Add'}
                        >
                            <IconButton
                                onClick={() => updateWatchlist(rows[+r]?.id)}
                            >
                                {rows[+r]?.includesNewId ? (
                                    <VisibilityOff />
                                ) : (
                                    <Visibility />
                                )}
                            </IconButton>
                        </Tooltip>
                    </TableCell>
                </TableRow>
            ))}
        </TableBody>
    </Table>
)

export default WatchlistTable
