import callAPI from '../api-base'
import {
    Response,
    StatusResponse,
    Watchlist,
} from '../../../utils/models/api-models'

type WatchlistResponse = Response<Watchlist>
type WatchlistsResponse = Response<Watchlist[]>

const WatchlistAPI = () => {
    const getWatchlist = ({
        id,
    }: {
        id: number
    }): Promise<WatchlistResponse> => {
        return callAPI({
            url: '/watchlist/?id=' + id.toString(),
            type: 'get',
            body: {},
            auth: true,
        })
            .then((res: any) => {
                const { success, data } = res.data
                return {
                    success: success as boolean,
                    data: data as Watchlist,
                }
            })
            .catch((err: any) => {
                return { success: false, data: null }
            })
    }

    const getWatchlists = (): Promise<WatchlistsResponse> => {
        return callAPI({
            url: 'watchlist/',
            type: 'get',
            body: {},
            auth: true,
        })
            .then((res: any) => {
                const { success, data } = res.data
                return {
                    success: success,
                    data: data as Watchlist[],
                }
            })
            .catch((err: any) => {
                return {
                    success: false,
                    data: null,
                }
            })
    }

    const deleteWatchlist = ({
        id,
    }: {
        id: number
    }): Promise<StatusResponse> => {
        return callAPI({
            url: 'watchlist/',
            type: 'delete',
            body: { id: id },
            auth: true,
        })
            .then((res: any) => {
                return { success: res.data.success }
            })
            .catch((err: any) => {
                return { success: false }
            })
    }

    const upsertWatchlist = ({
        id,
        name,
        businessIds,
    }: {
        id?: number
        name?: string
        businessIds?: number[]
    }): Promise<StatusResponse> => {
        return callAPI({
            url: 'watchlist/',
            type: 'post',
            body: {
                id: id,
                name: name,
                business_ids: businessIds
                    ? '[' + businessIds?.join(',') + ']'
                    : undefined,
            },
            auth: true,
        })
            .then((res: any) => {
                return {
                    success: res.data.success as boolean,
                }
            })
            .catch((err: any) => {
                return {
                    success: false,
                }
            })
    }

    return {
        getWatchlist,
        getWatchlists,
        deleteWatchlist,
        upsertWatchlist,
    }
}

export const { getWatchlist, getWatchlists, deleteWatchlist, upsertWatchlist } =
    WatchlistAPI()
