import { Box, Tab, Tabs, Typography } from '@mui/material'
import { useEffect, useState } from 'react'

import ContactInfo from './contact-info'
import CompanyInfo from './company-info'
import Services from './services'
import Naics from './naics'
import Locations from './locations'
import { getMyBusiness } from '../../../api/v1/business'
import { useNavigate } from 'react-router-dom'
import { Business } from '../../../utils/models/api-models'
import { MoonLoader } from 'react-spinners'
import { HEADCOUNT } from '../../../utils/constants/info-constants'
import Experiences from './experiences'

const BusinessEditor = () => {
    const [tabPage, setTabPage] = useState(0)
    const [business, setBusiness] = useState<Business>()
    const navigate = useNavigate()

    const getBusiness = async () => {
        const { success, data } = await getMyBusiness()
        if (!success || !data) {
            navigate('/platform/404-error-not-found')
            return
        }
        setBusiness(data)
    }

    useEffect(() => {
        getBusiness()
    }, [])

    const TabPages = (val: number) => {
        switch (val) {
            case 0:
                return (
                    <ContactInfo
                        fetchBusiness={getBusiness}
                        contacts={business?.contacts ?? []}
                    />
                )
            case 1:
                return (
                    <CompanyInfo
                        fetchBusiness={getBusiness}
                        name={business?.name ?? ''}
                        profile={business?.profile || ''}
                        headcount={business?.headcount || HEADCOUNT[0].value}
                        website={business?.website || ''}
                    />
                )
            case 2:
                return (
                    <Services
                        fetchBusiness={getBusiness}
                        services={business?.services ?? []}
                    />
                )
            case 3:
                return (
                    <Locations
                        fetchBusiness={getBusiness}
                        locations={business?.locations ?? []}
                    />
                )
            case 4:
                return (
                    <Naics
                        fetchBusiness={getBusiness}
                        naics={business?.naics ?? []}
                    />
                )
            case 5:
                return (
                    <Experiences
                        fetchBusiness={getBusiness}
                        experiences={business?.experiences ?? []}
                    />
                )
        }
    }

    return (
        <Box>
            <Typography
                variant="h4"
                color="black"
                fontFamily='"Inter", sans-serif'
                fontWeight="bold"
            >
                Business Editor
            </Typography>
            <Box
                mt={4}
                px={4}
                py={2}
                bgcolor="white"
                boxShadow="rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px"
                borderRadius={2}
            >
                <Box
                    sx={{ borderBottom: 1, borderColor: 'divider' }}
                    display="flex"
                    justifyContent="center"
                >
                    {business && (
                        <Tabs
                            value={tabPage}
                            onChange={(_, val) => setTabPage(val)}
                        >
                            <Tab label="Contact Info" />
                            <Tab label="Company Info" />
                            <Tab label="Services" />
                            <Tab label="Locations" />
                            <Tab label="NAICS" />
                            <Tab label="Experiences" />
                        </Tabs>
                    )}
                </Box>
                <Box mb={8} mt={2} minHeight={100}>
                    {!business && (
                        <Box display="flex" justifyContent="center" mt={8}>
                            <MoonLoader
                                loading={!business}
                                color="#262d50"
                                size={50}
                            />
                        </Box>
                    )}
                    {business && TabPages(tabPage)}
                </Box>
            </Box>
        </Box>
    )
}

export default BusinessEditor
