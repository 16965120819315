import { useEffect, useState } from 'react'
import { Grid, Typography, Link } from '@mui/material'
import activateAccount from '../../../api/v1/user/activate-user'

const messages = {
    success: 'Your account has been activated. Please log in.',
    failure:
        'Something went wrong. Please try again or contact us at contact@dbeworld.io.',
}

interface NonBusinessActivateProps {
    uuid: string
    token: string
}

const NonBusinessActivate = ({
    uuid,
    token,
}: NonBusinessActivateProps): JSX.Element => {
    const [messageType, setMessageType] = useState<'success' | 'failure'>(
        'success'
    )

    const activateUser = async () => {
        const { success } = await activateAccount({ uuid, token })
        if (success) {
            setMessageType('success')
        } else {
            setMessageType('failure')
        }
    }

    useEffect(() => {
        activateUser()
    })

    return (
        <>
            <Grid
                container
                justifyContent="flex-start"
                px={10}
                mt={10}
                direction="column"
                alignItems="center"
                textAlign="center"
            >
                <Grid item>
                    <Typography
                        variant="h5"
                        color="#262d50"
                        fontWeight="bold"
                        fontFamily='"Montserrat", sans-serif'
                    >
                        dbeWorld
                    </Typography>
                </Grid>
                <Grid item mt={4}>
                    <Typography
                        variant="h3"
                        fontWeight="bold"
                        fontFamily='"Inter", sans-serif'
                    >
                        Activate Account
                    </Typography>
                </Grid>
                <Grid item mt={2}>
                    <Typography variant="h5" color="#7f7f7f">
                        {messages[messageType]}
                    </Typography>
                </Grid>
            </Grid>
            <Grid
                container
                justifyContent="center"
                mt={3}
                spacing={2}
                sx={{ fontSize: 12 }}
            >
                <Grid item>
                    <Link href="/register" sx={linkStyling}>
                        Register
                    </Link>
                </Grid>
                <Grid item>|</Grid>
                <Grid item>
                    <Link href="/login" sx={linkStyling}>
                        Login
                    </Link>
                </Grid>
                <Grid item>|</Grid>
                <Grid item>
                    <Link href="/resend-activation" sx={linkStyling}>
                        Resend Activation
                    </Link>
                </Grid>
                <Grid item>|</Grid>
                <Grid item>
                    <Link href="/reset-password" sx={linkStyling}>
                        Reset Password
                    </Link>
                </Grid>
            </Grid>
        </>
    )
}

const linkStyling = {
    color: 'black',
    textDecoration: 'none',
    '&:hover': {
        textDecoration: 'underline',
        color: '#262d50',
    },
}

export default NonBusinessActivate
