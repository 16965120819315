import { Box, Typography, useMediaQuery } from '@mui/material'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { getUserInfo } from '../../selectors/login-selector'
import { getNotification } from '../../selectors/notification-selector'
import { setOpen } from '../../slices/notification-slice'
import WorldSnackBar from '../ui-components/snackbar'
import SideDrawer from './drawer'

interface PlatformPageProps {
    component: JSX.Element
    allowed?: 'all' | 'business' | 'prime'
    isSuperOnly?: boolean
}

const PlatformPage = ({
    component,
    allowed = 'all',
    isSuperOnly = false,
}: PlatformPageProps) => {
    const isMobile = useMediaQuery('(max-width: 800px)')
    const { userType, isSuper } = useSelector(getUserInfo)
    const notifInfo = useSelector(getNotification)

    if (isMobile) {
        return (
            <Box textAlign="center">
                <Typography variant="h5">
                    Please visit dbeworld.io on your computer.
                </Typography>
            </Box>
        )
    }

    if (isSuperOnly && !isSuper) {
        return <Navigate to="/platform/404-not-found" />
    }

    if (userType !== 'BUSINESS' && allowed === 'business') {
        return <Navigate to="/platform/404-not-found" />
    }

    if (userType === 'BUSINESS' && allowed === 'prime') {
        return <Navigate to="/platform/404-not-found" />
    }

    return (
        <>
            <Box display="flex">
                <SideDrawer />
                <Box mx="50px" my={4} sx={{ flexGrow: 1 }}>
                    {component}
                </Box>
            </Box>
            <WorldSnackBar
                message={<Box textAlign="center">{notifInfo.message}</Box>}
                open={notifInfo.open}
                setOpen={(val: boolean) =>
                    setOpen({ open: val, message: null })
                }
            />
        </>
    )
}

export default PlatformPage
