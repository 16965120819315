import { useEffect, useState } from 'react'

import {
    Box,
    Button,
    Divider,
    Pagination,
    Typography,
    Tooltip,
} from '@mui/material'
import { Warning, Check } from '@mui/icons-material'

import { useFormContext } from 'react-hook-form'

import findBusinessAPI from '../../../api/v1/search/find-business-api'
import WorldSnackbar from '../../ui-components/snackbar'

import { AnonBusiness } from '../../../utils/models/api-models'

import { Failure } from '../../../utils/constants/messages'

export interface BusinessResultType {
    data: any[]
    totalPages: number
    businessEmail: string
    businessName: string
}

interface BusinessResultProps {
    result: BusinessResultType
    handleNext: () => void
}

const BusinessResults = ({ result, handleNext }: BusinessResultProps) => {
    const { data, totalPages, businessEmail, businessName } = result

    const [businessData, setBusinessData] = useState<{
        data: AnonBusiness[] | null
        totalPages: number
    }>({
        data,
        totalPages,
    })
    const [pageNumber, setPageNumber] = useState(1)
    const [openSnackbar, setOpenSnackbar] = useState(false)
    const { setValue } = useFormContext()

    const getData = async () => {
        const { success, ...response } = await findBusinessAPI({
            page: pageNumber,
            businessEmail,
            businessName,
        })
        if (success) {
            setBusinessData({
                data: response.data?.data || null,
                totalPages: response.data?.numPages || 0,
            })
        } else {
            setOpenSnackbar(true)
        }
    }

    const handleClaim = (email: string) => {
        setValue('email', email)
        handleNext()
    }

    useEffect(() => {
        getData()
    }, [pageNumber])

    return (
        <>
            <Box
                mt={3}
                mb={2}
                height={300}
                width={300}
                overflow="auto"
                borderRadius={1}
                px={3}
                py={2}
                sx={{
                    boxShadow: 'rgba(0, 0, 0, 0.10) 1px 2px 4px 1px inset',
                }}
            >
                {businessData.data &&
                    businessData.data.map((d) => (
                        <>
                            <Box
                                py={1}
                                display="flex"
                                justifyContent="space-between"
                            >
                                <Box>
                                    <Box maxWidth={180} textAlign="left">
                                        <Typography
                                            fontWeight="bold"
                                            variant="caption"
                                        >
                                            {d.name}
                                        </Typography>
                                    </Box>
                                    <Box maxWidth={180}>
                                        <Typography variant="caption">
                                            {d.contacts[0].email}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box>
                                    <Tooltip
                                        title={
                                            d.emailMatch
                                                ? 'We found an exact match to the email you provided.'
                                                : 'The email on this business does not match the business email you searched. If you did not search using a business email, ignore this warning.'
                                        }
                                    >
                                        <Button
                                            variant="text"
                                            color="primary"
                                            sx={{
                                                px: 2,
                                                fontSize: 10,
                                            }}
                                            startIcon={
                                                !d.emailMatch ? (
                                                    <Warning
                                                        sx={{
                                                            height: 15,
                                                            width: 15,
                                                        }}
                                                    />
                                                ) : (
                                                    <Check
                                                        sx={{
                                                            height: 15,
                                                            width: 15,
                                                        }}
                                                    />
                                                )
                                            }
                                            onClick={() =>
                                                handleClaim(d.contacts[0].email)
                                            }
                                        >
                                            Claim
                                        </Button>
                                    </Tooltip>
                                </Box>
                            </Box>
                            <Divider />
                        </>
                    ))}
            </Box>
            <Pagination
                count={businessData.totalPages}
                siblingCount={1}
                onChange={(_, val) => setPageNumber(val)}
                page={pageNumber}
            />
            <WorldSnackbar
                open={openSnackbar}
                setOpen={setOpenSnackbar}
                message={<Box>{Failure}</Box>}
            />
        </>
    )
}

export default BusinessResults
