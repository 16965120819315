import { useDispatch } from 'react-redux'

import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Tooltip,
} from '@mui/material'
import { Controller, useForm } from 'react-hook-form'

import WorldTextField from '../../ui-components/text-field'

import { upsertWatchlist } from '../../../api/v1/watchlist'

import { Failure, CreateWatchlist } from '../../../utils/constants/messages'
import { setOpen as setNotif } from '../../../slices/notification-slice'

interface CreateProps {
    open: boolean
    setOpen: (val: boolean) => void
    numWatchlists: number
    fetchWatchlists: () => void
}

const Create = ({
    open,
    setOpen,
    numWatchlists,
    fetchWatchlists,
}: CreateProps) => {
    const dispatch = useDispatch()

    const { control, trigger, getValues, reset } = useForm({
        defaultValues: {
            watchlistName: '',
        },
    })

    const handleCreate = async () => {
        if (!(await trigger())) {
            return
        }

        const { watchlistName } = getValues()
        const { success } = await upsertWatchlist({
            name: watchlistName,
        })

        fetchWatchlists()

        dispatch(
            setNotif({
                open: true,
                message: success ? CreateWatchlist : Failure,
            })
        )
        setOpen(false)
        reset({
            watchlistName: '',
        })
    }

    const limit = numWatchlists >= 25

    return (
        <Dialog fullWidth open={open} onClose={() => setOpen(false)}>
            <DialogTitle sx={{ fontWeight: 'light' }}>
                Create Watchlist
            </DialogTitle>
            <DialogContent>
                <Box my={1}>
                    <Controller
                        name={'watchlistName' as const}
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <WorldTextField
                                placeholder="Enter watchlist name"
                                value={field.value}
                                disabled={limit}
                                error={Boolean(error)}
                                fullWidth
                                label="Watchlist Name"
                                helperText={error?.message || ''}
                                onChange={(e) => field.onChange(e.target.value)}
                            />
                        )}
                        rules={{
                            required: {
                                value: true,
                                message: 'Watchlist name is required',
                            },
                            maxLength: {
                                value: 50,
                                message:
                                    'Watchlist name must be 50 characters or less',
                            },
                            pattern: {
                                value: /^[^\s]+(?:$|.*[^\s]+$)/,
                                message:
                                    'Watchlist name can not start or end with a space',
                            },
                        }}
                    />
                </Box>
            </DialogContent>
            <DialogActions
                sx={{ justifyContent: 'space-between', mx: 2, mb: 2 }}
            >
                <Tooltip
                    title={
                        limit
                            ? 'Max number of watchlists (25) has been reached.'
                            : ''
                    }
                >
                    <Button
                        variant="text"
                        color="primary"
                        sx={{
                            width: 100,
                        }}
                        disabled={limit}
                        onClick={() => handleCreate()}
                    >
                        Save
                    </Button>
                </Tooltip>
                <Button
                    variant="text"
                    color="secondary"
                    sx={{
                        width: 100,
                    }}
                    onClick={() => setOpen(false)}
                >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default Create
