// Failure
export const Failure =
    'Something went wrong. Please try again or contact us at contact@dbeworld.io.'

// Success
// Watchlist
export const CreateWatchlist =
    'New watchlist created. It will now show under exisiting, please click add to add the business to the watchlist.'
export const AddBusinessToWatchlist =
    'Business has been added to your watchlist.'
export const RemoveBusinessFromWatchlist =
    'Business has been removed from your watchlist.'
export const UpdateWatchlistName =
    'The watchlist name has been updated successfully.'
export const RemoveWatchlist = 'Business has been removed from your watchlist.'

// Business Editor
export const UpdateCompanyInfo =
    'Your company information has been successfully updated.'
export const UpdateContactInfo =
    'Your contact information has been successfully updated.'
export const CreateLocation = 'Location was successfully added.'
export const RemoveLocation = 'Location was successfully deleted.'
export const CreateNaics = 'NAICS code was successfully added.'
export const RemoveNaics = 'NAICS code was successfully deleted.'
export const CreateService = 'Service was successfully added.'
export const RemoveService = 'Service was successfully deleted.'
export const CreateExperience = 'Experience was successfully added.'
export const RemoveExperience = 'Experience was successfully deleted.'

// Settings
export const UpdatePassword = 'Your password has been successfully changed.'

// Opportunity
export const CreateOpportunityGroup = 'Opportunity was successfully created.'
export const UpdateOpportunityGroup = 'Opportunity was successfully updated.'
export const RemoveOpportunityGroup = 'Opportunity was successfully deleted.'
export const CreateOpportunity = 'Scope of work was successfully created.'
export const UpdateOpportunity = 'Scope of work was successfully updated.'
export const RemoveOpportunity = 'Scope of work was successfully deleted.'
export const AlreadyInvitedOpportunity =
    'You have already invited this business.'
export const InvitedToOpportunity =
    'The business has been successfully invited to the scope of work.'
export const CreateOpportunityResponse =
    'Scope of work response was successfully created.'
export const UpdateOpportunityResponse =
    'Scope of work response was successfully updated.'
export const RemoveOpportunityResponse =
    'Scope of work response was successfully deleted.'
export const EvaluateOpportunityResponse =
    'Evaluation has been successfully submitted.'
export const CopyOpportunity = 'Scope of work was successfully copied.'

// Log Communication
export const CreateLogCommunication = 'Communication was successfully logged.'

// Business Notes
export const NoteAdded = 'Note was successfully added.'
