import { Delete, Edit } from '@mui/icons-material'
import {
    Box,
    Link,
    Stack,
    Tooltip,
    Button,
    Typography,
    IconButton,
    Dialog,
    DialogActions,
    DialogTitle,
} from '@mui/material'
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid'

import NoSearchResult from '../../../../search/search-result-states/no-search-result'
import { MyInvitedOpportunities } from '../../../../../../utils/models/api-models'
import { useState } from 'react'
import { deleteOpportunityResponse as deleteOpportunityResponseAPI } from '../../../../../../api/v1/opportunity-response'
import { useDispatch } from 'react-redux'
import { setOpen as setNotification } from '../../../../../../slices/notification-slice'
import {
    Failure,
    RemoveOpportunityResponse,
} from '../../../../../../utils/constants/messages'

interface TableProps {
    data: MyInvitedOpportunities[]
    count: number
    setPage: (num: number) => void
    getInvitedOpportunities: () => void
}

const Table = ({
    data,
    count,
    setPage,
    getInvitedOpportunities,
}: TableProps) => {
    const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false)
    const [id, setId] = useState<number>(-1)
    const dispatch = useDispatch()

    const deleteOpportunityResponse = async () => {
        const response = await deleteOpportunityResponseAPI({ id: id })
        dispatch(
            setNotification({
                message: response.success ? RemoveOpportunityResponse : Failure,
                open: true,
            })
        )
        setOpenDeleteDialog(false)
        getInvitedOpportunities()
    }

    const columns: GridColDef[] = [
        {
            field: 'opportunity',
            headerName: 'Scope of Work',
            editable: false,
            sortable: false,
            flex: 2,
            renderCell: (params: GridRenderCellParams) => (
                <Link
                    href={`/platform/scope-of-work/${params.row.id}`}
                    target="_blank"
                >
                    {params.row.title}
                </Link>
            ),
        },
        {
            field: 'opportunity-status',
            headerName: 'Status',
            editable: false,
            sortable: false,
            flex: 1,
            renderCell: (params: GridRenderCellParams) => (
                <Typography variant="body1">
                    {params.row.opportunityStatus ? 'OPEN' : 'CLOSED'}
                </Typography>
            ),
        },
        {
            field: 'response-status',
            headerName: 'Response Status',
            editable: false,
            sortable: false,
            flex: 1.25,
            renderCell: (params: GridRenderCellParams) => (
                <Typography variant="body1">
                    {!params.row.opportunityStatus &&
                    params.row.responseStatus === null
                        ? 'CLOSED'
                        : params.row.responseStatus === null
                        ? 'NO RESPONSE'
                        : params.row.responseDraft
                        ? 'IN-DRAFT'
                        : params.row.responseStatus}
                </Typography>
            ),
        },
        {
            field: 'due-by',
            headerName: 'Due Date',
            editable: false,
            sortable: false,
            flex: 1,
            renderCell: (params: GridRenderCellParams) => (
                <Typography variant="body1">{params.row.endDate}</Typography>
            ),
        },
        {
            field: 'tools',
            headerName: '',
            editable: false,
            sortable: false,
            flex: 1,
            renderCell: (params: GridRenderCellParams) => (
                <Stack
                    direction="row"
                    sx={{ justifyContent: 'right', width: '100%' }}
                >
                    <Tooltip
                        title={
                            params.row.responseStatus !== null
                                ? 'Edit'
                                : 'Create'
                        }
                    >
                        <IconButton
                            href={`/platform/scope-of-work/${params.row.id}/response/`}
                        >
                            <Edit sx={{ height: '20px' }} />
                        </IconButton>
                    </Tooltip>
                    {params.row.responseStatus !== null && (
                        <Tooltip title="Delete">
                            <IconButton
                                onClick={() => {
                                    setOpenDeleteDialog(true)
                                    setId(params.row.responseId)
                                }}
                            >
                                <Delete sx={{ height: '20px' }} />
                            </IconButton>
                        </Tooltip>
                    )}
                </Stack>
            ),
        },
    ]

    return (
        <Box
            sx={{
                height: '100vh',
                width: '100%',
            }}
        >
            <DataGrid
                rows={data || []}
                columns={columns}
                pageSize={10}
                disableColumnFilter
                disableColumnMenu
                disableSelectionOnClick
                disableColumnSelector
                onPageChange={(page, _) => setPage(page + 1)}
                rowCount={count}
                paginationMode="server"
                getRowHeight={() => 'auto'}
                sx={{
                    border: 0,
                    '& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cell:focus-within':
                        {
                            outline: 'none',
                        },
                    '& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus':
                        {
                            outline: 'none',
                        },
                    '.MuiDataGrid-columnSeparator': {
                        display: 'none',
                    },
                    '& .MuiDataGrid-columnHeaderTitle': {
                        fontWeight: 'bold',
                        color: '#262d50',
                    },
                    '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
                        py: '8px',
                    },
                    '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
                        py: '15px',
                    },
                    '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell':
                        { py: '22px' },
                }}
                components={{
                    NoRowsOverlay: () => (
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            sx={{
                                height: '100%',
                                width: '100%',
                            }}
                        >
                            <NoSearchResult
                                title="No Invites"
                                subtitle="You have not been invited to any scope of work yet."
                            />
                        </Box>
                    ),
                }}
            />
            <Dialog
                open={openDeleteDialog}
                maxWidth="sm"
                onClose={() => setOpenDeleteDialog(false)}
            >
                <DialogTitle
                    sx={{
                        width: 200,
                        mx: 'auto',
                        fontWeight: 'light',
                    }}
                >
                    Delete Response?
                </DialogTitle>
                <DialogActions
                    sx={{ justifyContent: 'space-between', mx: 2, mb: 2 }}
                >
                    <Button
                        variant="text"
                        color="secondary"
                        sx={{
                            width: 100,
                        }}
                        onClick={() => setOpenDeleteDialog(false)}
                    >
                        No
                    </Button>
                    <Button
                        variant="text"
                        color="primary"
                        sx={{
                            width: 100,
                        }}
                        onClick={async () => deleteOpportunityResponse()}
                    >
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}

export default Table
