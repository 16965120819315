export const CERTIFICATES_CODES = {
    SBD: 'SBD',
    WOSB: 'WOSB',
    SDVOSB: 'SDVOSB',
    HubZone: 'HubZone',
    DBE: 'DBE',
    ACDBE: 'ACDBE',
    DBEA: 'DBEA',
    WBE: 'WBE',
    MBE_AABE: 'MBE - AABE',
    MBE_APABE: 'MBE - APABE',
    MBE_FBE: 'MBE - FBE',
    MBE_HABE: 'MBE - HABE',
    MBE_NABE: 'MBE - NABE',
    SBE: 'SBE',
    MWBE: 'MWBE',
    HUB: 'HUB',
    DVOB: 'DVOB',
    LBE: 'LBE',
    MBE: 'MBE',
    VOB: 'VOB',
    VOSB: 'VOSB',
    PBE: 'PBE',
    WMBE: 'WMBE',
    LGBTBE: 'LGBTBE',
    VBE: 'VBE',
    DOBE: 'DOBE',
    SS: 'Stocking Supplier',
    NSS: 'Non-Stocking Supplier',
}
export const CERTIFICATES: { value: string; label: string }[] = []
Object.keys(CERTIFICATES_CODES).forEach((code) => {
    //@ts-ignore
    CERTIFICATES.push({ value: code, label: CERTIFICATES_CODES[code] })
})

export const CERTFICATION_BODY_CODES = {
    GDOT: 'GDOT',
    MAR: 'MAR',
    ATLANTA: 'ATLANTA',
    OMWBE: 'OMWBE',
    FDOT: 'FDOT',
    CALTRANS: 'CALTRANS',
    AUSTIN: 'AUSTIN',
    TXDOT: 'TXDOT',
    CCRTA: 'CCRTA',
    HOUSTON: 'HOUSTON',
    SCTRCA: 'SCTRCA',
    NCTRCA: 'NCTRCA',
    TCPA: 'Texas Comptroller of Public Accounts',
    NJDOT: 'NJDOT',
    NJTRANSIT: 'NJTRANSIT',
    PANYNJ: 'PANYNJ',
    NJSAVI: 'NJSAVI',
    IL_BEP: 'Illinois - BEP',
    IL_VBP: 'Illinois - VBP',
    CEI_BEP: 'CEI - BEP',
    PA_BEP: 'Pennsylvania - BEP',
}
export const CERTIFICATION_BODY: { value: string; label: string }[] = []
Object.keys(CERTFICATION_BODY_CODES).forEach((code) => {
    CERTIFICATION_BODY.push({
        value: code,
        //@ts-ignore
        label: CERTFICATION_BODY_CODES[code],
    })
})

export const HEADCOUNT_CODES = ['1-25', '26-50', '51-100', '101-200', '200+']
export const HEADCOUNT: { value: string; label: string }[] = []
HEADCOUNT_CODES.forEach((code) => {
    HEADCOUNT.push({ value: code, label: code })
})

const STATES_CODES = [
    'Alabama',
    'Montana',
    'Alaska',
    'Nebraska',
    'Arizona',
    'Nevada',
    'Arkansas',
    'New Hampshire',
    'California',
    'New Jersey',
    'Colorado',
    'New Mexico',
    'Connecticut',
    'New York',
    'Delaware',
    'North Carolina',
    'Florida',
    'North Dakota',
    'Georgia',
    'Ohio',
    'Hawaii',
    'Oklahoma',
    'Idaho',
    'Oregon',
    'Illinois',
    'Pennsylvania',
    'Indiana',
    'RhodeIsland',
    'Iowa',
    'South Carolina',
    'Kansas',
    'South Dakota',
    'Kentucky',
    'Tennessee',
    'Louisiana',
    'Texas',
    'Maine',
    'Utah',
    'Maryland',
    'Vermont',
    'Massachusetts',
    'Virginia',
    'Michigan',
    'Washington',
    'Minnesota',
    'West Virginia',
    'Mississippi',
    'Wisconsin',
    'Missouri',
    'Wyoming',
]
export const STATES: { value: string; label: string }[] = []
STATES_CODES.forEach((code) => {
    STATES.push({ value: code.toUpperCase(), label: code })
})

const RADIUS_VALUES = [
    0, 10, 20, 40, 60, 80, 100, 150, 200, 250, 300, 350, 400, 450, 500,
]
export const RADIUS: { value: number; label: string }[] = []
RADIUS_VALUES.forEach((value) => {
    RADIUS.push({ value, label: `${value} miles` })
})
