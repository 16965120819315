import { Checkbox, IconButton, TableCell, TableRow } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'
import WorldTextField from '../../../../../ui-components/text-field'
import { Close } from '@mui/icons-material'

interface RequirementProps {
    index: number
    reqName: string
    remove: () => void
    disable: boolean
}

const Requirement = ({ index, reqName, remove, disable }: RequirementProps) => {
    const { control } = useFormContext()
    const name = `${reqName}.${index}.value` as string
    const req = `${reqName}.${index}.required` as string

    const exampleTextForRequirement = [
        'Subcontractor has $2M in commercial general liability',
        'All equipment must comply with FCC regulations on electromagnetic interference (EMI)',
        "Contractors must have a valid contractor's license in the state where the work will be performed",
        'Bidders must have a proven track record of successfully completing at least 3 projects of similar size and scope',
        'All vehicles used for transportation of hazardous materials must comply with DOT regulations and have proper placards',
        'Suppliers must provide a certificate of analysis for all chemical products being purchased',
    ]

    const getRandomInt = (): number => {
        const range = exampleTextForRequirement.length
        return Math.floor(Math.random() * range)
    }

    return (
        <TableRow>
            <TableCell>
                <Controller
                    name={`${name}` as const}
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <WorldTextField
                            placeholder={
                                'Example: ' +
                                exampleTextForRequirement[getRandomInt()]
                            }
                            disabled={disable}
                            value={field.value}
                            error={Boolean(error)}
                            fullWidth
                            size="small"
                            variant="outlined"
                            helperText={error?.message || ''}
                            onChange={(e) => field.onChange(e.target.value)}
                        />
                    )}
                    rules={{
                        required: {
                            value: true,
                            message: 'Requirement is required',
                        },
                        maxLength: {
                            value: 1000,
                            message:
                                'Requirement must be 1000 characters or less',
                        },
                        pattern: {
                            value: /^[^\s]+(?:$|.*[^\s]+$)/,
                            message:
                                'Requirement can not start or end with a space',
                        },
                    }}
                />
            </TableCell>
            <TableCell width={50}>
                <Controller
                    name={`${req}` as const}
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                        <Checkbox
                            checked={
                                field.value && field.value !== undefined
                                    ? field.value
                                    : false
                            }
                            disabled={disable}
                            onChange={(e) => field.onChange(e.target.checked)}
                        />
                    )}
                />
            </TableCell>
            <TableCell align="right" width={50}>
                <IconButton
                    sx={{ ml: 1, borderRadius: 0 }}
                    onClick={() => remove()}
                >
                    <Close />
                </IconButton>
            </TableCell>
        </TableRow>
    )
}

export default Requirement
