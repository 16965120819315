import { Typography, Box, Link } from '@mui/material'

import {
    Naics,
    Location,
    Certificate,
} from '../../../../utils/models/api-models'
import { CERTFICATION_BODY_CODES } from '../../../../utils/constants/info-constants'

interface CompanyInfoProps {
    website: string
    certificates: Certificate[]
    headcount: string
    locations: Location[]
    naics: Naics[]
}

const CompanyInfo = ({
    certificates,
    website,
    headcount,
    locations,
    naics,
}: CompanyInfoProps) => (
    <Box
        px={3}
        py={2}
        bgcolor="white"
        boxShadow="rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px"
        borderRadius={2}
        height="fit-content"
    >
        {website.length > 0 && (
            <Box mt={1}>
                <Typography variant="caption">Website</Typography>
                <Link
                    href={
                        website.startsWith('http://') ||
                        website.startsWith('https://')
                            ? website
                            : `https://${website}`
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <Typography>{website}</Typography>
                </Link>
            </Box>
        )}

        {headcount && (
            <Box mt={1}>
                <Typography variant="caption">Company Headcount</Typography>
                <Typography>{headcount}</Typography>
            </Box>
        )}
        {certificates.length > 0 && (
            <Box mt={1}>
                <Typography variant="caption">Certifications</Typography>
                <Typography>
                    {certificates.map((c) => (
                        <Typography>
                            {/* @ts-ignore */}
                            {c.type} - {CERTFICATION_BODY_CODES[c.body]}
                        </Typography>
                    ))}
                </Typography>
            </Box>
        )}
        {naics.length > 0 && (
            <Box mt={1}>
                <Typography variant="caption">NAICS</Typography>
                <Box>
                    {naics.map((n) => (
                        <Typography>{n.code}</Typography>
                    ))}
                </Box>
            </Box>
        )}
        {locations.length > 0 && (
            <Box mt={1}>
                <Typography variant="caption">Locations</Typography>
                <Typography>
                    {locations.map((l) => (
                        <Typography>
                            {l.city}, {l.state}
                        </Typography>
                    ))}
                </Typography>
            </Box>
        )}
    </Box>
)

export default CompanyInfo
