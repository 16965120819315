import { FormControlLabel, FormGroup, Switch, SwitchProps } from '@mui/material'

interface WorldSwitchProps {
    switchProps?: SwitchProps
    label?: string
}

const WorldSwitch = ({ switchProps, label }: WorldSwitchProps) => {
    return (
        <FormGroup>
            <FormControlLabel
                control={<Switch {...switchProps} />}
                label={label || ''}
            />
        </FormGroup>
    )
}

export default WorldSwitch
