import { Typography, Box } from '@mui/material'

const AboutUs = ({ profile }: { profile: string | null }) => (
    <>
        <Typography variant="caption">About Us</Typography>
        <Box>
            <Typography>
                {profile
                    ? profile
                    : 'The business has not updated this information yet.'}
            </Typography>
        </Box>
    </>
)

export default AboutUs
