import {
    OpportunityResponse,
    OpportunityResponseRaw,
    Response,
    Paginated,
    DownloadableLink,
    StatusResponse,
    ResponseRequirement,
} from '../../../utils/models/api-models'
import callAPI from '../api-base'

const OpportunityResponseAPI = () => {
    const santizeOpportunityResponse = (
        value: OpportunityResponseRaw
    ): OpportunityResponse => {
        return {
            id: value.id,
            opportunity: value.opportunity,
            technicalReqs: value.technical_reqs,
            commercialReqs: value.commercial_reqs,
            business: {
                id: value.business.id,
                name: value.business.name,
                contactName: value.business.contact_name,
                email: value.business.email,
                phoneNumber: value.business.phone_number,
            },
            isDraft: value.is_draft,
            pricing: value.pricing,
            rejectionNote: value.rejection_note,
            status: value.status,
            createdAt: value.created_at,
            lastModifiedAt: value.last_modifed_at,
        }
    }

    const getOpportunityResponse = ({
        id,
    }: {
        id: number
    }):
        | Response<OpportunityResponse>
        | Promise<Response<OpportunityResponse>> => {
        return callAPI({
            url: '/opportunity-response/?id=' + id.toString(),
            type: 'get',
            body: {},
            auth: true,
        })
            .then((res: any) => {
                const { success, data } = res.data
                return {
                    success: success as boolean,
                    data: data ? santizeOpportunityResponse(data) : null,
                }
            })
            .catch((err: any) => {
                return { success: false, data: null }
            })
    }

    const getOpportunityResponses = ({
        opportunityId,
    }: {
        opportunityId?: number
    }):
        | Response<Paginated<OpportunityResponse>>
        | Promise<Response<Paginated<OpportunityResponse>>> => {
        let url = 'opportunity-response/'
        if (opportunityId) {
            url += '?opportunity_id=' + opportunityId.toString()
        }

        return callAPI({
            url: url,
            type: 'get',
            body: {},
            auth: true,
        })
            .then((res: any) => {
                const { success, data } = res.data
                return {
                    success: success,
                    data: {
                        numPages: data?.num_pages || 0,
                        count: data?.count || 0,
                        data:
                            data?.data.map((d: OpportunityResponseRaw) =>
                                santizeOpportunityResponse(d)
                            ) || null,
                    },
                }
            })
            .catch((err: any) => {
                return {
                    success: false,
                    data: null,
                }
            })
    }

    const getPricingFile = ({
        id,
    }: {
        id: number
    }): DownloadableLink | Promise<DownloadableLink> => {
        return callAPI({
            url: 'opportunity-response/download/pricing/?id=' + id.toString(),
            type: 'get',
            body: {},
            auth: true,
        })
            .then((res: any) => {
                const { success, data } = res.data
                return {
                    success: success as boolean,
                    data: data,
                }
            })
            .catch((err: any) => {
                return { success: false, data: null }
            })
    }

    const deleteOpportunityResponse = ({
        id,
    }: {
        id: number
    }): StatusResponse | Promise<StatusResponse> => {
        return callAPI({
            url: 'opportunity-response/',
            type: 'delete',
            body: { id: id },
            auth: true,
        })
            .then((res: any) => {
                const { success } = res.data
                return { success: success }
            })
            .catch((err: any) => {
                return {
                    success: false,
                }
            })
    }

    const evaluateOpportunityResponse = ({
        responseId,
        status,
        note,
    }: {
        responseId: number
        status: 0 | 1 | 2 | 3
        note?: string
    }): StatusResponse | Promise<StatusResponse> => {
        return callAPI({
            url: 'opportunity-response-evaluate/',
            type: 'post',
            body: {
                response: responseId,
                status: status,
                note: note || '',
            },
            auth: true,
        })
            .then((res: any) => {
                const { success } = res.data
                return { success: success }
            })
            .catch((err: any) => {
                return {
                    success: false,
                }
            })
    }

    const upsertOpportunityResponse = ({
        id,
        technicalReqs,
        commercialReqs,
        isDraft,
        pricing,
        opportunity,
    }: {
        technicalReqs: ResponseRequirement[] | null
        commercialReqs: ResponseRequirement[] | null
        isDraft: boolean
        id?: number
        pricing?: string | File
        opportunity?: number
    }): StatusResponse | Promise<StatusResponse> => {
        let form_data = new FormData()
        form_data.append(
            'technical_reqs',
            technicalReqs ? JSON.stringify(technicalReqs) : '[]'
        )
        form_data.append(
            'commercial_reqs',
            commercialReqs ? JSON.stringify(commercialReqs) : '[]'
        )
        form_data.append('is_draft', JSON.stringify(isDraft))

        if (!id) {
            if (opportunity) {
                form_data.append('opportunity', opportunity.toString())
            }
            if (pricing) {
                form_data.append('pricing', pricing)
            }
        } else {
            form_data.append('id', id.toString())
            if (pricing && typeof pricing !== 'string') {
                form_data.append('pricing', pricing)
            }
        }

        return callAPI({
            url: 'opportunity-response/',
            type: 'post',
            body: form_data,
            auth: true,
        })
            .then((res: any) => {
                const { success } = res.data
                return { success: success }
            })
            .catch((err: any) => {
                return {
                    success: false,
                }
            })
    }

    return {
        getOpportunityResponse,
        getOpportunityResponses,
        getPricingFile,
        deleteOpportunityResponse,
        evaluateOpportunityResponse,
        upsertOpportunityResponse,
    }
}

export const {
    getOpportunityResponse,
    getOpportunityResponses,
    getPricingFile,
    deleteOpportunityResponse,
    evaluateOpportunityResponse,
    upsertOpportunityResponse,
} = OpportunityResponseAPI()
