import { Box, IconButton, Link } from '@mui/material'
import { Check, Warning, VisibilityOff } from '@mui/icons-material'
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import { useDispatch } from 'react-redux'

import NoSearch from '../search/search-result-states/no-search'
import NoSearchResult from '../search/search-result-states/no-search-result'

import { upsertWatchlist } from '../../../api/v1/watchlist'

import { Option } from '../../../utils/models/api-models'
import { Business } from '../../../utils/models/api-models'

import {
    Failure,
    RemoveBusinessFromWatchlist,
} from '../../../utils/constants/messages'
import { setOpen } from '../../../slices/notification-slice'

interface TableProps {
    rows: any[]
    count: number
    watchlist: Record<string, any>
    fetchWatchlists: (val: Option) => void
}

const Table = ({ rows, count, watchlist, fetchWatchlists }: TableProps) => {
    const dispatch = useDispatch()

    const deleteFromWatchlist = async (id: number) => {
        const businessIds = watchlist.businesses
            .filter((i: Business) => i.id !== id)
            .map((i: Business) => i.id)

        const { success } = await upsertWatchlist({
            id: watchlist.id,
            businessIds: businessIds,
        })

        fetchWatchlists({
            label: watchlist.name,
            value: watchlist.id.toString(),
        })

        dispatch(
            setOpen({
                open: true,
                message: success ? RemoveBusinessFromWatchlist : Failure,
            })
        )
    }

    const columns: GridColDef[] = [
        {
            field: 'isVerified',
            headerName: 'Verified',
            editable: false,
            sortable: false,
            flex: 0.8,
            renderCell: (params: GridRenderCellParams) => (
                <Box display="flex" justifyContent="center">
                    {params.row.is_verified ? (
                        <Check color="success" />
                    ) : (
                        <Warning color="warning" />
                    )}
                </Box>
            ),
        },
        {
            field: 'name',
            headerName: 'Name',
            editable: false,
            sortable: false,
            flex: 2,
            renderCell: (params: GridRenderCellParams) => (
                <Link
                    href={'/platform/business/' + params.row.id.toString()}
                    target="_blank"
                >
                    {params.row.name}
                </Link>
            ),
        },
        {
            field: '_',
            headerName: '',
            editable: false,
            sortable: false,
            flex: 0.4,
            renderCell: (params: GridRenderCellParams) => (
                <Box display="flex" justifyContent="center">
                    <IconButton
                        onClick={() => deleteFromWatchlist(params.row.id)}
                    >
                        <VisibilityOff />
                    </IconButton>
                </Box>
            ),
        },
    ]

    if (count === -1) {
        return (
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                    height: 400,
                    width: '100%',
                }}
            >
                <NoSearch
                    title="No Watchlist Selected"
                    subtitle="Please select a watchlist."
                />
            </Box>
        )
    }

    if (count === 0) {
        return (
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                    height: 400,
                    width: '100%',
                }}
            >
                <NoSearchResult
                    title="Watchlist is Empty."
                    subtitle="Please add businesses to your watchlist."
                />
            </Box>
        )
    }

    return (
        <Box
            sx={{
                height: '100vh',
                width: '100%',
            }}
        >
            <DataGrid
                rows={rows}
                columns={columns}
                pageSize={51}
                disableColumnFilter
                disableColumnMenu
                disableSelectionOnClick
                disableColumnSelector
                rowCount={count}
                paginationMode="client"
                getRowHeight={() => 'auto'}
                sx={{
                    border: 0,
                    '& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cell:focus-within':
                        {
                            outline: 'none',
                        },
                    '& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus':
                        {
                            outline: 'none',
                        },
                    '.MuiDataGrid-columnSeparator': {
                        display: 'none',
                    },
                    '& .MuiDataGrid-columnHeaderTitle': {
                        fontWeight: 'bold',
                        color: '#262d50',
                    },
                    '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
                        py: '8px',
                    },
                    '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
                        py: '15px',
                    },
                    '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell':
                        { py: '22px' },
                }}
            />
        </Box>
    )
}

export default Table
