import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface AccountState {
    email: string | null
    loggedIn: boolean
    userType: string | null
    businessId: number | null
    isSuper: boolean
}

const initialState: AccountState = {
    email: null,
    loggedIn: false,
    userType: null,
    businessId: null,
    isSuper: false,
}

export const accountSlice = createSlice({
    name: 'account',
    initialState,
    reducers: {
        login: (state, action: PayloadAction<AccountState>) => {
            state.email = action.payload.email
            state.loggedIn = action.payload.loggedIn
            state.userType = action.payload.userType
            state.businessId = action.payload.businessId
            state.isSuper = action.payload.isSuper
        },
        logout: (state) => {
            state = initialState
        },
    },
})

export const { login, logout } = accountSlice.actions

export default accountSlice
