import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { setOpen as changeNotif } from '../../../../../slices/notification-slice'

import {
    Button,
    Dialog,
    DialogContent,
    DialogActions,
    Box,
    DialogTitle,
    Typography,
    Tooltip,
    Divider,
} from '@mui/material'

import { useForm, Controller } from 'react-hook-form'

import WorldTextField from '../../../../ui-components/text-field'
import WatchlistTable, { WatchlistType } from './watchlist-table'

import { upsertWatchlist } from '../../../../../api/v1/watchlist'
import { getWatchlists as getWatchlistsAPI } from '../../../../../api/v1/watchlist'
import { Watchlist } from '../../../../../utils/models/api-models'

import {
    Failure,
    CreateWatchlist,
    AddBusinessToWatchlist,
    RemoveWatchlist,
} from '../../../../../utils/constants/messages'

interface AddToWatchlistProps {
    open: boolean
    setOpen: (val: boolean) => void
    id: number
}

const AddToWatchlist = ({ open, setOpen, id }: AddToWatchlistProps) => {
    const dispatch = useDispatch()
    const [disable, setDisable] = useState(false)
    const [watchlists, setWatchlists] = useState<Record<number, WatchlistType>>(
        {}
    )

    const { control, trigger, reset, getValues } = useForm({
        defaultValues: {
            watchlistName: '',
        },
    })

    const addWatchlist = async () => {
        setDisable(true)

        if (!(await trigger())) {
            setDisable(false)
            return
        }

        const { watchlistName } = getValues()
        const { success } = await upsertWatchlist({
            name: watchlistName,
        })

        await getWatchlists()

        dispatch(
            changeNotif({
                open: true,
                message: success ? CreateWatchlist : Failure,
            })
        )
        setDisable(false)
        reset()
    }

    const getWatchlists = async () => {
        const { success, data } = await getWatchlistsAPI()
        let formattedData: Record<number, WatchlistType> = {}
        if (success && data) {
            data.map((d: Watchlist) => {
                const business: number[] = d?.['businesses'].map((i) => {
                    return (i?.id as number) ?? -1
                })
                const businessSet = new Set(business)
                formattedData[d?.id] = {
                    buisnessIds: businessSet,
                    id: d?.id as number,
                    includesNewId: businessSet.has(id),
                    name: d?.name as string,
                }
            })
        }
        setWatchlists(formattedData)
    }

    const updateWatchlist = async (watchlistId: number) => {
        const info = watchlists[watchlistId] ?? null
        if (!info) return

        if (info.includesNewId) {
            info.buisnessIds.delete(id)
        } else {
            info.buisnessIds.add(id)
        }

        const { success } = await upsertWatchlist({
            id: +info['id'],
            name: info['name'],
            businessIds: Array.from(info['buisnessIds']),
        })

        await getWatchlists()

        dispatch(
            changeNotif({
                open: true,
                message: success
                    ? info.includesNewId
                        ? RemoveWatchlist
                        : AddBusinessToWatchlist
                    : Failure,
            })
        )
    }

    useEffect(() => {
        if (open === true) {
            getWatchlists()
        }
    }, [open])

    const maxLimit = Object.keys(watchlists).length >= 25

    return (
        <>
            <Dialog fullWidth open={open} onClose={() => setOpen(false)}>
                <DialogTitle sx={{ fontWeight: 'bold' }}>Watchlist</DialogTitle>
                <DialogContent>
                    <Box>
                        <Typography variant="h6" mt={2}>
                            Create a Watchlist
                        </Typography>
                        <Typography variant="caption" sx={{ width: 150 }}>
                            Create a watchlist to add this business to.
                        </Typography>
                        <Box my={2}>
                            <Controller
                                name={'watchlistName' as const}
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <WorldTextField
                                        placeholder="Enter watchlist name"
                                        value={field.value}
                                        disabled={disable || maxLimit}
                                        error={Boolean(error)}
                                        fullWidth
                                        label="Watchlist Name"
                                        helperText={error?.message || ''}
                                        onChange={(e) =>
                                            field.onChange(e.target.value)
                                        }
                                    />
                                )}
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'Watchlist name is required',
                                    },
                                    maxLength: {
                                        value: 50,
                                        message:
                                            'Watchlist name must be 50 characters or less',
                                    },
                                    pattern: {
                                        value: /^[^\s]+(?:$|.*[^\s]+$)/,
                                        message:
                                            'Watchlist name can not start or end with a space',
                                    },
                                }}
                            />
                        </Box>
                        <Tooltip
                            title={
                                maxLimit
                                    ? 'Maximum number of watchlists (25) has been reached.'
                                    : ''
                            }
                        >
                            <Box component="span">
                                <Button
                                    variant="text"
                                    color="primary"
                                    sx={{
                                        textTransform: 'none',
                                    }}
                                    disabled={maxLimit}
                                    onClick={() => addWatchlist()}
                                >
                                    Create Watchlist
                                </Button>
                            </Box>
                        </Tooltip>
                    </Box>

                    <Divider variant="middle" sx={{ mt: 6, mb: 2 }} />

                    <Box mb={3}>
                        <Typography variant="h6">
                            Add to Existing Watchlist
                        </Typography>
                        <Typography variant="caption" sx={{ width: 150 }}>
                            Add the business to a created watchlist.
                        </Typography>
                        <Box mt={2}>
                            <WatchlistTable
                                rows={watchlists}
                                updateWatchlist={updateWatchlist}
                            />
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions sx={{ mb: 2, mx: 2 }}>
                    <Button
                        variant="text"
                        color="primary"
                        onClick={() => setOpen(false)}
                        sx={{
                            textTransform: 'none',
                        }}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default AddToWatchlist
