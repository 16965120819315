import { Typography, useMediaQuery, Box } from '@mui/material'

import NavMenu from './navbar'
import Footer from './footer'

const LandingPage = () => {
    const isMobile = useMediaQuery('(max-width: 960px)')

    return (
        <>
            <NavMenu />
            <Box maxWidth={1200} mx="auto" my={6}>
                <Box width="100%" display="flex" justifyContent="center">
                    <Typography
                        maxWidth={isMobile ? 600 : 800}
                        textAlign="center"
                        lineHeight={1.25}
                        fontSize={isMobile ? 40 : 72}
                        fontFamily='"Inter", sans-serif'
                        color="#000000"
                        m={3}
                        fontWeight="bold"
                        mt={isMobile ? 5 : 3}
                    >
                        MANAGING{' '}
                        <Box component="span" color="#262d50">
                            DBE
                        </Box>{' '}
                        GOALS JUST GOT EASIER
                    </Typography>
                </Box>
                <Box width="100%" mt={3}>
                    <Typography
                        textAlign="center"
                        maxWidth={600}
                        mx="auto"
                        fontSize={22}
                        px={isMobile ? 2 : 0}
                    >
                        Whether you are a prime, a government agency or a
                        certified business, managing the process to fulfill a
                        DBE goal can be complicated. We help simplify engagement
                        to improve communication, bid management and reporting.
                    </Typography>
                </Box>
                <Box width="100%" mt={6}>
                    <Typography
                        textAlign="center"
                        fontSize={isMobile ? 25 : 32}
                        fontFamily='"Inter", sans-serif'
                        color="#000000"
                        fontWeight="bold"
                        px={isMobile ? 2 : 0}
                    >
                        WHAT WE OFFER
                    </Typography>
                </Box>
                <Box
                    width="100%"
                    mt={3}
                    px={isMobile ? 0 : 2}
                    height={isMobile ? '100%' : '40vh'}
                    display={isMobile ? 'block' : 'flex'}
                    justifyContent="space-between"
                >
                    <Box
                        px={4}
                        py={2}
                        bgcolor="white"
                        boxShadow="rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px"
                        borderRadius={2}
                        width={275}
                        height={175}
                        mx={isMobile ? 'auto' : 0}
                        mt={isMobile ? 2 : 0}
                    >
                        <Typography variant="body1" fontWeight="bold">
                            For Primes
                        </Typography>
                        <Typography variant="body1" mt={1}>
                            + Intuitive search directory
                        </Typography>
                        <Typography variant="body1">
                            + Qualify and contact confidentially
                        </Typography>
                        <Typography variant="body1">
                            + Manage quote process
                        </Typography>
                        <Typography variant="body1">
                            + Track good faith efforts
                        </Typography>
                        <Typography variant="body1">
                            + Generate comprehensive reports
                        </Typography>
                    </Box>

                    <Box
                        px={4}
                        py={2}
                        bgcolor="white"
                        boxShadow="rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px"
                        borderRadius={2}
                        width={275}
                        height={175}
                        mx={isMobile ? 'auto' : 0}
                        mt={isMobile ? 2 : 0}
                    >
                        <Typography variant="body1" fontWeight="bold">
                            For Certified Businesses
                        </Typography>
                        <Typography variant="body1" mt={1}>
                            + Expand your opportunities
                        </Typography>
                        <Typography variant="body1">
                            + Claim your profile
                        </Typography>
                        <Typography variant="body1">
                            + Network with prime contractors
                        </Typography>
                        <Typography variant="body1">
                            + Access business support
                        </Typography>
                        <Typography variant="body1">
                            + Receive prompt payment
                        </Typography>
                    </Box>

                    <Box
                        px={4}
                        py={2}
                        bgcolor="white"
                        boxShadow="rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px"
                        borderRadius={2}
                        width={275}
                        height={175}
                        mx={isMobile ? 'auto' : 0}
                        mt={isMobile ? 2 : 0}
                    >
                        <Typography variant="body1" fontWeight="bold">
                            For Government Agencies
                        </Typography>
                        <Typography variant="body1" mt={1}>
                            + Improve DBE participation rates
                        </Typography>
                        <Typography variant="body1">
                            + Simplify reporting and data analysis
                        </Typography>
                        <Typography variant="body1">
                            + Improve transparency
                        </Typography>
                        <Typography variant="body1">
                            + Easily support DBE development
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Footer />
        </>
    )
}

export default LandingPage
