import { Box, Button, Divider, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import Table from './table'

import { getMyBusiness } from '../../../api/v1/business'
import { upsertService, deleteService } from '../../../api/v1/service'

import WorldTextField from '../../ui-components/text-field'

import { Service as ServiceType } from '../../../utils/models/api-models'
import {
    Failure,
    CreateService,
    RemoveService,
} from '../../../utils/constants/messages'

import { setOpen } from '../../../slices/notification-slice'

const Services = ({
    fetchBusiness,
    services,
}: {
    fetchBusiness: () => Promise<void>
    services: ServiceType[]
}) => {
    const { control, reset, getValues, trigger } = useForm({
        defaultValues: {
            title: '',
            description: '',
        },
    })

    const dispatch = useDispatch()
    const [disable, setDisable] = useState(false)

    const handleSubmit = async () => {
        setDisable(true)
        if (!(await trigger())) {
            setDisable(false)
            return
        }
        const { title, description } = getValues()
        const { success } = await upsertService({
            title: title.trim(),
            description: description.trim(),
        })
        reset()
        dispatch(
            setOpen({
                open: true,
                message: success ? CreateService : Failure,
            })
        )
        await fetchBusiness()
        setDisable(false)
    }

    const handleDelete = async (id: number) => {
        setDisable(true)
        const { success } = await deleteService({ id: id })
        dispatch(
            setOpen({
                open: true,
                message: success ? RemoveService : Failure,
            })
        )
        await fetchBusiness()
        setDisable(false)
    }

    return (
        <>
            <Typography variant="h6" mt={2}>
                Add a new service
            </Typography>
            <Typography variant="caption" sx={{ width: 150 }}>
                Let contractors know about all the services your business is
                offering.
            </Typography>
            <Box display="flex" justifyContent="center" mt={6}>
                <Typography variant="subtitle2" width={200} sx={{ mr: 4 }}>
                    Service Name
                </Typography>
                <Controller
                    name={'title' as const}
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <WorldTextField
                            placeholder="Enter service name"
                            value={field.value}
                            variant="outlined"
                            disabled={disable}
                            error={Boolean(error)}
                            helperText={error?.message || ''}
                            onChange={(e) => field.onChange(e.target.value)}
                            sx={{
                                width: 400,
                            }}
                        />
                    )}
                    rules={{
                        required: {
                            value: true,
                            message: 'Service name is required',
                        },
                        maxLength: {
                            value: 200,
                            message:
                                'Service name must be 200 characters or less',
                        },
                        pattern: {
                            value: /^[^\s]+(?:$|.*[^\s]+$)/,
                            message:
                                'Service name can not start or end with a space',
                        },
                    }}
                />
            </Box>
            <Box display="flex" justifyContent="center" mt={4}>
                <Typography variant="subtitle2" width={200} sx={{ mr: 4 }}>
                    Service Description
                </Typography>
                <Controller
                    name={'description' as const}
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <WorldTextField
                            placeholder="Enter information about your service"
                            value={field.value}
                            multiline
                            minRows={3}
                            maxRows={5}
                            variant="outlined"
                            disabled={disable}
                            error={Boolean(error)}
                            helperText={error?.message || ''}
                            onChange={(e) => field.onChange(e.target.value)}
                            sx={{
                                width: 400,
                            }}
                        />
                    )}
                    rules={{
                        required: {
                            value: true,
                            message: 'Service description is required',
                        },
                        maxLength: {
                            value: 1000,
                            message:
                                'Service description must be 1000 characters or less',
                        },
                        pattern: {
                            value: /^[^\s]+(?:$|.*[^\s]+$)/,
                            message:
                                'Service description can not start or end with a space',
                        },
                    }}
                />
            </Box>
            <Box display="flex" justifyContent="center" mt={6}>
                <Button
                    variant="text"
                    color="primary"
                    sx={{
                        px: 2,
                    }}
                    disabled={disable}
                    onClick={() => handleSubmit()}
                >
                    Create New Service
                </Button>
            </Box>
            <Divider variant="middle" sx={{ mt: 6, mb: 2 }} />
            <Typography variant="h6">Existing services</Typography>
            <Typography variant="caption" sx={{ width: 150 }}>
                View and delete your existing services.
            </Typography>
            <Box mt={4}>
                {services.length === 0 ? (
                    <Typography variant="subtitle2">
                        There are no existing services.
                    </Typography>
                ) : (
                    <Table
                        headers={['Service', 'Description']}
                        rows={services}
                        values={['title', 'description']}
                        onDelete={handleDelete}
                    />
                )}
            </Box>
        </>
    )
}

export default Services
