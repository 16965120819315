import { Box, Button, Divider, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import WorldSearchSingleSelect from '../../ui-components/search-single-select'

import { getWatchlists } from '../../../api/v1/watchlist'

import NameEditCell from './name-edit-cell'
import Create from './create'
import Table from './table'

import { Option } from '../../../utils/models/api-models'

import { Watchlist as WatchlistType } from '../../../utils/models/api-models'

const Watchlist = () => {
    const [searchParams, setSearchParams] = useSearchParams()

    const [watchlists, setWatchlists] = useState<Option[]>([])
    const [watchlistData, setWatchlistData] = useState<Record<number, any>>({})
    const [selectedWatchlist, setSelectedWatchlist] = useState<Option | null>(
        null
    )
    const [openCreate, setOpenCreate] = useState(false)

    const getMyWatchlists = async (current?: Option) => {
        const { success, data } = await getWatchlists()

        const formattedData: Option[] = []
        const watchlistDetails: Record<number, WatchlistType> = {}
        if (success && data) {
            data.map((d) => {
                formattedData.push({
                    label: d.name,
                    value: d.id,
                })
                watchlistDetails[d.id] = d
            })
        }
        setWatchlists(formattedData)
        setWatchlistData(watchlistDetails)
        setSelectedWatchlist(current ?? null)
    }

    const getSearchParams = () => {
        const id = searchParams.get('id')
        if (!id || watchlists.length === 0) {
            return
        }
        if (!watchlistData?.[+id]) {
            return
        }
        setSelectedWatchlist({
            label: watchlistData?.[+id]?.name,
            value: +id,
        })
    }

    useEffect(() => {
        getMyWatchlists()
    }, [])

    useEffect(() => {
        getSearchParams()
    }, [searchParams, watchlistData])

    return (
        <>
            <Box>
                <Box display="flex" justifyContent="space-between">
                    <Typography
                        variant="h4"
                        color="black"
                        fontFamily='"Inter", sans-serif'
                        fontWeight="bold"
                    >
                        Watchlist
                    </Typography>
                    <Button
                        onClick={() => setOpenCreate(true)}
                        variant="text"
                        color="primary"
                        sx={{
                            px: 2,
                        }}
                    >
                        Create
                    </Button>
                </Box>
                <Box
                    mt={4}
                    px={4}
                    py={2}
                    bgcolor="white"
                    boxShadow="rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px"
                    borderRadius={2}
                >
                    <Typography variant="h6" fontWeight="bold" mb={2}>
                        Select a Watchlist
                    </Typography>
                    <WorldSearchSingleSelect
                        label="Watchlist"
                        placeholder="Select watchlist"
                        options={watchlists}
                        onChange={(selected) =>
                            setSearchParams({
                                id: selected?.value.toString() || '-1',
                            })
                        }
                        value={selectedWatchlist}
                    />
                    <Divider sx={{ my: 4 }} />
                    <Box minHeight={300}>
                        <NameEditCell
                            watchlistId={
                                selectedWatchlist?.value
                                    ? +selectedWatchlist?.value
                                    : undefined
                            }
                            name={selectedWatchlist?.label as string}
                            fetchWatchlists={getMyWatchlists}
                        />
                        <Table
                            rows={
                                watchlistData[
                                    selectedWatchlist?.value as number
                                ]?.businesses ?? []
                            }
                            count={
                                watchlistData[
                                    selectedWatchlist?.value as number
                                ]?.businesses.length ?? -1
                            }
                            watchlist={
                                watchlistData[
                                    selectedWatchlist?.value as number
                                ] ?? {}
                            }
                            fetchWatchlists={getMyWatchlists}
                        />
                    </Box>
                </Box>
            </Box>
            <Create
                open={openCreate}
                setOpen={setOpenCreate}
                numWatchlists={watchlists.length}
                fetchWatchlists={getMyWatchlists}
            />
        </>
    )
}

export default Watchlist
