import { Autocomplete, SxProps } from '@mui/material'
import { useState } from 'react'
import WorldTextField from './text-field'
import { Option } from '../../utils/models/api-models'

interface WorldSearchSelectProps {
    label: string
    placeholder: string
    options: Option[]
    value: Option[]
    onChange: (value: Option[]) => void
    sx?: SxProps
    allowNewOptions?: boolean
}

const WorldSearchSelect = ({
    label,
    placeholder,
    options,
    value,
    onChange,
    sx,
    allowNewOptions = false,
}: WorldSearchSelectProps) => {
    const [internalOptions, setInternalOptions] = useState<Option[]>(options)

    const handleAddOption = (newValue: string) => {
        const newOption = { label: newValue, value: newValue } as Option
        const updatedOptions = [...internalOptions, newOption]
        setInternalOptions(updatedOptions)
        return newOption
    }

    return (
        <Autocomplete
            sx={sx}
            multiple
            freeSolo={allowNewOptions}
            limitTags={5}
            options={internalOptions}
            onChange={(_, newValue) => {
                if (allowNewOptions) {
                    const newOptions = newValue.filter(
                        (option) => typeof option === 'string'
                    ) as string[]
                    const addedOptions = newOptions.map((newOption) =>
                        handleAddOption(newOption)
                    )
                    const existingOptions = newValue.filter(
                        (option) => typeof option !== 'string'
                    ) as Option[]
                    onChange([...existingOptions, ...addedOptions])
                } else {
                    onChange(newValue as Option[])
                }
            }}
            value={value}
            getOptionLabel={(option) => option.label as string}
            renderInput={(params) => (
                <WorldTextField
                    {...params}
                    label={label}
                    placeholder={placeholder}
                    onKeyDown={(event) => {
                        if (
                            allowNewOptions &&
                            event.key === 'Enter' &&
                            //@ts-ignore
                            event.target.value
                        ) {
                            const newOption = handleAddOption(
                                //@ts-ignore
                                event.target.value
                            )
                            onChange([...value, newOption])
                        }
                    }}
                />
            )}
        />
    )
}

export default WorldSearchSelect
