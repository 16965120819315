import WorldSelect from '../../ui-components/select'
import { useFormContext, Controller } from 'react-hook-form'

import { AccountType } from '../../../utils/constants/enums'

const typeOptions = [
    {
        label: 'Certified Disadvantaged Business',
        value: AccountType.BUSINESS,
    },
    {
        label: 'Prime Contractor',
        value: AccountType.CONTRACTOR,
    },
    {
        label: 'Government Entity',
        value: AccountType.GOVERNMENT,
    },
]

const Type = () => {
    const { control } = useFormContext()

    return (
        <>
            <Controller
                name={'type' as const}
                control={control}
                render={({ field, fieldState: { error } }) => (
                    <WorldSelect
                        options={typeOptions}
                        fullWidth
                        label="Account Type"
                        value={field.value}
                        error={Boolean(error)}
                        onChange={(event) => field.onChange(event.target.value)}
                    />
                )}
            />
        </>
    )
}

export default Type
