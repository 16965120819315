import WorldTextField from '../../ui-components/text-field'
import { Box, Button, Typography } from '@mui/material'

import { Controller, useForm } from 'react-hook-form'

import { useState } from 'react'
import { useDispatch } from 'react-redux'

import changePasswordAPI from '../../../api/v1/user/change-password'

import { Failure, UpdatePassword } from '../../../utils/constants/messages'
import { setOpen } from '../../../slices/notification-slice'

const ChangePassword = () => {
    const { control, trigger, getValues, reset } = useForm({
        defaultValues: {
            currentPassword: '',
            newPassword: '',
        },
    })

    const [disable, setDisable] = useState(false)
    const dispatch = useDispatch()

    const handlePasswordChange = async () => {
        setDisable(true)
        if (!(await trigger())) {
            setDisable(false)
            return
        }

        const { currentPassword, newPassword } = getValues()
        const { success } = await changePasswordAPI({
            oldPassword: currentPassword,
            newPassword,
        })

        dispatch(
            setOpen({
                open: true,
                message: success ? UpdatePassword : Failure,
            })
        )

        setDisable(false)

        if (success) {
            reset({
                currentPassword: '',
                newPassword: '',
            })
        }
    }

    return (
        <>
            <Typography variant="h6">Change your account password</Typography>
            <Typography variant="caption">
                Ensure that your password length is greater than 8 characters.
            </Typography>
            <Box mt={6} display="flex" justifyContent="center">
                <Typography variant="subtitle2" width={200}>
                    Current Password
                </Typography>
                <Controller
                    name={'currentPassword' as const}
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <WorldTextField
                            placeholder="Enter current password"
                            variant="outlined"
                            type="password"
                            value={field.value}
                            disabled={disable}
                            error={Boolean(error)}
                            helperText={error?.message || ''}
                            sx={{
                                width: 400,
                            }}
                            onChange={(e: React.BaseSyntheticEvent) =>
                                field.onChange(e.target.value)
                            }
                        />
                    )}
                    rules={{
                        required: {
                            value: true,
                            message: 'Password is required',
                        },
                        minLength: {
                            value: 8,
                            message: 'Password must be 8 characters or longer',
                        },
                    }}
                />
            </Box>
            <Box display="flex" justifyContent="center" mt={4}>
                <Typography variant="subtitle2" width={200}>
                    New Password
                </Typography>
                <Controller
                    name={'newPassword' as const}
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <WorldTextField
                            placeholder="Enter new password"
                            type="password"
                            variant="outlined"
                            disabled={disable}
                            value={field.value}
                            error={Boolean(error)}
                            helperText={error?.message || ''}
                            sx={{
                                width: 400,
                            }}
                            onChange={(e: React.BaseSyntheticEvent) =>
                                field.onChange(e.target.value)
                            }
                        />
                    )}
                    rules={{
                        required: {
                            value: true,
                            message: 'Password is required',
                        },
                        minLength: {
                            value: 8,
                            message: 'Password must be 8 characters or longer',
                        },
                    }}
                />
            </Box>
            <Box mt={6} display="flex" justifyContent="center">
                <Button
                    variant="text"
                    color="primary"
                    sx={{
                        px: 2,
                    }}
                    disabled={disable}
                    onClick={handlePasswordChange}
                >
                    Change Password
                </Button>
            </Box>
        </>
    )
}

export default ChangePassword
