import callAPI from '../api-base'

import {
    ClientCommunication,
    ClientCommunicationRaw,
    FilterResponse,
    GoodFaithEffortReport,
    GoodFaithEffortReportRaw,
    OpportunityGroup,
    OpportunityGroupRaw,
    Paginated,
    Response,
    StatusResponse,
} from '../../../utils/models/api-models'

import { sanitizeOpportunity } from '../opportunity'

const OpportunityGroupAPI = () => {
    const sanitizeOpportunityGroup = ({
        opportunityGroup,
    }: {
        opportunityGroup: OpportunityGroupRaw
    }): OpportunityGroup => {
        const { opportunities, ...rest } = opportunityGroup

        return {
            //@ts-ignore
            opportunities: opportunities.map((i) => {
                const { end_date, ...info } = i
                return {
                    endDate: end_date,
                    ...info,
                }
            }),
            ...rest,
        }
    }

    const santizeCommunication = (
        value: Record<number, Record<number, ClientCommunicationRaw[]>>
    ): Record<number, Record<number, ClientCommunication[]>> => {
        let results: Record<number, Record<number, ClientCommunication[]>> = {}

        Object.keys(value).forEach((k: string) => {
            results[+k] = {}
            Object.keys(value[+k]).forEach((kk: string) => {
                results[+k][+kk] = value[+k][+kk].map((v) => ({
                    id: v.id,
                    opportunity: v.opportunity,
                    status: v.status,
                    message: v.message,
                    type: v.type,
                    business: v.business,
                    action: v.action,
                    createdAt: v.created_at,
                    automated: v.automated,
                    note: v.note,
                }))
            })
        })
        return results
    }

    const sanitizeInvitees = (
        value: Record<
            number,
            {
                id: number
                name: string
                contact_name: string
                phone_number: string
                email: string
            }[]
        >
    ): Record<
        number,
        {
            id: number
            name: string
            contactName: string
            phoneNumber: string
            email: string
        }[]
    > => {
        let results: Record<
            number,
            {
                id: number
                name: string
                contactName: string
                phoneNumber: string
                email: string
            }[]
        > = {}

        Object.keys(value).forEach((k: string) => {
            results[+k] = value[+k].map((v) => ({
                id: v.id,
                name: v.name,
                contactName: v.contact_name,
                phoneNumber: v.phone_number,
                email: v.email,
            }))
        })

        return results
    }

    const sanitizeGoodFaithEffortReport = (
        value: GoodFaithEffortReportRaw
    ): GoodFaithEffortReport => {
        return {
            opportunityGroupName: value.opportunity_group_name,
            opportunities: value.opportunities.map((o) =>
                sanitizeOpportunity(o)
            ),
            invitees: sanitizeInvitees(value.invitees),
            communication: santizeCommunication(value.communication),
            templates: value.templates,
        }
    }

    const getOpportunityGroupsFilter = ():
        | FilterResponse
        | Promise<FilterResponse> => {
        return callAPI({
            url: 'filters/opportunity-groups/',
            type: 'get',
            body: {},
            auth: true,
        })
            .then((res: any) => {
                const { success, data } = res.data
                return {
                    success: success,
                    data: data,
                }
            })
            .catch((err: any) => {
                return {
                    success: false,
                    data: null,
                }
            })
    }

    const getOpportunityGroups = ({
        page = 1,
    }: {
        page?: number
    }):
        | Response<Paginated<OpportunityGroup>>
        | Promise<Response<Paginated<OpportunityGroup>>> => {
        let url = `opportunity-group/?page=${page.toString()}`

        return callAPI({
            url: url,
            type: 'get',
            body: {},
            auth: true,
        })
            .then((res: any) => {
                const { success, data } = res.data
                return {
                    success: success,
                    data: {
                        numPages: data?.num_pages || 0,
                        count: data?.count || 0,
                        data: data?.data.map((i: OpportunityGroupRaw) =>
                            sanitizeOpportunityGroup({ opportunityGroup: i })
                        ),
                    },
                }
            })
            .catch((err: any) => {
                return {
                    success: false,
                    data: null,
                }
            })
    }

    const deleteOpportunityGroup = ({
        id,
    }: {
        id: number
    }): StatusResponse | Promise<StatusResponse> => {
        return callAPI({
            url: 'opportunity-group/',
            type: 'delete',
            body: { id: id },
            auth: true,
        })
            .then((res: any) => {
                const { success } = res.data
                return { success: success }
            })
            .catch((err: any) => {
                return {
                    success: false,
                }
            })
    }

    const upsertOpportunityGroup = ({
        name,
        id,
    }: {
        name: string
        id?: number
    }): StatusResponse | Promise<StatusResponse> => {
        let body = {}
        if (id) {
            body = {
                name,
                id,
            }
        } else {
            body = {
                name,
            }
        }

        return callAPI({
            url: 'opportunity-group/',
            type: 'post',
            body: body,
            auth: true,
        })
            .then((res: any) => {
                const { success } = res.data
                return { success: success }
            })
            .catch((err: any) => {
                return {
                    success: false,
                }
            })
    }

    const getGoodFaithEffortReport = ({
        id,
    }: {
        id: number
    }):
        | Response<GoodFaithEffortReport>
        | Promise<Response<GoodFaithEffortReport>> => {
        return callAPI({
            url:
                'opportunity-group/good-faith-effort-report/' +
                id.toString() +
                '/',
            type: 'get',
            body: {},
            auth: true,
        })
            .then((res: any) => {
                const { success, data } = res.data
                return {
                    success: success,
                    data: sanitizeGoodFaithEffortReport(data),
                }
            })
            .catch((err: any) => {
                return {
                    success: false,
                    data: null,
                }
            })
    }

    return {
        deleteOpportunityGroup,
        getOpportunityGroupsFilter,
        getOpportunityGroups,
        upsertOpportunityGroup,
        getGoodFaithEffortReport,
    }
}

export const {
    getOpportunityGroupsFilter,
    getOpportunityGroups,
    deleteOpportunityGroup,
    upsertOpportunityGroup,
    getGoodFaithEffortReport,
} = OpportunityGroupAPI()
