import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack,
} from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import WorldSearchSingleSelect from '../../../../ui-components/search-single-select'
import { useState } from 'react'
import WorldTextField from '../../../../ui-components/text-field'
import { setOpen as setNotification } from '../../../../../slices/notification-slice'
import { createClientCommunication } from '../../../../../api/v1/client-communication'
import {
    CreateLogCommunication,
    Failure,
} from '../../../../../utils/constants/messages'

const typeOptions = [
    {
        label: 'Email',
        value: 0,
    },
    {
        label: 'SMS',
        value: 1,
    },
    {
        label: 'Phone Call',
        value: 2,
    },
    {
        label: 'Other',
        value: 3,
    },
]

export type Info = {
    opportunity: number
    businessName: string
    business: number
}

const LogCommunication = ({
    info,
    open,
    setOpen,
}: {
    info: Info | undefined
    open: boolean
    setOpen: (val: boolean) => void
}) => {
    const [disable, setDisable] = useState(false)
    const { getValues, control, trigger, reset } = useForm({
        defaultValues: {
            type: typeOptions[0],
            note: '',
        },
    })

    const addNote = async () => {
        setDisable(true)
        if (await trigger()) {
            const { note, type } = getValues()
            const { success } = await createClientCommunication({
                opportunity: info?.opportunity || -1,
                type: type.value as number,
                note: note,
                business: info?.business || -1,
            })

            setOpen(false)
            setNotification({
                message: success ? CreateLogCommunication : Failure,
                open: true,
            })
            reset()
        }
        setDisable(false)
        return
    }

    return (
        <Dialog
            fullWidth
            key="communication-log-dialog"
            open={open}
            onClose={() => {
                setOpen(false)
                reset()
            }}
        >
            <DialogTitle sx={{ fontWeight: 'light' }}>
                Log Communication - {info?.businessName}
            </DialogTitle>
            <DialogContent>
                <Stack spacing={2} my={2}>
                    <Controller
                        name={'type' as const}
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <WorldSearchSingleSelect
                                label="Type"
                                placeholder="Select type..."
                                options={typeOptions}
                                onChange={(selected) => {
                                    field.onChange(selected)
                                }}
                                disable={disable}
                                error={error ? Boolean(error) : false}
                                errorMessage={error?.message || ''}
                                value={field.value}
                                variant="outlined"
                            />
                        )}
                        rules={{
                            required: {
                                value: true,
                                message: 'Type is required',
                            },
                        }}
                    />
                    <Controller
                        name={'note' as const}
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <WorldTextField
                                placeholder={`I spoke to ${info?.businessName} today about...`}
                                label="Note"
                                value={field.value}
                                onChange={(e: React.BaseSyntheticEvent) =>
                                    field.onChange(e.target.value)
                                }
                                error={Boolean(error)}
                                multiline
                                minRows={3}
                                helperText={error?.message || ''}
                            />
                        )}
                        rules={{
                            required: {
                                value: true,
                                message: 'Note is required',
                            },
                            maxLength: {
                                value: 1000,
                                message:
                                    'Note must be less than 1000 characters',
                            },
                        }}
                    />
                </Stack>
            </DialogContent>
            <DialogActions
                sx={{ justifyContent: 'space-between', mx: 2, mb: 2 }}
            >
                <Button
                    variant="text"
                    color="secondary"
                    sx={{
                        width: 100,
                    }}
                    onClick={() => {
                        setOpen(false)
                        reset()
                    }}
                >
                    Cancel
                </Button>
                <Button
                    variant="text"
                    color="primary"
                    sx={{
                        width: 100,
                    }}
                    onClick={() => addNote()}
                >
                    Add Note
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default LogCommunication
