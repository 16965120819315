import { Box, Typography } from '@mui/material'

import Search from '../../../../assets/images/noResults.svg'

const NoSearchResult = ({
    title,
    subtitle,
}: {
    title: string
    subtitle?: string
}) => {
    return (
        <>
            <Box
                textAlign="center"
                height="100%"
                alignItems="center"
                display="flex"
                justifyContent="center"
            >
                <Box>
                    <Box mb={2}>
                        <img src={Search} height="50%" width="50%" alt="done" />
                    </Box>
                    <Typography
                        fontWeight="bold"
                        variant="body1"
                        textAlign="center"
                    >
                        {title}
                    </Typography>
                    {subtitle && (
                        <Typography variant="caption" textAlign="center">
                            {subtitle}
                        </Typography>
                    )}
                </Box>
            </Box>
        </>
    )
}

export default NoSearchResult
