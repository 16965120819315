import { Grid, Typography, Link, useMediaQuery } from '@mui/material'

import RegistrationStepper from './stepper'

const Register = (): JSX.Element => {
    const isMobile = useMediaQuery('(max-width: 650px)')

    return (
        <>
            <Grid
                container
                justifyContent="flex-start"
                px={10}
                mt={10}
                direction="column"
                alignItems="center"
                textAlign="center"
            >
                <Grid item>
                    <Typography
                        variant="h5"
                        color="#262d50"
                        fontWeight="bold"
                        fontFamily='"Montserrat", sans-serif'
                    >
                        dbeWorld
                    </Typography>
                </Grid>
                <Grid item mt={4}>
                    <Typography
                        variant="h3"
                        fontWeight="bold"
                        fontFamily='"Inter", sans-serif'
                    >
                        Register
                    </Typography>
                </Grid>
                <Grid item mt={6} width={isMobile ? 'auto' : 400}>
                    {isMobile ? (
                        <Typography variant="h5">
                            Please visit our website on your computer to
                            register.
                        </Typography>
                    ) : (
                        <RegistrationStepper />
                    )}
                </Grid>
            </Grid>
            <Grid
                container
                justifyContent="center"
                my={3}
                spacing={2}
                sx={{ fontSize: 12 }}
            >
                <Grid item>
                    <Link href="/login" sx={linkStyling}>
                        Login
                    </Link>
                </Grid>
                <Grid item>|</Grid>
                <Grid item>
                    <Link href="/resend-activation" sx={linkStyling}>
                        Resend Activation
                    </Link>
                </Grid>
                <Grid item>|</Grid>
                <Grid item>
                    <Link href="/reset-password" sx={linkStyling}>
                        Reset Password
                    </Link>
                </Grid>
            </Grid>
        </>
    )
}

const linkStyling = {
    color: 'black',
    textDecoration: 'none',
    '&:hover': {
        textDecoration: 'underline',
        color: '#262d50',
    },
}

export default Register
