import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { getGoodFaithEffortReport as getGoodFaithEffortReportAPI } from '../../../../../../api/v1/opportunity-group'
import Page from '../../../../../ui-components/page'
import { GoodFaithEffortReport as GoodFaithEffortReportModel } from '../../../../../../utils/models/api-models'
import {
    Box,
    Button,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material'
import { jsPDF } from 'jspdf'

const GoodFaithEffortReport = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const [goodFaithReport, setGoodFaithReport] =
        useState<GoodFaithEffortReportModel>()

    useEffect(() => {
        getGoodFaithEffortReport()
    }, [id])

    const getGoodFaithEffortReport = async () => {
        if (!id) {
            navigate('/404-not-found')
            return
        }
        const { data, success } = await getGoodFaithEffortReportAPI({ id: +id })
        if (!success || data === null) {
            navigate('/404-not-found')
            return
        }
        setGoodFaithReport(data)
    }

    const exportHtml = () => {
        var doc = new jsPDF()

        // Source HTMLElement or a string containing HTML.
        var elementHTML = document.getElementById('report')

        //@ts-ignore
        doc.html(elementHTML, {
            callback: function (doc) {
                // Save the PDF
                doc.save(goodFaithReport?.opportunityGroupName + '.pdf')
            },
            margin: [10, 10, 10, 10],
            autoPaging: 'text',
            x: 0,
            y: 0,
            width: 190, //target width in the PDF document
            windowWidth: 675, //window width in CSS pixels
        })
    }

    const createActionTable = (
        invitees: {
            id: number
            name: string
            contactName: string
            phoneNumber: string
            email: string
        }[],
        opportunityId: number
    ) => {
        return invitees.map((i) => (
            <Box>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell
                                sx={{
                                    fontWeight: 'bold',
                                    fontSize: 12,
                                    width: 180,
                                }}
                            >
                                DBE
                            </TableCell>
                            <TableCell
                                sx={{
                                    fontWeight: 'bold',
                                    fontSize: 12,
                                    width: 100,
                                }}
                            >
                                Contact
                            </TableCell>
                            <TableCell
                                sx={{
                                    fontWeight: 'bold',
                                    fontSize: 12,
                                    width: 100,
                                }}
                            >
                                Phone Number
                            </TableCell>
                            <TableCell
                                sx={{
                                    fontWeight: 'bold',
                                    fontSize: 12,
                                    width: 100,
                                }}
                            >
                                Email
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell
                                sx={{
                                    fontSize: 12,
                                }}
                            >
                                {i.name}
                            </TableCell>
                            <TableCell
                                sx={{
                                    fontSize: 12,
                                }}
                            >
                                {i.contactName}
                            </TableCell>
                            <TableCell
                                sx={{
                                    fontSize: 12,
                                }}
                            >
                                {i.phoneNumber}
                            </TableCell>
                            <TableCell
                                sx={{
                                    fontSize: 12,
                                }}
                            >
                                {i.email}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>

                {goodFaithReport?.communication[opportunityId][i.id] &&
                goodFaithReport?.communication[opportunityId][i.id].length >
                    0 ? (
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    sx={{
                                        fontWeight: 'bold',
                                        fontSize: 12,
                                        width: 50,
                                    }}
                                >
                                    Actions
                                </TableCell>
                                <TableCell
                                    sx={{
                                        fontWeight: 'bold',
                                        fontSize: 12,
                                        width: 50,
                                    }}
                                >
                                    Type
                                </TableCell>
                                <TableCell
                                    sx={{
                                        fontWeight: 'bold',
                                        fontSize: 12,
                                        width: 50,
                                    }}
                                >
                                    Automated
                                </TableCell>
                                <TableCell
                                    sx={{
                                        fontWeight: 'bold',
                                        fontSize: 12,
                                        width: 180,
                                    }}
                                >
                                    Timestamp
                                </TableCell>
                                <TableCell
                                    sx={{
                                        fontWeight: 'bold',
                                        fontSize: 12,
                                        width: 100,
                                    }}
                                >
                                    Status
                                </TableCell>
                                <TableCell
                                    sx={{
                                        fontWeight: 'bold',
                                        fontSize: 12,
                                    }}
                                >
                                    Note
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {goodFaithReport?.communication[opportunityId][
                                i.id
                            ].map((c) => (
                                <TableRow>
                                    <TableCell
                                        sx={{
                                            fontSize: 12,
                                        }}
                                    >
                                        {c.action}
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            fontSize: 12,
                                        }}
                                    >
                                        {c.type}
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            fontSize: 12,
                                        }}
                                    >
                                        {c.automated ? 'Yes' : 'No'}
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            fontSize: 12,
                                        }}
                                    >
                                        {c.createdAt}
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            fontSize: 12,
                                        }}
                                    >
                                        {c.status}
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            fontSize: 12,
                                        }}
                                    >
                                        {c.note}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                ) : (
                    <Typography fontSize={12}>
                        *No Communication with this DBE was logged
                    </Typography>
                )}
            </Box>
        ))
    }

    const handleEmail = (text: string) => {
        const textSplit = text.split('\n')
        return (
            <Box>
                {textSplit.map((str, idx) => (
                    <React.Fragment key={idx}>
                        {str.length === 0 ? (
                            <br />
                        ) : (
                            <Typography fontSize={12}>{str}</Typography>
                        )}

                        {/* {idx === array.length - 1 ? null : <br />} */}
                    </React.Fragment>
                ))}
            </Box>
        )
    }

    return (
        <Page
            header="Good Faith Effort Report"
            actionButtons={[
                <Button variant="text" color="primary" onClick={exportHtml}>
                    Export
                </Button>,
            ]}
        >
            <Box
                px={4}
                py={2}
                bgcolor="white"
                boxShadow="rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px"
                borderRadius={2}
            >
                <Box mt={4} mb={8}>
                    <Box id="report">
                        <Typography
                            fontSize={18}
                            fontWeight={'bold'}
                            textAlign="center"
                        >
                            {goodFaithReport?.opportunityGroupName} - Detailed
                            Activity Report
                        </Typography>
                        <Stack my={4} spacing={4}>
                            {goodFaithReport?.opportunities.map((o, index) => (
                                <Stack spacing={3}>
                                    <Typography fontSize={16} fontWeight="bold">
                                        {`Scope ${index + 1} - ${o.title}`}
                                    </Typography>
                                    <Stack spacing={1.15}>
                                        <Typography fontSize={14}>
                                            Description
                                        </Typography>
                                        <Typography fontSize={12}>
                                            {o.description || 'No Description'}
                                        </Typography>
                                    </Stack>
                                    <Stack spacing={1.15}>
                                        <Typography fontSize={14}>
                                            Technical Requirements
                                        </Typography>
                                        {o.technicalReqs ? (
                                            o?.technicalReqs.map((r, index) => (
                                                <Typography fontSize={12}>
                                                    {index + 1}. {r.value}
                                                </Typography>
                                            ))
                                        ) : (
                                            <Typography fontSize={12}>
                                                No Techncial Requirements
                                            </Typography>
                                        )}
                                    </Stack>
                                    <Stack spacing={1.15}>
                                        <Typography fontSize={14}>
                                            Commercial Requirements
                                        </Typography>
                                        {o.commercialReqs ? (
                                            o?.commercialReqs.map(
                                                (r, index) => (
                                                    <Typography fontSize={12}>
                                                        {index + 1}. {r.value}
                                                    </Typography>
                                                )
                                            )
                                        ) : (
                                            <Typography fontSize={12}>
                                                No Commercial Requirements
                                            </Typography>
                                        )}
                                    </Stack>
                                    <Stack spacing={1.15}>
                                        <Typography fontSize={14}>
                                            DBEs Included in the Outreach
                                        </Typography>
                                        {goodFaithReport.invitees[o.id].length >
                                        0 ? (
                                            <Stack spacing={5}>
                                                {createActionTable(
                                                    goodFaithReport.invitees[
                                                        o.id
                                                    ],
                                                    o.id
                                                )}
                                            </Stack>
                                        ) : (
                                            <Typography fontSize={12}>
                                                No DBEs
                                            </Typography>
                                        )}
                                    </Stack>
                                </Stack>
                            ))}
                        </Stack>
                        <Stack spacing={4}>
                            <Typography fontSize={16} fontWeight="bold">
                                Appendix A - Outreach Templates
                            </Typography>
                            {goodFaithReport?.opportunities.map((o, index) => (
                                <Stack spacing={3}>
                                    <Typography fontSize={16} fontWeight="bold">
                                        {`Scope ${index + 1} - ${o.title}`}
                                    </Typography>
                                    {goodFaithReport.templates[o.id].map(
                                        (t) => (
                                            <Stack spacing={1.15}>
                                                <Typography fontSize={14}>
                                                    {t.type} Template
                                                </Typography>
                                                <Typography fontSize={12}>
                                                    {t.type === 'EMAIL'
                                                        ? handleEmail(
                                                              t.template
                                                          )
                                                        : t.template}
                                                </Typography>
                                            </Stack>
                                        )
                                    )}
                                </Stack>
                            ))}
                        </Stack>
                    </Box>
                </Box>
            </Box>
        </Page>
    )
}
export default GoodFaithEffortReport
