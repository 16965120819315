import { Box, Typography } from '@mui/material'

interface Service {
    id: number
    title: string
    description: string
}

interface ServicesProps {
    services: Service[]
}

const Services = ({ services }: ServicesProps) => (
    <>
        <Typography variant="caption">Services</Typography>
        {services.length <= 0 && (
            <Box>
                <Typography>
                    The business has not updated this information yet.
                </Typography>
            </Box>
        )}
        {services.map((s) => (
            <Box>
                <Typography variant="body2">{s.title}</Typography>
                <Typography>{s.description}</Typography>
            </Box>
        ))}
    </>
)

export default Services
