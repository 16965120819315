import callAPI from '../api-base'

import {
    Response,
    Paginated,
    AnonBusiness,
} from '../../../utils/models/api-models'
import { sanitizeFindBusinessFromAPI } from './util'

interface findBusinessAPIParams {
    businessName: string
    businessEmail: string
    page: number
}

type FindBusinessResponse = Response<Paginated<AnonBusiness>>

export const findBusinessAPI = ({
    businessName,
    businessEmail,
    page,
}: findBusinessAPIParams):
    | FindBusinessResponse
    | Promise<FindBusinessResponse> => {
    return callAPI({
        url: 'find-business/',
        type: 'post',
        body: {
            business_name: businessName,
            business_email: businessEmail,
            page_number: page,
        },
    })
        .then((res: any) => {
            return {
                success: res.data.success,
                data: sanitizeFindBusinessFromAPI(res.data.data),
            }
        })
        .catch((err: any) => {
            return { success: false, data: null }
        })
}

export default findBusinessAPI
