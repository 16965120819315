import { Typography, Box, Link, Stack } from '@mui/material'
import { Contact } from '../../../../utils/models/api-models'
import React from 'react'

const phoneNumberFormat = (phone: string | null) => {
    if (phone === null) {
        return null
    }
    if (phone.length === 10) {
        const prefix = phone.substring(0, 3)
        const mid = phone.substring(3, 6)
        const end = phone.substring(6, 10)
        return `${prefix} ${mid} ${end}`
    } else if (phone.length === 11) {
        const prefix = phone.substring(1, 4)
        const mid = phone.substring(4, 7)
        const end = phone.substring(7, 11)
        return `+1 ${prefix} ${mid} ${end}`
    } else {
        return phone
    }
}

const ContactCard = ({ contact }: { contact: Contact }) => {
    const { name, phoneNumber, email, type } = contact

    return (
        <Box
            px={3}
            py={2}
            bgcolor="white"
            boxShadow="rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px"
            borderRadius={2}
            height="fit-content"
        >
            {type === 0 ? (
                <Typography variant="caption">Primary Contact</Typography>
            ) : (
                <Typography variant="caption">Secondary Contact</Typography>
            )}
            {name && <Typography>{name}</Typography>}
            {phoneNumber && (
                <Typography>{phoneNumberFormat(phoneNumber)}</Typography>
            )}
            {email && (
                <Typography sx={{ wordBreak: 'break-all' }}>{email}</Typography>
            )}
        </Box>
    )
}

const ContactInfo = ({ contacts }: { contacts: Contact[] }) => {
    const sortedContacts = contacts.sort((a, b) => (a.type === 0 ? -1 : 1))
    return (
        <Stack spacing={2}>
            {sortedContacts.map((contact, index) => (
                <ContactCard key={index} contact={contact} />
            ))}
        </Stack>
    )
}

export default ContactInfo
