import {
    Select,
    SelectProps,
    FormControl,
    InputLabel,
    MenuItem,
} from '@mui/material'

import { Option } from '../../utils/models/api-models'

interface SelectComponentProps extends SelectProps {
    options: Option[]
}

const WorldSelect = ({ ...props }: SelectComponentProps) => {
    const { options, error, sx, ...rest } = props

    return (
        <FormControl size={props.size} fullWidth={props.fullWidth}>
            <InputLabel
                error={error}
                htmlFor="name"
                sx={{ color: error ? 'red !important' : '#262d50 !important' }}
            >
                {props.label}
            </InputLabel>
            <Select
                name="name"
                error={error}
                disableUnderline
                sx={{
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#e2e2e1',
                        '&:hover': {
                            borderColor: '#e2e2e1',
                        },
                    },
                    ...sx,
                }}
                MenuProps={{
                    sx: {
                        '& .Mui-selected': {
                            backgroundColor: '#262d50 !important',
                            color: 'white',
                            '&:hover': {
                                opacity: 0.7,
                                backgroundColor: '#262d50',
                                color: 'white',
                            },
                        },
                        '& .MuiMenuItem-root': {
                            '&:hover': {
                                opacity: 0.7,
                                backgroundColor: '#262d50',
                                color: 'white',
                            },
                        },
                    },
                }}
                {...rest}
            >
                {options.map(({ label, value }) => (
                    <MenuItem value={value.toString()} key={label.toString()}>
                        {label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

export default WorldSelect
