import { Box, Typography } from '@mui/material'
import { useEffect } from 'react'

import SearchForm from './search-form'

const Search = () => {
    useEffect(() => {
        document.title = 'dbeWorld | Search for DBEs'
    })

    return (
        <Box  minHeight="100%">
            <Typography
                variant="h4"
                color="black"
                fontFamily='"Inter", sans-serif'
                fontWeight="bold"
            >
                Search For DBEs
            </Typography>
            <Box mt={4}>
                <SearchForm />
            </Box>
        </Box>
    )
}

export default Search
