import {
    Response,
    Account,
    AccountRaw,
    Paginated,
    PaginatedRaw,
    SignIn,
} from '../../../utils/models/api-models'
import callAPI from '../api-base'
import { sanitizeAccount } from '../user/util'

type Accounts = Paginated<Account>
type AccountsRaw = PaginatedRaw<AccountRaw>
export type AccountsRepsonse = Response<Accounts>

const SuperAPI = () => {
    const sanitizeAccountsFromAPI = (value: AccountsRaw): Accounts => {
        return {
            numPages: value.num_pages,
            count: value.count,
            data:
                (value.data && value.data.map((b) => sanitizeAccount(b))) || [],
        }
    }

    const getAccounts = ({
        pageNumber = 1,
    }: {
        pageNumber?: number
    }): Promise<AccountsRepsonse> => {
        return callAPI({
            url: 'superuser/accounts/?page=' + pageNumber.toString(),
            type: 'get',
            auth: true,
            body: {},
        })
            .then((res: any) => {
                return {
                    success: res.data.success,
                    data: sanitizeAccountsFromAPI(res.data.data),
                }
            })
            .catch((err: any) => {
                return { success: false, data: null }
            })
    }

    const superIntoAccount = ({ id }: { id: number }): Promise<SignIn> => {
        return callAPI({
            url: 'superuser/access-token/',
            type: 'post',
            auth: true,
            body: { id: id },
        })
            .then((res: any) => {
                return {
                    success: res.data.success,
                    accessToken: res.data.data.access,
                    refreshToken: res.data.data.refresh,
                    user: sanitizeAccount(res.data.data.user),
                }
            })
            .catch((err: any) => {
                return {
                    success: false,
                    accessToken: null,
                    refreshToken: null,
                    user: null,
                }
            })
    }

    return { getAccounts, superIntoAccount }
}

export const { getAccounts, superIntoAccount } = SuperAPI()
