import { Box, Button } from '@mui/material'
import { useState } from 'react'

import { useForm, Controller } from 'react-hook-form'
import WorldTextField from '../../ui-components/text-field'

import findBusinessAPI from '../../../api/v1/search/find-business-api'
import WorldSnackbar from '../../ui-components/snackbar'

import { AnonBusiness } from '../../../utils/models/api-models'
import { Failure } from '../../../utils/constants/messages'

interface BusinessSearchProps {
    handleNext: () => void
    setSearchedBusiness: ({
        data,
        totalPages,
        businessEmail,
        businessName,
    }: {
        data: AnonBusiness[]
        totalPages: number
        businessEmail: string
        businessName: string
    }) => void
}

const BusinessSearch = ({
    handleNext,
    setSearchedBusiness,
}: BusinessSearchProps) => {
    const [openSnackbar, setOpenSnackbar] = useState(false)
    const [disable, setDisabled] = useState(false)
    const methods = useForm({
        defaultValues: {
            businessName: '',
            businessEmail: '',
        },
    })

    const { control, getValues, trigger } = methods
    const handleBusinessSearch = async () => {
        setDisabled(true)
        if (!(await trigger())) {
            setDisabled(false)
            return
        }
        const { businessEmail, businessName } = getValues()
        const { success, data } = await findBusinessAPI({
            page: 1,
            businessEmail,
            businessName,
        })

        if (success && data) {
            setSearchedBusiness({
                totalPages: data.numPages,
                businessEmail,
                businessName,
                data: data.data || [],
            })
            handleNext()
        } else {
            setOpenSnackbar(true)
        }
        setDisabled(false)
    }

    return (
        <>
            <Box mt={3}>
                <Controller
                    name={'businessEmail' as const}
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <WorldTextField
                            label="Business Email"
                            placeholder="Enter business email"
                            fullWidth
                            value={field.value}
                            error={Boolean(error)}
                            helperText={error?.message || ''}
                            onChange={(e: React.BaseSyntheticEvent) =>
                                field.onChange(e.target.value)
                            }
                        />
                    )}
                    rules={{
                        maxLength: {
                            value: 60,
                            message:
                                'Business email must be 60 characters or less',
                        },
                        pattern: {
                            value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                            message: 'Must be an email',
                        },
                        validate: () => {
                            const { businessEmail, businessName } = getValues()
                            if (!businessEmail && !businessName) {
                                return 'Must include either one or both business name and business email'
                            }
                        },
                    }}
                />
            </Box>
            <Box mt={2}>
                <Controller
                    name={'businessName' as const}
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <WorldTextField
                            label="Business Name"
                            placeholder="Enter business name"
                            fullWidth
                            value={field.value}
                            error={Boolean(error)}
                            helperText={error?.message || ''}
                            onChange={(e: React.BaseSyntheticEvent) =>
                                field.onChange(e.target.value)
                            }
                        />
                    )}
                    rules={{
                        maxLength: {
                            value: 500,
                            message:
                                'Business name must be 500 characters or less',
                        },
                        pattern: {
                            value: /^[^\s]+(?:$|.*[^\s]+$)/,
                            message:
                                'Business name can not start or end with a space',
                        },
                        validate: () => {
                            const { businessEmail, businessName } = getValues()
                            if (!businessEmail && !businessName) {
                                return 'Must include either one or both business name and business email'
                            }
                        },
                    }}
                />
            </Box>
            <Box mt={3}>
                <Button
                    variant="text"
                    color="primary"
                    sx={{
                        px: 4,
                    }}
                    onClick={handleBusinessSearch}
                    disabled={disable}
                >
                    Next
                </Button>
            </Box>
            <WorldSnackbar
                open={openSnackbar}
                setOpen={setOpenSnackbar}
                message={<Box>{Failure}</Box>}
            />
        </>
    )
}

export default BusinessSearch
