import { Alert, Box, Stack, Typography } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { useDropzone } from 'react-dropzone'
import { useCallback } from 'react'
import { AttachFile, Download } from '@mui/icons-material'
import { getPricingFile } from '../../../../../../api/v1/opportunity-response'
import WhiteContainer from '../../../../../ui-components/white-container'
import OpportunityInfo from './opportunity'
import { Opportunity } from '../../../../../../utils/models/api-models'

const Pricing = ({
    disable,
    required,
    id,
    opportunity,
}: {
    disable: boolean
    required: boolean
    id: number
    opportunity: Opportunity
}) => {
    const { setValue, getValues } = useFormContext()

    const onDrop = useCallback((acceptedFiles) => {
        const file = acceptedFiles[0]
        setValue('pricing', file)
    }, [])

    const { fileRejections, getRootProps, getInputProps, isDragActive } =
        useDropzone({
            accept: {
                'text/*': ['.csv', '.pdf'],
            },
            multiple: false,
            onDrop,
        })

    const handleDownload = () => {
        const uploadedFile = getValues('pricing')
        if (uploadedFile) {
            const downloadUrl = URL.createObjectURL(uploadedFile)
            window.open(downloadUrl)
        }
    }

    const downloadFromS3 = async () => {
        const { data } = await getPricingFile({ id: id })
        window.open(data?.link)
    }

    const showFiles = () => {
        const pricing = getValues('pricing')
        if (typeof pricing === 'string' && pricing) {
            const pricingSplit = pricing.split('/')
            const pricingFileName = pricingSplit[pricingSplit.length - 1]
            return (
                <Box
                    color="blue"
                    onClick={downloadFromS3}
                    sx={{ cursor: 'pointer' }}
                    display="flex"
                >
                    <Download sx={{ mr: 1 }} />
                    {pricingFileName}
                </Box>
            )
        } else if (typeof pricing !== 'string' && pricing) {
            return (
                <Box
                    color="blue"
                    onClick={handleDownload}
                    sx={{ cursor: 'pointer' }}
                    display="flex"
                >
                    <Download sx={{ mr: 1 }} />
                    {pricing.name}
                </Box>
            )
        }
    }

    return (
        <Box component={'span'} display="flex">
            <WhiteContainer sx={{ width: '30%', mr: '25px' }}>
                <OpportunityInfo opportunity={opportunity} />
            </WhiteContainer>
            <WhiteContainer sx={{ width: '60%', ml: '25px' }}>
                <Typography
                    variant="h2"
                    fontSize={16}
                    fontWeight={300}
                    lineHeight={1.7}
                >
                    Pricing
                </Typography>

                <Stack spacing={4}>
                    <Typography variant="body1">
                        {required
                            ? 'Please upload a single CSV or PDF with pricing information. This will replace any uploaded pricing file.'
                            : 'Pricing is not required'}
                    </Typography>
                    {required && (
                        <Box
                            justifyContent="center"
                            display="flex"
                            px={8}
                            py={8}
                            {...getRootProps()}
                            sx={{
                                borderStyle: 'dotted',
                                borderRadius: 2,
                                borderColor: 'rgba(0, 0, 0, 0.38)',
                                color: 'rgba(0, 0, 0, 0.38)',
                            }}
                        >
                            <AttachFile />
                            {isDragActive
                                ? 'Drop the file here...'
                                : 'Click or Drag to Upload Pricing in CSV or PDF'}

                            <input {...getInputProps()} disabled={disable} />
                        </Box>
                    )}

                    {fileRejections.length > 0 && (
                        <Alert severity="error">Incorrect file type</Alert>
                    )}
                    {required && showFiles()}
                </Stack>
            </WhiteContainer>
        </Box>
    )
}

export default Pricing
