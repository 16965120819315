import { Box, Button } from '@mui/material'
import { superIntoAccount } from '../../../../api/v1/super'
import { useNavigate } from 'react-router-dom'
import { setOpen as openNotification } from '../../../../slices/notification-slice'
import { useDispatch, useSelector } from 'react-redux'
import { Failure } from '../../../../utils/constants/messages'
import { login } from '../../../../slices/account-slice'
import { getUserInfo } from '../../../../selectors/login-selector'

const ActionButtons = ({ id }: { id: number }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { userType, email } = useSelector(getUserInfo)

    const loginAsSuper = async () => {
        const { success, accessToken, refreshToken, user } =
            await superIntoAccount({ id: id })

        // TODO: Clear out the user info and then refresh
        // TODO: update the web code to pass down account information
        console.log(success, accessToken, refreshToken, user)

        if (!success || !user) {
            dispatch(
                openNotification({
                    message: Failure,
                    open: true,
                })
            )
            return
        }

        localStorage.setItem('accessToken', accessToken || '')
        localStorage.setItem('refreshToken', refreshToken || '')

        dispatch(
            login({
                email: user.email,
                loggedIn: true,
                userType: user.type,
                businessId: user.businessId || null,
                isSuper: user.isSuper,
            })
        )

        console.log(userType, email)

        navigate('/platform/search')
        return
    }

    return (
        <Box alignItems={'center'}>
            <Button onClick={() => loginAsSuper()}>Super</Button>
        </Box>
    )
}

export default ActionButtons
