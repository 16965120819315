import React, { useState } from 'react'
import { Grid, Typography, Button, Link } from '@mui/material'

import { useDispatch } from 'react-redux'

import WorldTextField from '../../ui-components/text-field'

import signInAPI from '../../../api/v1/user/sign-in'
import { login, logout } from '../../../slices/account-slice'
import { useNavigate } from 'react-router-dom'

const Login = () => {
    const [email, setEmail] = useState<string | null>(null)
    const [password, setPassword] = useState<string | null>(null)
    const [error, setError] = useState<boolean>(false)
    const [disable, setDisabled] = useState<boolean>(false)

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const handleLogin = async () => {
        setDisabled(true)
        if (!email || !password) {
            setError(true)
            setDisabled(false)
            return
        }
        const { success, user } = await signInAPI({ email, password })

        if (!success || !user) {
            setError(true)
            dispatch(logout())
            setDisabled(false)
            return
        }

        dispatch(
            login({
                email: user.email,
                loggedIn: true,
                userType: user.type,
                businessId: user.businessId || null,
                isSuper: user.isSuper,
            })
        )
        navigate('../platform/search', { replace: true })
    }

    const linkStyling = {
        color: 'black',
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
            color: '#262d50',
        },
    }

    return (
        <>
            <Grid
                container
                justifyContent="flex-start"
                px={10}
                mt={10}
                direction="column"
                alignItems="center"
                textAlign="center"
            >
                <Grid item>
                    <Typography
                        variant="h5"
                        color="#262d50"
                        fontWeight="bold"
                        fontFamily='"Montserrat", sans-serif'
                    >
                        dbeWorld
                    </Typography>
                </Grid>
                <Grid item mt={4}>
                    <Typography
                        variant="h3"
                        fontWeight="bold"
                        fontFamily='"Inter", sans-serif'
                    >
                        Login
                    </Typography>
                </Grid>
                <Grid item mt={2}>
                    <Typography variant="h5" color="#7f7f7f">
                        Good to see you again
                    </Typography>
                </Grid>
                <Grid item mt={6} justifyContent="flex-start">
                    <WorldTextField
                        placeholder="Enter email"
                        label="Email"
                        type="text"
                        fullWidth
                        error={error}
                        value={email}
                        onChange={(e: React.BaseSyntheticEvent) =>
                            setEmail(e.target.value)
                        }
                    />
                    <WorldTextField
                        placeholder="Enter password"
                        label="Password"
                        type="password"
                        fullWidth
                        sx={{ mt: 2 }}
                        error={error}
                        value={password}
                        onChange={(e: React.BaseSyntheticEvent) =>
                            setPassword(e.target.value)
                        }
                        onKeyDown={(e: React.KeyboardEvent) => {
                            if (e.key === 'Enter') {
                                handleLogin()
                            }
                        }}
                    />
                    <Button
                        sx={{
                            mt: 3,
                            width: '100%',
                            px: 4,
                        }}
                        variant="text"
                        color="primary"
                        onClick={() => handleLogin()}
                        disabled={disable}
                    >
                        Sign in
                    </Button>
                </Grid>
            </Grid>
            <Grid
                container
                justifyContent="center"
                mt={3}
                spacing={2}
                sx={{ fontSize: 12 }}
            >
                <Grid item>
                    <Link href="/register" sx={linkStyling}>
                        Register
                    </Link>
                </Grid>
                <Grid item>|</Grid>
                <Grid item>
                    <Link href="/resend-activation" sx={linkStyling}>
                        Resend Activation
                    </Link>
                </Grid>
                <Grid item>|</Grid>
                <Grid item>
                    <Link href="/reset-password" sx={linkStyling}>
                        Reset Password
                    </Link>
                </Grid>
            </Grid>
        </>
    )
}

export default Login
